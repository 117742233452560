import React from "react";
import {
  Checkbox,
  Divider,
  Grid,
  FormControlLabel,
  Typography,
  Paper,
} from "@mui/material";

import pxToRem from "hooks/usePxToRem";
import RetrieveUserInformation from "hooks/useUserInformation";

import {useDispatch, useSelector} from "react-redux";
import {Types as userTypes} from "store/reducers/userDataReducer";
import {useFormikContext} from "formik";
import {UserFormDataType} from "views/User/types/userTypes";

const UserPermissionsAndRoles = () => {
  const dispatch = useDispatch();
  const {userData} = useSelector((state: any) => state.stateUserData);
  const formik = useFormikContext<UserFormDataType>();

  const user = RetrieveUserInformation();

  const roles = [
    {role: "Administrador", criteria: "CompanyAdministrator"},
    {role: "Financeiro", criteria: "Financial"},
    {role: "Analista", criteria: "Analist"},
    {role: "Liberação Online", criteria: "DocumentRelease"},
    {role: "Convidado", criteria: "Guest"},
  ];

  const userCanEdit = () => {
    if (user.roles.includes("CompanyAdministrator")) {
      return true;
    } else {
      return false;
    }
  };

  const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const permission = userCanEdit();

    if (!permission) return;
    dispatch({
      type: userTypes.SET_USER_DATA,
      userData: {
        ...userData,
        [event.target.name]:
          event.target.name === "disabled"
            ? !event.target.checked
            : event.target.checked,
      },
    });
  };
  const handleChangeRoles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const permission = userCanEdit();
    if (!permission) return;

    if (userData.profiles.includes(event.target.name)) {
      const newRoles = userData.profiles.filter(
        (item: string) => item !== event.target.name,
      );

      dispatch({
        type: userTypes.SET_USER_DATA,
        userData: {...userData, profiles: newRoles},
      });
    } else {
      const newRole = userData.profiles;

      newRole.push(event.target.name);

      dispatch({
        type: userTypes.SET_USER_DATA,
        userData: {
          ...userData,
          profiles: newRole,
        },
      });
    }
  };

  return (
    <Grid item xs={12} md={4}>
      <Paper
        elevation={6}
        sx={{
          mt: pxToRem(30),
          p: pxToRem(20),
          height: `calc(100% - ${pxToRem(30)})`,
        }}>
        <Typography variant="h6">Perfis</Typography>
        <Divider />
        <Grid container sx={{mt: pxToRem(15)}}>
          {roles.map((item, index) => (
            <Grid item xs={12} md={6} key={index}>
              <FormControlLabel
                sx={{fontSize: pxToRem(5)}}
                label={
                  <Typography fontSize={pxToRem(14)}>{item.role}</Typography>
                }
                control={
                  <Checkbox
                    checked={userData.profiles?.includes(item.criteria)}
                    name={item.criteria}
                    onChange={handleChangeRoles}
                  />
                }
              />
            </Grid>
          ))}
        </Grid>
        <Typography variant="h6" sx={{mt: pxToRem(20)}}>
          Permissões
        </Typography>
        <Divider />
        <Grid container sx={{mt: pxToRem(15)}}>
          <Grid item xs={6}>
            <FormControlLabel
              label={
                <Typography fontSize={pxToRem(14)}>Acesso ao chat</Typography>
              }
              control={
                <Checkbox
                  checked={userData.hasChat}
                  name="hasChat"
                  onChange={handleCheckbox}
                  value={formik.values.hasChat}
                />
              }
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              label={
                <Typography fontSize={pxToRem(14)}>Perfil Ativo</Typography>
              }
              control={
                <Checkbox
                  checked={!userData.disabled}
                  name="disabled"
                  onChange={handleCheckbox}
                />
              }
            />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default UserPermissionsAndRoles;
