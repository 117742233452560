import React from "react";
import {InputAdornment, Icon, TextField} from "@mui/material";

type inputTypes = {
  name: string;
  value: string;
  type: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void | undefined;
  startIcon?: string;
  endIcon?: string;
  error?: boolean;
  helperText?: string;
  fullWidth?: boolean;
  fnIconOnClick?: () => void;
  placeholder: string;
};

const CPInput = (props: inputTypes) => {
  return (
    <TextField
      color="primary"
      fullWidth={props.fullWidth}
      type={props.type}
      name={props.name}
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.onChange}
      variant="outlined"
      error={props.error}
      helperText={props.helperText}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Icon>{props.startIcon}</Icon>
          </InputAdornment>
        ),
        endAdornment: props.endIcon && (
          <InputAdornment position="end">
            <Icon onClick={props.fnIconOnClick} sx={{cursor: "pointer"}}>
              {props.endIcon}
            </Icon>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default React.memo(CPInput);
