import {createActions, createReducer} from "reduxsauce";

type initial = {
  dataModal: any;
  dataConfirmModal: any;
  openModal: boolean;
  uploadModal: boolean;
  referenceModal: boolean;
  downloadModal: boolean;
  updateModal: boolean;
  viewOrdersModal: boolean;
  mainOrdersModal: boolean;
  openConfirmModal: boolean;
  openUserModal: boolean;
  viewEmployerModal: boolean;
  viewDocumentModal: boolean;
  brokerPermissionsModal: boolean;
};

const INITIAL_STATE: initial = {
  dataModal: {title: "123"},
  dataConfirmModal: {},
  openModal: false,
  openConfirmModal: false,
  uploadModal: false,
  referenceModal: false,
  downloadModal: false,
  updateModal: false,
  viewOrdersModal: false,
  mainOrdersModal: false,
  openUserModal: false,
  viewEmployerModal: false,
  viewDocumentModal: false,
  brokerPermissionsModal: false,
};

export const {Types, Creators} = createActions({
  showModal: ["openModal"],
  setDataConfirmModal: ["dataConfirmModal"],
  showConfirmModal: ["openConfirmModal"],
  openChooseModal: [
    "dataModal",
    "uploadModal",
    "referenceModal",
    "downloadModal",
    "updateModal",
    "viewOrdersModal",
    "mainOrdersModal",
    "openUserModal",
    "viewEmployerModal",
    "viewDocumentModal",
    "brokerPermissionsModal",
  ],
});

const showConfirmModal = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  openConfirmModal: action.openConfirmModal,
});

const setDataConfirmModal = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  dataConfirmModal: action.dataConfirmModal,
});

const showModal = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  openModal: action.openModal,
});

const openChooseModal = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  dataModal: action.dataModal || {},
  uploadModal: action.uploadModal || false,
  referenceModal: action.referenceModal || false,
  downloadModal: action.downloadModal || false,
  updateModal: action.updateModal || false,
  viewOrdersModal: action.viewOrdersModal || false,
  mainOrdersModal: action.mainOrdersModal || false,
  openUserModal: action.openUserModal || false,
  viewEmployerModal: action.viewEmployerModal || false,
  viewDocumentModal: action.viewDocumentModal || false,
  brokerPermissionsModal: action.brokerPermissionsModal || false,
});

export default createReducer(INITIAL_STATE, {
  [Types.SHOW_MODAL]: showModal,
  [Types.SET_DATA_CONFIRM_MODAL]: setDataConfirmModal,
  [Types.SHOW_CONFIRM_MODAL]: showConfirmModal,
  [Types.OPEN_CHOOSE_MODAL]: openChooseModal,
});
