import {createActions, createReducer} from "reduxsauce";

type initial = {
  employerData: any;
};

const INITIAL_STATE: initial = {
  employerData: undefined,
};

export const {Types, Creators} = createActions({
  SetEmployerData: ["employerData"],
});

const setEmployerData = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  employerData: action.employerData,
});

export default createReducer(INITIAL_STATE, {
  [Types.SET_EMPLOYER_DATA]: setEmployerData,
});
