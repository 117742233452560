import {CustomInput} from "ui";
import {useSelector, useDispatch} from "react-redux";
import {Types as companyTypes} from "store/reducers/companyReducer";
import {InformationContainer} from "../index";
import {isValidPhoneNumber, MuiTelInput} from "mui-tel-input";
import {useFormikContext} from "formik";
import {useEffect, useState} from "react";
import {Grid, Typography} from "@mui/material";
import pxToRem from "hooks/usePxToRem";
import WindowDimensions from "hooks/useWindowDimensions";

type Props = {
  readOnly: boolean;
};

const Identification = (props: Props) => {
  const dispatch = useDispatch();
  const formik = useFormikContext<any>();

  const {readOnly} = props;
  const {currentCompany} = useSelector((state: any) => state.stateCompany);
  const {screenWidth} = WindowDimensions();

  const [phone, setPhone] = useState(currentCompany.phone);

  const handlePhoneChange = (phoneNumber: string) => {
    console.log(phoneNumber);
    setPhone(phoneNumber);

    if (isValidPhoneNumber(phoneNumber)) {
      formik.setFieldValue("telephone", phoneNumber);
      const validPhoneNumberData = {...currentCompany, phone: phoneNumber};
      dispatch({
        type: companyTypes.COMPANY_DATA,
        currentCompany: validPhoneNumberData,
      });
    }
  };

  useEffect(() => {
    setPhone(currentCompany.phone);
  }, [currentCompany]);

  return (
    <InformationContainer title="Identificação">
      <CustomInput
        type="company"
        disabled
        value={currentCompany?.cnpj}
        label="CNPJ"
        mobileMarginBottom={25}
      />
      <CustomInput
        type="company"
        disabled
        value={currentCompany?.name}
        label="Razão social"
        mobileMarginBottom={25}
      />
      <Grid item sx={{marginBottom: screenWidth <= 768 ? pxToRem(25) : 0}}>
        <Typography sx={{color: "rgba(255, 255, 255, 0.7)"}}>
          Telefone:
        </Typography>
        <MuiTelInput
          fullWidth
          defaultCountry="BR"
          name="telephone"
          error={formik.touched.telephone && Boolean(formik.errors.telephone)}
          helperText={formik.errors?.telephone}
          disabled={readOnly}
          value={phone}
          onChange={(event) => handlePhoneChange(event)}
        />
      </Grid>
      <CustomInput
        type="company"
        name="email"
        disabled
        value={currentCompany?.email}
        label="Email"
        mobileMarginBottom={25}
      />
    </InformationContainer>
  );
};

export default Identification;
