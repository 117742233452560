import {useState, useEffect} from "react";

const GetWindowDimensions = () => {
  const {innerWidth: screenWidth, innerHeight: screenHeight} = window;
  return {
    screenWidth,
    screenHeight,
  };
};

const WindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    GetWindowDimensions(),
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(GetWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
};

export default WindowDimensions;
