import {buildURL, dataAPI} from "services/Api/apiConsumer";

export const getCompany = async (id: string | number) => {
  return await dataAPI.get(`employers/${id}`);
};

export const patchCompany = async (id: string | number, data: any) => {
  return await dataAPI.patch(`employers/${id}`, data);
};

export const getCompanies = async (
  query: any,
  sort?: string,
  page?: number,
  limit?: number,
) => {
  return await dataAPI.get(
    `employers/${buildURL(
      query,
      sort ? sort : null,
      page ? page : null,
      limit ? limit : 9999,
    )}`,
  );
};
