import {deleteOrder} from "services/Orders/orders.service";
import {useDispatch, useSelector} from "react-redux";
import {Types as loadingTypes} from "store/reducers/loadingReducer";
import {Types as snackbarTypes} from "store/reducers/snackbarReducer";
import {Types as modalTypes} from "store/reducers/modalReducer";
import {Types as paginationTypes} from "store/reducers/paginationReducer";
import colors from "global/colors";
import {CPCardItem} from "components";

type Props = {
  order: any;
  getOrdersPending: () => void;
};

const ItemOrders = (data: Props) => {
  const dispatch = useDispatch();
  const {totalItems, actualPage, itemsPerPage, totalPages} = useSelector(
    (state: any) => state.statePagination,
  );
  const {clientReference, responsible, provider, orderState} = data.order;

  const menuOptions = [
    //opções que serão renderizados no menu flutuante
    {
      label: "Visualizar Pedidos",
      icon: "visibility",
      fnOnClick: () => handleOpenViewOrdersModal(data),
    },
    {
      label: "Editar pedido",
      icon: "edit",
      fnOnClick: () => handleOpenEditModal(data),
    },
    {
      label: "Excluir pedido",
      icon: "delete",
      fnOnClick: () => handleOpenConfirmModal(data),
    },
  ];

  const itemsToRender = [
    {
      xs: 3,
      content: clientReference,
      mobileTitle: "RF do Cliente",
      disableTooltip: false,
    },
    {
      xs: 3,
      content: responsible.name,
      mobileTitle: "Responsável",
      disableTooltip: true,
    },
    {
      xs: 3,
      content: provider,
      mobileTitle: "Fornecedor",
      disableTooltip: false,
    },
    {
      xs: 2,
      content: orderState,
      mobileTitle: "Status",
      disableTooltip: true,
    },
  ];
  //modal global
  const handleOpenCPModal = () => {
    dispatch({
      type: modalTypes.SHOW_MODAL,
      openModal: true,
    });
  };

  const handleOpenSnackbar = (severity: string, message: string) => {
    dispatch({
      type: snackbarTypes.SHOW_SNACKBAR,
      openSnackbar: true,
      message: message,
      severity: severity,
    });
  };

  const handleOpenViewOrdersModal = (data: any) => {
    handleOpenCPModal();

    dispatch({
      type: modalTypes.OPEN_CHOOSE_MODAL,
      dataModal: data.order,
      viewOrdersModal: true,
    });
  };

  const handleOpenConfirmModal = (data: any) => {
    const dataAndActions = {
      action: () => handleDeleteOrder(data.order._id),
      title: "Deseja excluir o pedido?",
      message: `RF do Cliente: ${data.order.clientReference}`,
      icon: "priority_high",
      color: colors.badgeColors.error.text,
    };

    dispatch({
      type: modalTypes.SHOW_CONFIRM_MODAL,
      openConfirmModal: true,
    });

    dispatch({
      type: modalTypes.SET_DATA_CONFIRM_MODAL,
      dataConfirmModal: dataAndActions,
    });
  };

  const handleOpenEditModal = (data: any) => {
    handleOpenCPModal();

    dispatch({
      type: modalTypes.OPEN_CHOOSE_MODAL,
      dataModal: {getOrders: () => data.getOrdersPending(), editData: data},
      mainOrdersModal: true,
    });
  };

  const handleDeleteOrder = async (OrderId: string) => {
    dispatch({
      type: loadingTypes.CHANGE_LOADING,
      isLoading: true,
    });
    try {
      await deleteOrder(OrderId);
      data.getOrdersPending();
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: modalTypes.SHOW_MODAL,
        openModal: false,
      });

      if (totalPages > actualPage) {
        dispatch({
          type: paginationTypes.CHANGE_OPTIONS,
          totalItems: totalItems,
          actualPage: actualPage,
          itemsPerPage: itemsPerPage,
          totalPages: totalPages,
        });
      }

      dispatch({
        type: loadingTypes.CHANGE_LOADING,
        isLoading: false,
      });
      handleOpenSnackbar("success", "Pedido excluído com sucesso!");
    }
  };

  return <CPCardItem itemsToRender={itemsToRender} menuOptions={menuOptions} />;
};

export default ItemOrders;
