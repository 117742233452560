import {FormControl, Grid} from "@mui/material";
import {Formik, useFormik} from "formik";
import pxToRem from "hooks/usePxToRem";
import {useSelector, useDispatch} from "react-redux";
import {patchCompany} from "services/Company/company.service";
import {Types as snackbarTypes} from "store/reducers/snackbarReducer";
import {Types as companyTypes} from "store/reducers/companyReducer";
import {Types as loadingTypes} from "store/reducers/loadingReducer";
import {
  Address,
  FormFooter,
  Identification,
} from "views/Company/components/GeneralDetails/components";
import WindowDimensions from "hooks/useWindowDimensions";
import * as yup from "yup";

type Props = {
  readOnly: boolean;
};

const FormCompanyInfo = (props: Props) => {
  const dispatch = useDispatch();

  const {screenWidth} = WindowDimensions();
  const {currentCompany} = useSelector((state: any) => state.stateCompany);
  const {readOnly} = props;

  const formikInitialValues = {
    telephone: currentCompany?.phone,
    zipcode: currentCompany?.address.zipcode,
    street: currentCompany?.address.street,
    district: currentCompany?.address.district,
    city: currentCompany?.address.city,
    state: currentCompany?.address.state,
    complement: currentCompany?.address.complement,
  };

  const companyInfoSchema = yup.object({
    telephone: yup
      .string()
      .min(5, "mínimo 5 dígitos")
      .matches(/\d+$/, {
        message: "Deve terminar com número",
      })
      .max(20, "Telefone deve no máximo 20 dígitos"),
    zipcode: yup
      .string()
      .min(8, "Campo obrigatório")
      .matches(/^\d{5}[-.\s]?\d{3}$/, {
        message: "Formatos permitidos: 00000-000, 00000000, 00000 000",
        excludeEmptyString: true,
      }),
    street: yup.string().min(3, "Logradouro inválido"),
    district: yup.string(),
    city: yup.string(),
    state: yup
      .string()
      .min(2, "Sigla do estado. Ex: SC")
      .max(2, "Sigla do estado. Ex: SC"),
    complement: yup.string(),
  });

  const formik = useFormik({
    initialValues: formikInitialValues,
    enableReinitialize: true,
    validationSchema: companyInfoSchema,
    onSubmit: (values) => {
      submitFunction(values);
    },
  });

  const submitFunction = async (companyInfo: any) => {
    handleLoading("start");
    const updatedPhoneData = {
      ...currentCompany,
      phone: companyInfo?.telephone?.replace(/ /g, ""),
    };

    try {
      const response = await patchCompany(currentCompany._id, updatedPhoneData);

      if (response.status == 200) {
        handleOpenSnackbar("success", "Dados atualizados com sucesso!");
        dispatch({
          type: companyTypes.COMPANY_DATA,
          currentCompany: updatedPhoneData,
        });
      }
    } catch (error) {
      console.log(error);
      handleOpenSnackbar("error", "Erro ao atualizar dados. Tente novamente.");
    } finally {
      handleLoading("finish");
    }
  };

  const handleOpenSnackbar = (severity: string, message: string) => {
    dispatch({
      type: snackbarTypes.SHOW_SNACKBAR,
      openSnackbar: true,
      message: message,
      severity: severity,
    });
  };

  const handleLoading = (action: "start" | "finish") => {
    dispatch({
      type: loadingTypes.CHANGE_LOADING,
      isLoading: action === "start" ? true : false,
    });
  };

  return (
    <Formik
      initialValues={formikInitialValues}
      validationSchema={companyInfoSchema}
      onSubmit={submitFunction}
      enableReinitialize>
      <FormControl
        component="form"
        onSubmit={formik.handleSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          height: screenWidth <= 768 ? "auto" : "87%",
        }}>
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: screenWidth <= 768 ? "column" : "row",
            flexWrap: "nowrap",
            alignItems: "center",
            justifyContent:
              screenWidth <= 768 ? "space-evenly" : "space-between",
            height: "100%",
            padding: `0 ${pxToRem(16)}`,
          }}>
          <Identification readOnly={readOnly} />
          <Address readOnly={readOnly} />
        </Grid>
        <FormFooter />
      </FormControl>
    </Formik>
  );
};

export default FormCompanyInfo;
