import {Grid} from "@mui/material";
import {CardAllowedBroker, InfoContainer} from "../index";
import pxToRem from "hooks/usePxToRem";
import WindowDimensions from "hooks/useWindowDimensions";
import {GetBrokers} from "api";

const AllBrokersContainer = () => {
  const {allBrokers, allowedBrokers, setAllowedBrokers} = GetBrokers();
  const {screenWidth} = WindowDimensions();

  return (
    <InfoContainer title="Despachantes permitidos" desktopHeight="20%">
      <Grid
        container
        sx={{
          "div: last-child": {
            marginBottom: pxToRem(0),
          },
          height: screenWidth <= 768 ? "auto" : "62%",
          overflowY: "auto",
          display: "flex",
          flexDirection: screenWidth <= 768 ? "column" : "row",
        }}>
        {allowedBrokers.map((broker: any, index: number) => (
          <CardAllowedBroker
            key={index}
            broker={broker}
            allBrokers={allBrokers}
            allowedBrokers={allowedBrokers}
            setAllowedBrokers={setAllowedBrokers}
          />
        ))}
      </Grid>
    </InfoContainer>
  );
};

export default AllBrokersContainer;
