import {
  Drawer,
  Box,
  Typography,
  Paper,
  Grid,
  IconButton,
  Icon,
  Switch,
} from "@mui/material";
import {CPDivider} from "components";
import pxToRem from "hooks/usePxToRem";
import {useSelector, useDispatch} from "react-redux";
import {Types as configsTypes} from "store/reducers/configsLayoutReducer";

const CPSettings = () => {
  const dispatch = useDispatch();
  const {openSettings, darkMode, fixedNavbar} = useSelector(
    (state: any) => state.stateConfigsLayout,
  );

  const handleCloseDrawer = () => {
    dispatch({
      type: configsTypes.SET_OPEN_SETTINGS,
      openSettings: false,
    });
  };

  const handleChangeNavbarFixed = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch({
      type: configsTypes.SET_FIXED_NAVBAR,
      fixedNavbar: event.target.checked,
    });

    localStorage.setItem(
      "theme",
      JSON.stringify(event.target.checked ? "dark" : "light"),
    );
  };

  const handleChangeDarkMode = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: configsTypes.SET_DARK_MODE,
      darkMode: event.target.checked,
    });

    localStorage.setItem(
      "theme",
      JSON.stringify(event.target.checked ? "dark" : "light"),
    );
  };

  return (
    <Drawer
      elevation={0}
      anchor="right"
      open={openSettings}
      onClose={handleCloseDrawer}
      PaperProps={{
        sx: {
          backgroundColor: "transparent",
          border: "none",
        },
      }}
      sx={{
        padding: pxToRem(16),
      }}>
      <Box
        sx={{
          width: pxToRem(320),
          margin: pxToRem(16),
        }}>
        <Paper
          elevation={6}
          sx={{
            width: "100%",
            borderRadius: pxToRem(12),
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}>
          <Box sx={{width: "100%"}}>
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{display: "flex", justifyContent: "flex-end"}}>
                <IconButton onClick={handleCloseDrawer}>
                  <Icon>close</Icon>
                </IconButton>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}>
                <Typography variant="h6">Configurações de exibição </Typography>
              </Grid>
            </Grid>
            <CPDivider />

            <Grid container>
              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: pxToRem(32),
                }}>
                <Typography variant="subtitle2">Light / Dark </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{display: "flex", justifyContent: "center"}}>
                <Switch checked={darkMode} onChange={handleChangeDarkMode} />
              </Grid>
            </Grid>
            <CPDivider />
            <Grid container>
              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: pxToRem(32),
                }}>
                <Typography variant="subtitle2">
                  Barra superior fixa{" "}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{display: "flex", justifyContent: "center"}}>
                <Switch
                  checked={fixedNavbar}
                  onChange={handleChangeNavbarFixed}
                />
              </Grid>
            </Grid>
            <CPDivider />
          </Box>
        </Paper>
      </Box>
    </Drawer>
  );
};

export default CPSettings;
