import {lazy} from "react";
import PublicLayout from "./layouts/Public";
import PrivateLayout from "./layouts/Private";

const routes = [
  {
    path: "/",
    exact: true,
    component: () => {
      window.location.href = "/login";
      return null;
    },
  },
  {
    path: "/new-password",

    component: PublicLayout,
    routes: [
      {
        path: "/new-password",

        component: lazy(() => import("./views/Login")),
      },
    ],
  },
  {
    path: "/forgot-password",

    component: PublicLayout,
    routes: [
      {
        path: "/forgot-password",
        exact: true,
        component: lazy(() => import("./views/Login")),
      },
    ],
  },
  {
    path: "/new-access",

    component: PublicLayout,
    routes: [
      {
        path: "/new-access",
        exact: true,
        component: lazy(() => import("./views/Login")),
      },
    ],
  },
  {
    path: "/login",
    component: PublicLayout,
    routes: [
      {
        path: "/login",
        exact: true,
        component: lazy(() => import("./views/Login")),
      },
    ],
  },
  {
    path: "/send-files/:userID/:processID/:processNumber/:token",
    component: PublicLayout,
    routes: [
      {
        path: "/send-files/:userID/:processID/:processNumber/:token",
        exact: true,
        component: lazy(
          () => import("./views/OnlineRelease/components/ReleaseMobile"),
        ),
      },
    ],
  },

  {
    route: "*",
    component: PrivateLayout,
    routes: [
      {
        path: "/user-new",
        exact: true,
        component: lazy(() => import("./views/User")),
      },
      {
        path: "/user-edit",
        exact: true,
        component: lazy(() => import("./views/User")),
      },

      {
        path: "/user-edit/:id",
        exact: true,
        component: lazy(() => import("./views/User")),
      },
      {
        path: "/dashboard",
        exact: true,
        component: lazy(() => import("./views/Dashboard")),
      },
      {
        path: "/loads-done",
        name: "cargas concluídas",
        exact: true,
        component: lazy(() => import("./views/Loads")),
      },
      {
        path: "/loads-archived",
        name: "cargas arquivadas",
        exact: true,
        component: lazy(() => import("./views/Loads")),
      },
      {
        path: "/profile",
        exact: true,
        component: lazy(() => import("./views/Profile")),
      },
      {
        path: "/approve-payments",
        exact: true,
        component: lazy(() => import("./views/Payments")),
      },
      {
        path: "/payments",
        exact: true,
        component: lazy(() => import("./views/Payments")),
      },
      {
        path: "/orders",
        exact: true,
        component: lazy(() => import("./views/Orders")),
      },
      {
        path: "/company",
        exact: true,
        component: lazy(
          () => import("./views/Company/components/GeneralDetails"),
        ),
      },
      {
        path: "/company-users",
        exact: true,
        component: lazy(() => import("./views/Company/components/Users")),
      },
      {
        path: "/company-brokers",
        exact: true,
        component: lazy(() => import("./views/Company/components/Brokers")),
      },
      {
        path: "/company-branches",
        exact: true,
        component: lazy(() => import("./views/Company/components/Branches")),
      },
      {
        path: "/company-settings",
        exact: true,
        component: lazy(() => import("./views/Company/components/Settings")),
      },
      {
        path: "*/detalhes-do-processo",
        exact: true,
        component: lazy(() => import("./views/ProcessDetails")),
      },
      {
        path: "/online-release",
        exact: true,
        component: lazy(() => import("./views/OnlineRelease")),
      },
    ],
  },
];

export default routes;
