/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Grid,
  Icon,
  LinearProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import pxToRem from "hooks/usePxToRem";
import WindowDimensions from "hooks/useWindowDimensions";
import {useCallback} from "react";
import {useDropzone} from "react-dropzone";
import {CustomButton, CustomIconButton} from "ui";
import {useSelector, useDispatch} from "react-redux";
import {Types as uploaderTypes} from "store/reducers/uploaderReducer";

const CPUploader = () => {
  const {screenWidth} = WindowDimensions();
  const dispatch = useDispatch();
  const {uploaderFiles} = useSelector((state: any) => state.stateUploader);

  const onDrop = useCallback((acceptedFiles: any) => {
    const temp = uploaderFiles;
    temp.push(...acceptedFiles);

    dispatch({
      type: uploaderTypes.HANDLE_UPLOADER_FILES,
      uploaderFiles: temp,
    });
  }, []);

  const {getRootProps, getInputProps} = useDropzone({
    onDrop,
  });

  const handleDeleteFile = (item: any) => {
    const temp = uploaderFiles;

    const index = temp.indexOf(item);

    if (index !== -1) {
      temp.splice(index, 1);
    }

    dispatch({
      type: uploaderTypes.HANDLE_UPLOADER_FILES,
      uploaderFiles: [...temp],
    });
  };

  return (
    <Box
      sx={{
        border: `${pxToRem(1.5)} solid lightgrey`,
        borderRadius: pxToRem(3),
        marginTop: pxToRem(8),
        width: "100%",
        color: "text.primary",
      }}>
      <Grid container spacing={2} sx={{padding: pxToRem(8)}}>
        <Grid
          item
          xs={screenWidth <= 768 ? 12 : 6}
          sx={{height: screenWidth <= 768 ? "50%" : "auto"}}>
          <Box
            {...getRootProps({className: "dropzone"})}
            sx={{
              border: "1px dashed grey",
              borderRadius: "5px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center;",
              padding: pxToRem(15),
              textAlign: "center",
            }}>
            <input {...getInputProps()} />
            <Icon fontSize="large">cloud_upload</Icon>
            <Typography>Arraste e solte seu arquivo aqui</Typography>
            <Typography sx={{margin: pxToRem(4)}}>ou</Typography>
            <CustomButton>Escolha seu arquivo</CustomButton>
          </Box>
        </Grid>
        <Grid
          item
          xs={screenWidth <= 768 ? 12 : 6}
          sx={{minHeight: screenWidth <= 768 ? pxToRem(160) : "auto"}}>
          <Typography
            component="p"
            variant="body1"
            sx={{textAlign: "center", fontWeight: 600}}>
            Arquivos enviados
          </Typography>

          {uploaderFiles.length > 0 ? (
            <Grid
              container
              spacing={1}
              className={"scrollBar"}
              sx={{
                maxHeight: pxToRem(130),
                marginTop: pxToRem(5),
                overflow: "auto",
              }}>
              {uploaderFiles.map((file: any, index: number) => (
                <Grid
                  key={index}
                  item
                  xs={12}
                  sx={{display: "flex", alignItems: "center"}}>
                  <Box
                    width={screenWidth < 768 ? pxToRem(80) : pxToRem(150)}
                    sx={{display: "flex", justifyContent: "center"}}>
                    {file.type.includes("image") ? (
                      <img width={70} src={URL.createObjectURL(file)} />
                    ) : (
                      <Icon sx={{fontSize: pxToRem(50)}}>description</Icon>
                    )}
                  </Box>
                  <Grid
                    container
                    sx={{display: "flex", flexDirection: "column"}}>
                    <Grid
                      item
                      xs={screenWidth > 768 ? 8 : 12}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginLeft: pxToRem(10),
                      }}>
                      <Tooltip title={file.name} arrow placement="top-start">
                        <Typography
                          noWrap
                          width={
                            screenWidth < 768 ? pxToRem(80) : pxToRem(120)
                          }>
                          {file.name}
                        </Typography>
                      </Tooltip>

                      <Tooltip
                        title={`${(file.size / 1024).toFixed(2)} kB`}
                        arrow
                        placement="top">
                        <Typography noWrap width={pxToRem(60)}>
                          / {(file.size / 1024).toFixed(0)} kB
                        </Typography>
                      </Tooltip>
                      <CustomIconButton
                        onClickFn={() => handleDeleteFile(file)}>
                        <Tooltip title="Excluir arquivo" placement="top" arrow>
                          <Icon>delete_forever</Icon>
                        </Tooltip>
                      </CustomIconButton>
                    </Grid>
                    <Grid
                      item
                      xs={screenWidth > 500 ? 8 : 12}
                      sx={{marginLeft: pxToRem(10)}}>
                      <LinearProgress value={100} variant="determinate" />
                      <Typography>100%</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography
              variant="body2"
              sx={{
                marginTop: pxToRem(10),
                textAlign: screenWidth <= 768 ? "center" : "left",
              }}>
              Nenhum arquivo enviado
            </Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default CPUploader;
