import React from "react";
import {Backdrop, CircularProgress} from "@mui/material";
import {useSelector} from "react-redux";

const CPLoading = () => {
  const {isLoading} = useSelector((state: any) => state.stateLoading);
  return (
    <Backdrop open={isLoading || false} sx={{zIndex: 99999}}>
      <CircularProgress color="primary" />
    </Backdrop>
  );
};

export default React.memo(CPLoading);
