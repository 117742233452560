import React, {useState} from "react";
import {
  DialogTitle,
  Typography,
  DialogContent,
  DialogActions,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

import {useDispatch, useSelector} from "react-redux";
import {Types as modalTypes} from "store/reducers/modalReducer";
import {CustomButton} from "ui";
import pxToRem from "hooks/usePxToRem";
import {patchCompany} from "services/Company/company.service";
import {
  handleCompany,
  handleLoading,
  handleModal,
  handleSnackbar,
} from "views/Company/utils";
import WindowDimensions from "hooks/useWindowDimensions";

const ModalBrokerPermissions = () => {
  const dispatch = useDispatch();
  const brokerPermissions = [
    "Definir como padrão",
    "Liberar documentos",
    "Acesso ao chat",
    "Visualizar faturas",
    "Liberação online",
  ];

  const {dataModal} = useSelector((state: any) => state.stateModal);
  const {currentCompany} = useSelector((state: any) => state.stateCompany);
  const {name} = dataModal;
  const {screenWidth} = WindowDimensions();

  const [companyBrokers, setCompanyBrokers] = useState<any[]>(
    currentCompany.brokers,
  );

  const handleCloseModal = () => {
    handleModal(dispatch, "close");

    dispatch({
      type: modalTypes.OPEN_CHOOSE_MODAL,
      brokerPermissionsModal: false,
    });
  };

  const handleChangeChecked = (
    event: React.ChangeEvent<HTMLInputElement>,
    item: string,
  ) => {
    const tempObject = dataModal;

    if (item === "Definir como padrão") {
      tempObject.isDefault = !dataModal.isDefault;

      dispatch({
        type: modalTypes.OPEN_CHOOSE_MODAL,
        dataModal: tempObject,
        brokerPermissionsModal: true,
      });

      if (companyBrokers.find((item) => item.person === dataModal.person)) {
        setCompanyBrokers([
          ...companyBrokers.filter((item) => item.person != dataModal.person),
          dataModal,
        ]);
      }
    } else {
      tempObject?.permissions
        .map((permission: string) => permission)
        .indexOf(item) > -1
        ? tempObject.permissions.splice(
            tempObject.permissions
              .map((permission: string) => permission)
              .indexOf(item),
            1,
          )
        : tempObject.permissions.push(item);

      dispatch({
        type: modalTypes.OPEN_CHOOSE_MODAL,
        dataModal: tempObject,
        brokerPermissionsModal: true,
      });
      if (companyBrokers.find((item) => item.person === dataModal.person)) {
        setCompanyBrokers([
          ...companyBrokers.filter((item) => item.person != dataModal.person),
          dataModal,
        ]);
      }
    }
  };

  const handleCheckedPermissions = (permission: string) => {
    const tempArray = companyBrokers
      .filter((broker) => broker.person === dataModal.person)
      .map((broker) => broker?.permissions?.includes(permission));

    return tempArray[0];
  };

  const updateBrokerPermissions = async () => {
    handleLoading(dispatch, "start");
    try {
      const response = await patchCompany(currentCompany._id, {
        brokers: companyBrokers,
      });
      if (response.status === 200) {
        handleCompany(dispatch, response.data);
        handleSnackbar(dispatch, "Permissões alteradas com sucesso", "success");
      }
    } catch (error) {
      handleSnackbar(dispatch, "Erro ao alterar permissões", "error");
    } finally {
      handleLoading(dispatch, "finish");
      handleCloseModal();
    }
  };

  return (
    <React.Fragment>
      <DialogTitle sx={{paddingTop: screenWidth <= 768 ? 0 : pxToRem(16)}}>
        Selecionar permissões do despachante
      </DialogTitle>
      <Typography sx={{padding: ` 0 ${pxToRem(24)}`}}>{name}</Typography>
      <DialogContent>
        {brokerPermissions?.map((permission: string, index: number) => {
          return (
            <FormControlLabel
              sx={{display: "flex", alignItems: "center"}}
              key={index}
              control={
                <Checkbox
                  onChange={(event) => handleChangeChecked(event, permission)}
                  checked={
                    permission === "Definir como padrão"
                      ? dataModal.isDefault
                        ? true
                        : false
                      : handleCheckedPermissions(permission)
                  }
                />
              }
              label={permission}
            />
          );
        })}
      </DialogContent>
      <DialogActions
        sx={{
          padding: `${pxToRem(8)} ${pxToRem(24)} ${pxToRem(24)} ${pxToRem(24)}`,
          display: "flex",
          justifyContent: "space-between",
        }}>
        <CustomButton variant="outlined" onClickFn={handleCloseModal}>
          Cancelar
        </CustomButton>
        <CustomButton variant="contained" onClickFn={updateBrokerPermissions}>
          Confirmar permissões
        </CustomButton>
      </DialogActions>
    </React.Fragment>
  );
};

export default ModalBrokerPermissions;
