import pxToRem from "hooks/usePxToRem";

import styled from "@emotion/styled";
import {Accordion} from "@mui/material";

import lightTheme from "global/themes/lightTheme";

const StyledAccordion = styled(Accordion)`
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: ${pxToRem(5)};
  color: ${lightTheme.palette.grey[800]};

  &::before {
    content: unset;
  }

  .MuiAccordionSummary-root {
    &.Mui-expanded {
      border-bottom: 1px solid ${lightTheme.palette.grey.A200};
    }
  }

  .MuiAccordionSummary-content {
    align-items: center;
    gap: ${pxToRem(6)};
  }

  .accordion {
    &__icon {
      &--right {
        font-size: ${pxToRem(19)};
        color: ${lightTheme.palette.grey[800]};
      }

      &--left {
        font-size: ${pxToRem(20)};
      }
    }

    &__title {
      text-transform: capitalize;
    }

    &__details {
      padding: ${pxToRem(12)} ${pxToRem(16)};
    }
  }
`;

export default StyledAccordion;
