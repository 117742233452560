import rgba from "./useRgba";
import pxToRem from "./usePxToRem";

const boxShadow = (
  offset: Array<any>,
  radius: Array<any>,
  color: string,
  opacity: number,
  inset?: string,
) => {
  const [x, y] = offset;
  const [blur, spread] = radius;

  return `${inset} ${pxToRem(x)} ${pxToRem(y)} ${pxToRem(blur)} ${pxToRem(
    spread,
  )} ${rgba(color, opacity)}`;
};

export default boxShadow;
