import React from "react";
import {styled} from "@mui/material/styles";
import {
  IconButton,
  Icon,
  Menu,
  MenuProps,
  Box,
  Typography,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import pxToRem from "hooks/usePxToRem";
import WindowDimensions from "hooks/useWindowDimensions";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
))(({theme}) => ({
  "& .MuiPaper-root": {
    marginTop: theme.spacing(1),
    minWidth: 180,
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 14,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
    },
  },
}));

const CPMenuItem = (props: any) => {
  const {screenWidth} = WindowDimensions();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const {menuPosition} = props;
  const {itemsMenu} = props;

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  return (
    <Box
      sx={{
        display: screenWidth < 500 ? "flex" : "auto",
        flexDirection: "flex-end",
      }}>
      <IconButton
        aria-expanded={openMenu ? "true" : undefined}
        onClick={handleOpenMenu}>
        <Icon
          sx={{
            position: menuPosition ? "absolute" : "",
            top: menuPosition ? pxToRem(10) : "",
            right: menuPosition ? pxToRem(10) : "",
          }}>
          more_vert
        </Icon>
      </IconButton>
      <StyledMenu anchorEl={anchorEl} open={openMenu} onClose={handleCloseMenu}>
        {itemsMenu
          .filter((item: any) => !item.disabled)
          .map((item: any, index: number) => (
            <MenuItem
              onClick={() => {
                item.fnOnClick();
                handleCloseMenu();
              }}
              disableRipple
              key={index}>
              <Icon sx={{marginRight: pxToRem(8)}}>{item.icon}</Icon>
              <Typography variant="caption">{item.label}</Typography>
            </MenuItem>
          ))}
      </StyledMenu>
    </Box>
  );
};

export default CPMenuItem;
