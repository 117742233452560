import {useEffect, useState} from "react";
import {format, subDays} from "date-fns";
import CurrencyFormat from "react-currency-format";
import {useDispatch} from "react-redux";
import {Types as modalTypes} from "store/reducers/modalReducer";
import {CPCardItem} from "components";

const ItemBill = (props: any) => {
  const dispatch = useDispatch();

  const {propsItem, buildLink, item} = props;
  const {clientReference, documents, additionalReference, kanbanStatus} =
    props.item;

  const [urlPaymentSlip, setUrlPaymentSlip] = useState("");

  const itemsToRender = [
    {
      xs: 2,
      content: clientReference,
      mobileTitle: "Referência",
      disableTooltip: false,
    },
    {
      xs: 2,
      content: format(new Date(propsItem.due), "dd/MM/yyyy"),
      mobileTitle: "Vencimento",
      disableTooltip: true,
    },
    {
      xs: 2,
      content: (
        <b>
          {propsItem.isPayed || kanbanStatus === "Concluído"
            ? "PAGO"
            : propsItem.fileUpload
            ? "EM ANÁLISE"
            : "EM ABERTO"}
        </b>
      ),
      mobileTitle: "Status",
      disableTooltip: true,
    },
    {
      xs: 2,
      content: (
        <CurrencyFormat
          displayType={"text"}
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
          value={propsItem.value}
          fixedDecimalScale={true}
        />
      ),
      mobileTitle: "Valor (R$)",
      disableTooltip: true,
    },
  ];

  const showDownload = () => {
    if (
      propsItem.isPaymentSlip ||
      format(new Date(propsItem?.date), "dd/MM/yyyy") ===
        format(new Date(), "dd/MM/yyyy") ||
      (format(new Date(propsItem?.date), "dd/MM/yyyy") ===
        format(subDays(new Date(), 1), "dd/MM/yyyy") &&
        Number(format(new Date(), "hh")) < 13)
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (documents.length > 0) {
      const paymentSlips = documents.filter((item: any) => {
        return item.group === "BOLETO";
      });
      setUrlPaymentSlip(paymentSlips[paymentSlips.length - 1].url);
    }
  }, [documents]);

  const handleOpenModal = () => {
    dispatch({
      type: modalTypes.SHOW_MODAL,
      openModal: true,
    });
  };

  const handleOpenDownloadModal = (item: any) => {
    handleOpenModal();

    dispatch({
      type: modalTypes.OPEN_CHOOSE_MODAL,
      dataModal: item,
      downloadModal: true,
    });
  };

  const handleOpenUploadModal = (item: any) => {
    handleOpenModal();

    dispatch({
      type: modalTypes.OPEN_CHOOSE_MODAL,
      dataModal: item,
      uploadModal: true,
    });
  };

  const itemsMenu = [
    {
      label: "Visualização e download de fatura",
      icon: "download",
      fnOnClick: () =>
        handleOpenDownloadModal({
          ...propsItem,
          additionalReference,
          clientReference,
          name: "fatura",
        }),
    },
    {
      label: "Anexar comprovante de pagamento",
      icon: "publish",
      fnOnClick: () =>
        handleOpenUploadModal({
          ...propsItem,
          buildLink,
          item,
        }),
    },
    {
      label: "Visualizar comprovante",
      icon: "receipt",
      fnOnClick: () =>
        handleOpenDownloadModal({...propsItem, name: "comprovante"}),
      disabled: propsItem.fileUpload ? false : true,
    },
    {
      label: "Visualização e download de boleto",
      icon: "payment",
      fnOnClick: () =>
        handleOpenDownloadModal({
          ...propsItem,
          urlPaymentSlip,
          name: "boleto",
        }),
      disabled: `${showDownload()}` ? false : true,
    },
  ];

  return <CPCardItem itemsToRender={itemsToRender} menuOptions={itemsMenu} />;
};

export default ItemBill;
