import {Grid, Typography} from "@mui/material";
import pxToRem from "hooks/usePxToRem";
import WindowDimensions from "hooks/useWindowDimensions";
import {useSelector} from "react-redux";
import colors from "global/colors";

type Props = {titles: {title: string; xs: number}[]};

const CPHeaderTitles = (props: Props) => {
  const {screenWidth} = WindowDimensions();
  const {darkMode} = useSelector((state: any) => state.stateConfigsLayout);
  const {titles} = props;

  return (
    <Grid
      container
      spacing={2}
      sx={{
        display: screenWidth <= 768 ? "none" : "flex",
        flexDirection: "row",
        width: "100%",
        color: darkMode ? colors.light.main : colors.dark.main,
        padding: pxToRem(16),
        justifyContent: "space-between",
        "div: last-child": {
          textAlign: "end",
        },
      }}>
      {titles.map((headerItem, index) => (
        <Grid item xs={headerItem.xs} key={index}>
          <Typography color="inherit" variant="body1" sx={{fontWeight: "bold"}}>
            {headerItem.title}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};

export default CPHeaderTitles;
