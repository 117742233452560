import React from "react";
import {Paper, Grid, Divider, Typography, Box} from "@mui/material";
import Dropzone from "react-dropzone";
import {MuiTelInput} from "mui-tel-input";
import {useParams} from "react-router-dom";
import {useFormikContext} from "formik";

import pxToRem from "hooks/usePxToRem";
import {Upload} from "hooks/useUpload";
import WindowDimensions from "hooks/useWindowDimensions";

import {useDispatch, useSelector} from "react-redux";
import {Types as userTypes} from "store/reducers/userDataReducer";

import {CPAvatar} from "components";
import colors from "global/colors";
import {CustomInput} from "ui";
import lightTheme from "global/themes/lightTheme";
import "./stylesHoverAndScrool.scss";
import RetrieveUserInformation from "hooks/useUserInformation";
import {UserFormDataType} from "views/User/types/userTypes";

type ParamsId = {
  id?: string;
};

type UserInfoProps = {
  setUserPhoneNumber: React.Dispatch<any>;
};
const UserInfo = (props: UserInfoProps) => {
  const {routeActive} = useSelector((state: any) => state.stateConfigsLayout);
  const {userData} = useSelector((state: any) => state.stateUserData);
  const user = RetrieveUserInformation();
  const params: ParamsId = useParams();
  const {md} = lightTheme.breakpoints.values;
  const {screenWidth} = WindowDimensions();
  const dispatch = useDispatch();

  const {setUserPhoneNumber} = props;

  const [editPassword, setEditPassword] = React.useState(false);

  const formik = useFormikContext<UserFormDataType>();
  const editUser = routeActive === "user-edit";

  React.useEffect(() => {
    editUser === false && setEditPassword(true);
  }, []);

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: userTypes.SET_USER_DATA,
      userData: {
        ...userData,
        [event.target.name]: event.target.value,
      },
    });
  };

  const handleEditPassword = () => {
    if (
      (editUser && user.roles.includes("CompanyAdministrator")) ||
      params.id !== user._id
    ) {
      setEditPassword(!editPassword);
    }
  };

  const handleChangeAvatar = async (acceptedFiles: any) => {
    const data = new FormData();

    try {
      data.append("image", acceptedFiles[0]);
      const result = await Upload(data);
      formik.setFieldValue("avatar", result.data.link);

      dispatch({
        type: userTypes.SET_USER_DATA,
        userData: {...userData, avatar: result.data.link},
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangePhoneNumber = (newValue) => {
    formik.setFieldValue("phone", newValue);
    setUserPhoneNumber(newValue);

    dispatch({
      type: userTypes.SET_USER_DATA,
      userData: {...userData, phone: newValue},
    });
  };

  return (
    <Grid item xs={12} md={8}>
      <Paper
        elevation={7}
        sx={{
          mt: pxToRem(30),
          p: pxToRem(20),
          minHeight: pxToRem(380),
        }}>
        <Typography variant="h6">Dados gerais</Typography>
        <Divider />
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: screenWidth < 768 ? "column" : "",
          }}>
          <Grid
            item
            xs={12}
            md={4}
            lg={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              p: pxToRem(15),
            }}>
            <Dropzone
              onDrop={(acceptedFiles) => handleChangeAvatar(acceptedFiles)}>
              {({getRootProps, getInputProps}) => (
                <section>
                  <div {...getRootProps()} style={{position: "relative"}}>
                    <input {...getInputProps()} />

                    <CPAvatar
                      aWidth={screenWidth <= md ? 150 : 180}
                      aHeight={screenWidth <= md ? 150 : 180}
                      src={userData.avatar}
                      name="Sem Nome"
                      variant="square"
                    />
                    {screenWidth < 768 && (
                      <Typography
                        variant="caption"
                        component="p"
                        textAlign="center">
                        Clique na foto para alterar
                      </Typography>
                    )}
                    <Box className="avatar-box">Alterar foto</Box>
                  </div>
                </section>
              )}
            </Dropzone>
          </Grid>
          <Grid item xs={12} md={8} lg={9}>
            <Grid
              container
              spacing={1}
              sx={{
                display: "flex",
                justifyContent: "center",
                p: pxToRem(15),
              }}>
              <Grid item xs={12}>
                <Typography variant="caption">
                  Nome Completo
                  <Typography component="span" sx={{color: colors.error.main}}>
                    *
                  </Typography>
                </Typography>
                <CustomInput
                  name="name"
                  error={formik?.touched.name && Boolean(formik?.errors.name)}
                  helperText={formik.errors.name}
                  value={formik?.values.name}
                  placeholder="João da Silva"
                  iconStart="person"
                  onChange={handleChangeInput}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="caption">
                  Email
                  <Typography component="span" sx={{color: colors.error.main}}>
                    *
                  </Typography>
                </Typography>
                <CustomInput
                  placeholder="exemplo@email.com"
                  iconStart="mail"
                  name="email"
                  error={formik?.touched.email && Boolean(formik?.errors.email)}
                  helperText={formik?.errors.email}
                  value={formik?.values.email}
                  disabled={editUser}
                  onChange={handleChangeInput}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="caption">
                  Telefone
                  <Typography component="span" sx={{color: colors.error.main}}>
                    *
                  </Typography>
                </Typography>

                <MuiTelInput
                  name="phone"
                  fullWidth
                  defaultCountry="BR"
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                  value={formik.values.phone}
                  onChange={handleChangePhoneNumber}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="caption">Senha</Typography>
                <CustomInput
                  placeholder={editPassword ? "Senha" : ""}
                  iconStart="lock"
                  iconEnd={editUser ? "edit" : ""}
                  inputPassword
                  disabled={!editPassword ? true : false}
                  name="password"
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.errors.password}
                  value={formik.values.password}
                  onChange={handleChangeInput}
                  onClickFn={handleEditPassword}
                />

                <Typography
                  fontSize={10}
                  noWrap
                  sx={{m: pxToRem(5), minHeight: pxToRem(15)}}>
                  {editPassword ? "Mínimo 8 caracteres, letras e números." : ""}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="caption">Confirmar Senha</Typography>

                <CustomInput
                  error={
                    formik.touched.confirmPassword &&
                    Boolean(formik.errors.confirmPassword)
                  }
                  helperText={formik.errors.confirmPassword}
                  value={formik.values.confirmPassword}
                  placeholder={editPassword ? "Senha" : ""}
                  iconStart="lock"
                  inputPassword
                  name="confirmPassword"
                  disabled={!editPassword ? true : false}
                  onChange={handleChangeInput}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default UserInfo;
