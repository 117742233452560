import {Suspense} from "react";
import {BrowserRouter} from "react-router-dom";
import {renderRoutes} from "react-router-config";
import {Provider as StoreProvider} from "react-redux";
import {CircularProgress, StyledEngineProvider} from "@mui/material";
import configureStore from "./store/configureStore";
import routes from "./routes";

const store = configureStore();

function App() {
  return (
    <StoreProvider store={store}>
      <StyledEngineProvider injectFirst>
        <BrowserRouter>
          <Suspense fallback={<CircularProgress color="primary" />}>
            {renderRoutes(routes)}
          </Suspense>
        </BrowserRouter>
      </StyledEngineProvider>
    </StoreProvider>
  );
}

export default App;
