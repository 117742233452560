import {combineReducers} from "redux";
import companyReducer from "./companyReducer";
import configsLayoutReducer from "./configsLayoutReducer";
import dialogReducer from "./dialogReducer";
import employerDataReducer from "./employerDataReducer";
import formLoginReducer from "./formLoginReducer";
import loadingReducer from "./loadingReducer";
import modalReducer from "./modalReducer";
import paginationReducer from "./paginationReducer";
import snackbarReducer from "./snackbarReducer";
import uploaderReducer from "./uploaderReducer";
import userDataReducer from "./userDataReducer";

const rootReducer = combineReducers({
  stateLoading: loadingReducer,
  stateFormLogin: formLoginReducer,
  stateDialog: dialogReducer,
  stateConfigsLayout: configsLayoutReducer,
  statePagination: paginationReducer,
  stateModal: modalReducer,
  stateSnackbar: snackbarReducer,
  stateUploader: uploaderReducer,
  stateUserData: userDataReducer,
  stateEmployerData: employerDataReducer,
  stateCompany: companyReducer,
});

export default rootReducer;
