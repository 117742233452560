import {Box, Paper} from "@mui/material";
import {CustomInput} from "ui";
import SearchIcon from "@mui/icons-material/Search";
import {Dispatch, SetStateAction, useEffect} from "react";
import pxToRem from "hooks/usePxToRem";
import WindowDimensions from "hooks/useWindowDimensions";
import {CPHeaderTitles} from "components";

type headerProps = {
  getPayments: (action?: string) => any;
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
};

const Header = (props: headerProps) => {
  const {getPayments, search, setSearch} = props;
  const {screenWidth} = WindowDimensions();

  const headerTitles = [
    {title: "Referência", xs: 2},
    {title: "Vencimento", xs: 2},
    {title: "Status", xs: 2},
    {title: "Valor (R$)", xs: 2},
    {title: "Menu", xs: 1},
  ];

  useEffect(() => {
    if (search === "") {
      getPayments();
    }
  }, [search]);

  const handleInputSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);

    if (event.target.value === "") {
      getPayments();
    }
  };

  const clearInputSearch = () => {
    setSearch("");
  };

  return (
    <Box
      sx={{
        width: "100%",
        margin: `0 0 0 ${pxToRem(15)}`,
      }}>
      <Paper
        elevation={1}
        sx={{
          padding: pxToRem(8),
          width: screenWidth <= 768 ? "100%" : pxToRem(400),
          marginBottom: screenWidth <= 768 ? 0 : pxToRem(24),
        }}>
        <CustomInput
          placeholder="Pesquisar faturas"
          iconEnd={<SearchIcon />}
          fnClearSearch={clearInputSearch}
          type="search"
          onChange={handleInputSearch}
          onClickFn={() => getPayments("search")}
          value={search}
        />
      </Paper>

      <CPHeaderTitles titles={headerTitles} />
    </Box>
  );
};

export default Header;
