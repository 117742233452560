declare module "@mui/material/styles" {
  interface TypographyVariants {
    subtitle3: React.CSSProperties;
    body3: React.CSSProperties;
    button1: React.CSSProperties;
    button2: React.CSSProperties;
    button3: React.CSSProperties;
    label1: React.CSSProperties;
    label2: React.CSSProperties;
    placeholder1: React.CSSProperties;
    placeholder2: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    subtitle3?: React.CSSProperties;
    body3?: React.CSSProperties;
    button1?: React.CSSProperties;
    button2?: React.CSSProperties;
    button3?: React.CSSProperties;
    label1?: React.CSSProperties;
    label2?: React.CSSProperties;
    placeholder1?: React.CSSProperties;
    placeholder2?: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    subtitle3: true;
    body3: true;
    button1: true;
    button2: true;
    button3: true;
    label1: true;
    label2: true;
    placeholder1: true;
    placeholder2: true;
  }
}

const typographyConfigs = {
  allVariants: {
    fontFamily: "Poppins, sans-serif",
  },

  h1: {
    fontSize: 48,
    fontWeight: 700,

    "@media (min-width: 1200px)": {
      fontSize: 60,
    },
  },

  h2: {
    fontSize: 34,
    fontWeight: 700,

    "@media (min-width: 1200px)": {
      fontSize: 48,
    },
  },

  h3: {
    fontSize: 20,
    fontWeight: 700,

    "@media (min-width: 1200px)": {
      fontSize: 34,
    },
  },

  h4: {
    fontSize: 18,
    fontWeight: 600,

    "@media (min-width: 1200px)": {
      fontSize: 20,
    },
  },

  subtitle1: {
    fontWeight: 500,
    lineHeight: "24px",
  },

  subtitle3: {
    fontSize: 12,
    fontWeight: 500,
  },

  body3: {
    fontSize: 12,
    fontWeight: 400,
  },

  button1: {
    fontSize: 16,
    fontWeight: 500,
  },

  button2: {
    fontSize: 14,
    fontWeight: 500,
  },

  button3: {
    fontSize: 12,
    fontWeight: 500,
  },

  label1: {
    fontSize: 14,
    fontWeight: 500,
  },

  label2: {
    fontSize: 12,
    fontWeight: 500,
  },

  placeholder1: {
    fontSize: 14,
    fontWeight: 400,
  },

  placeholder2: {
    fontSize: 11,
    fontWeight: 400,
  },
};

export default typographyConfigs;
