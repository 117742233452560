import {Link, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {Breadcrumbs as MUIBreadcrumbs, Icon, Typography} from "@mui/material";

import lightTheme from "global/themes/lightTheme";
import darkTheme from "global/themes/darkTheme";
import colors from "global/colors";

const Breadcrumbs = () => {
  const pathnames = useLocation().pathname.split("/").slice(1);
  const pathnamesWithoutTrace = pathnames.map((path: string) =>
    path.split("-").join(" "),
  );

  const {darkMode} = useSelector((state: any) => state.stateConfigsLayout);

  const showPathsInOrder = (pathIndex: number): string => {
    const routeTo = `/${pathnames.slice(0, pathIndex + 1).join("/")}`;

    return routeTo;
  };

  const checkIfIsLastPath = (pathIndex: number): boolean => {
    const isLastPath = pathIndex === pathnames.length - 1;

    return isLastPath;
  };

  return (
    <MUIBreadcrumbs sx={{lineHeight: 0}}>
      {pathnames.length > 0 ? (
        <Link to="/dashboard">
          <Icon
            sx={{
              fontSize: 22,
              color: darkMode ? colors.grey[500] : lightTheme.palette.grey[600],

              transition: "color 280ms ease-in-out",

              "&:hover": {
                color: darkMode
                  ? darkTheme.palette.primary.main
                  : lightTheme.palette.primary[700],
              },
            }}>
            home
          </Icon>
        </Link>
      ) : (
        <Typography
          sx={{
            color: darkMode
              ? darkTheme.palette.text.primary
              : lightTheme.palette.grey[800],
          }}>
          <Icon sx={{fontSize: 22}}>home</Icon>
        </Typography>
      )}

      {pathnamesWithoutTrace.map((path: string, index: number) => {
        return checkIfIsLastPath(index) ? (
          <Typography
            key={path}
            sx={{
              color: darkMode
                ? darkTheme.palette.text.primary
                : lightTheme.palette.grey[800],
            }}>
            {path}
          </Typography>
        ) : (
          <Link
            key={path}
            to={showPathsInOrder(index)}
            style={{textDecoration: "none"}}>
            <Typography
              sx={{
                color: darkMode
                  ? colors.grey[400]
                  : lightTheme.palette.grey[600],

                transition: "color 280ms ease-in-out",

                "&:hover": {
                  textDecoration: "underline",
                  color: darkMode
                    ? darkTheme.palette.secondary.main
                    : lightTheme.palette.primary[700],
                },
              }}>
              {path}
            </Typography>
          </Link>
        );
      })}
    </MUIBreadcrumbs>
  );
};

export default Breadcrumbs;
