import React from "react";
import {Grid, FormControl, MenuItem, Pagination, Paper} from "@mui/material";
import pxToRem from "hooks/usePxToRem";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import {useDispatch, useSelector} from "react-redux";
import {Types as paginationTypes} from "store/reducers/paginationReducer";
import WindowDimensions from "hooks/useWindowDimensions";

const CPPagination = () => {
  const [rowsPerPage, setRowsPerPage] = React.useState<any>("10");
  const {totalItems, actualPage, itemsPerPage, totalPages} = useSelector(
    (state: any) => state.statePagination,
  );
  const dispatch = useDispatch();
  const {screenWidth} = WindowDimensions();

  const paperPaginationStyle = {
    padding: pxToRem(8),
    minHeight: screenWidth < 500 ? pxToRem(5) : pxToRem(72),
    position: "fixed",
    bottom: pxToRem(16),
    right: screenWidth < 500 ? "auto" : pxToRem(16),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: pxToRem(12),
    width: screenWidth < 768 ? "96%" : `calc(100% - ${pxToRem(315)})`,
  };

  const handleChangeItemsPerPage = async (event: SelectChangeEvent) => {
    if (event.target.value === itemsPerPage) return;
    setRowsPerPage(event.target.value);
    dispatch({
      type: paginationTypes.CHANGE_OPTIONS,
      totalItems: totalItems,
      actualPage: actualPage,
      itemsPerPage: event.target.value,
      totalPages: Math.ceil(totalItems / +event.target.value),
    });
  };

  const handleChangeActualPage = async (
    event: React.ChangeEvent<unknown>,
    targetPage: number,
  ) => {
    dispatch({
      type: paginationTypes.CHANGE_OPTIONS,
      totalItems: totalItems,
      actualPage: targetPage,
      itemsPerPage: itemsPerPage,
      totalPages: totalPages,
    });
  };

  return (
    <Paper sx={paperPaginationStyle}>
      <Grid container sx={{}}>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            bottom: pxToRem(15),
            width: screenWidth <= 800 ? "96%" : `calc(100% - ${pxToRem(315)})`,
          }}>
          <FormControl>
            <Select
              size="small"
              value={rowsPerPage}
              onChange={handleChangeItemsPerPage}>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </FormControl>

          <Pagination
            count={totalPages}
            variant="outlined"
            shape="rounded"
            page={actualPage}
            onChange={handleChangeActualPage}
            siblingCount={0}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CPPagination;
