import {createActions, createReducer} from "reduxsauce";

type initial = {
  currentCompany: {
    address: {
      _id: string;
      street: string;
      district: string;
      city: string;
      zipcode: string;
      state: string;
      complement: string;
    };
    annualDemurrage: boolean;
    brokers: Array<any>;
    cnpj: string;
    createdAt: string;
    email: string;
    fantasyName: string;
    idHC: string | number | null;
    isActive: boolean;
    name: string;
    permissions: Array<any>;
    profiles: Array<any>;
    updatedAt: string;
    _id: string;
    phone: string;
  };
};

const INITIAL_STATE: initial = {
  currentCompany: {
    address: {
      _id: "",
      street: "",
      district: "",
      city: "",
      zipcode: "",
      state: "",
      complement: "",
    },
    annualDemurrage: false,
    brokers: [],
    cnpj: "",
    createdAt: "",
    email: "",
    fantasyName: "",
    idHC: null,
    isActive: true,
    name: "",
    permissions: [],
    profiles: [],
    updatedAt: "",
    _id: "",
    phone: "",
  },
};

export const {Types, Creators} = createActions({
  companyData: ["currentCompany"],
});

const companyData = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  currentCompany: action.currentCompany,
});

export default createReducer(INITIAL_STATE, {
  [Types.COMPANY_DATA]: companyData,
});
