import {applyMiddleware, createStore, compose} from "redux";
import thunkMiddleware from "redux-thunk";
import {composeWithDevTools} from "@redux-devtools/extension";
import createSagaMiddleware from "redux-saga";

import rootReducer from "./reducers";
// import sagas from "./sagas";

export default function configureStore(preloadedState = {}) {
  const sagaMiddleware = createSagaMiddleware();
  const middleware = [thunkMiddleware, sagaMiddleware]; // loggerMiddleware
  const middlewareEnhancer = composeWithDevTools(
    applyMiddleware(...middleware),
  );

  // const enhancers = [middlewareEnhancer];
  const composedEnhancers = compose(middlewareEnhancer);

  const store = createStore(rootReducer, preloadedState, composedEnhancers);

  // sagaMiddleware.run(sagas);

  return store;
}
