/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react";
import {Box} from "@mui/material";
import {CPBoxProps} from "types/CPBox.types";
import linearGradient from "hooks/useLinearGradient";
import colors from "global/colors";
import {bordersTheme, boxShadows} from "global/base";

const CPBox = (props: Partial<CPBoxProps>) => {
  const {
    theme,
    variant,
    bgColor,
    color,
    opacity,
    borderRadius,
    shadow,
    coloredShadow,
  } = props;

  // ({
  //   theme,
  //   variant,
  //   bgColor,
  //   color,
  //   opacity,
  //   borderRadius,
  //   shadow,
  //   coloredShadow,
  // } = {
  //   theme: "light",
  //   variant: "contained",
  //   bgColor: "transparent",
  //   color: "dark",
  //   opacity: 1,
  //   borderRadius: null,
  //   shadow: undefined,
  //   coloredShadow: undefined,
  // });

  const validGradients = [
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ];

  const validColors = [
    "transparent",
    "white",
    "black",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
    "text",
    "grey-100",
    "grey-200",
    "grey-300",
    "grey-400",
    "grey-500",
    "grey-600",
    "grey-700",
    "grey-800",
    "grey-900",
  ];

  const {colored} = boxShadows;
  const {borderRadius: radius} = bordersTheme;
  // const {borderRadiusDark: radiusDark} = bordersThemeDark;
  const validBorderRadius = ["xs", "sm", "md", "lg", "xl", "xxl", "section"];
  const validBoxShadows = ["xs", "sm", "md", "lg", "xl", "xxl", "inset"];

  const [palette, setPalette] = useState(colors);
  const [backgroundValue, setBackgroundValue] = useState(bgColor);
  const [colorValue, setColorValue] = useState(color);
  const [borderRadiusValue, setBorderRadiusValue] = useState(borderRadius);
  const [boxShadowValue, setBoxShadowValue] = useState(shadow);
  const greyColors = {
    "grey-100": palette.grey[100],
    "grey-200": palette.grey[200],
    "grey-300": palette.grey[300],
    "grey-400": palette.grey[400],
    "grey-500": palette.grey[500],
    "grey-600": palette.grey[600],
    "grey-700": palette.grey[700],
    "grey-800": palette.grey[800],
    "grey-900": palette.grey[900],
  };

  const setColor = () => {
    setPalette(colors);

    if (
      validBorderRadius.find((el) => el === borderRadius) &&
      borderRadius !== null &&
      borderRadius !== undefined
    ) {
      setBorderRadiusValue(radius[borderRadius]);
    }
  };

  const setBgColor = () => {
    if (variant === "gradient" && bgColor !== undefined) {
      setBackgroundValue(
        validGradients.find((item) => item === bgColor)
          ? linearGradient(
              palette.gradients[bgColor].main,
              palette.gradients[bgColor].state,
            )
          : palette.white.main,
      );
    } else if (
      validColors.find((item) => item === bgColor) &&
      bgColor !== undefined
    ) {
      setBackgroundValue(
        palette[bgColor] ? palette[bgColor].main : greyColors[bgColor],
      );
    } else {
      setBackgroundValue(bgColor !== undefined ? bgColor : palette.white.main);
    }
  };

  useEffect(() => {
    setColor();
  }, [theme]);

  useEffect(() => {
    setBgColor();
  }, [bgColor]);

  useEffect(() => {
    if (validColors.find((el) => el === color) && color !== undefined) {
      setColorValue(palette[color] ? palette[color].main : greyColors[color]);
    }
  }, []);

  useEffect(() => {
    if (validBoxShadows.find((el) => el === shadow)) {
      setBoxShadowValue(boxShadows[shadow ? shadow : "none"]);
    } else if (coloredShadow) {
      setBoxShadowValue(
        colored[coloredShadow] ? colored[coloredShadow] : undefined,
      );
    }
  }, []);

  return (
    <Box
      sx={[
        {
          opacity: opacity,
          background: backgroundValue,
          color: colorValue,
          borderRadius: borderRadiusValue,
          boxShadow: boxShadowValue,
        },
        props.sx !== undefined && props.sx,
      ]}>
      {props.children}
    </Box>
  );
};

export default React.memo(CPBox);
