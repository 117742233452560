type userValidatorTypes = {
  cnpj: string;
  name: string;
  _id: string;
};

const ValidatorLogin = (userEmployer: userValidatorTypes) => {
  if (userEmployer.cnpj === "20.185.717/0001-62") {
    return false;
  }
  return true;
};

export default ValidatorLogin;
