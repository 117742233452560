import React from "react";
import {
  DialogTitle,
  DialogContent,
  Link,
  Grid,
  DialogActions,
  Typography,
} from "@mui/material";
import {PDFViewer} from "components";
import {useDispatch, useSelector} from "react-redux";
import {Types as modalTypes} from "store/reducers/modalReducer";
import {CustomButton} from "ui";
import pxToRem from "hooks/usePxToRem";
import WindowDimensions from "hooks/useWindowDimensions";

const CPFilePreviewAndDownload = () => {
  const dispatch = useDispatch();

  const {dataModal} = useSelector((state: any) => state.stateModal);
  const {url, urlPaymentSlip, name, fileUpload} = dataModal;
  const {screenWidth} = WindowDimensions();

  const handleCloseModal = () => {
    dispatch({
      type: modalTypes.SHOW_MODAL,
      openModal: false,
    });
    dispatch({
      type: modalTypes.OPEN_CHOOSE_MODAL,
      downloadModal: false,
    });
  };

  return (
    <React.Fragment>
      <DialogTitle sx={{paddingTop: screenWidth <= 768 ? 0 : pxToRem(16)}}>
        Visualização e download de {name}
      </DialogTitle>
      <DialogContent>
        {name === "comprovante" && (
          <Typography variant="body2">
            Pré visualização somente para formato .pdf.
          </Typography>
        )}
        <Grid container spacing={2} sx={{overflow: "hidden"}}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "100%",
            }}>
            <PDFViewer
              pdf={{
                url:
                  name === "fatura"
                    ? url
                    : name === "boleto"
                    ? urlPaymentSlip
                    : name === "comprovante" && fileUpload,
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          padding: `${pxToRem(24)} 
            ${pxToRem(36)} 
            ${pxToRem(24)} 
            ${pxToRem(24)}`,
        }}>
        <CustomButton variant="outlined" onClickFn={handleCloseModal}>
          Fechar
        </CustomButton>

        <Link
          href={
            name === "fatura"
              ? url
              : name === "boleto"
              ? urlPaymentSlip
              : name === "comprovante" && fileUpload
          }
          target="_blank"
          download={dataModal.fileName}
          rel="noreferrer"
          style={{textDecoration: "none"}}>
          <CustomButton variant="contained" onClickFn={handleCloseModal}>
            Baixar {name}
          </CustomButton>
        </Link>
      </DialogActions>
    </React.Fragment>
  );
};

export default CPFilePreviewAndDownload;
