import {DialogActions, DialogContent, DialogTitle, Grid} from "@mui/material";
import {CPUploader} from "components";
import pxToRem from "hooks/usePxToRem";
import {Upload} from "hooks/useUpload";
import WindowDimensions from "hooks/useWindowDimensions";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Types as modalTypes} from "store/reducers/modalReducer";
import {Types as snackbarTypes} from "store/reducers/snackbarReducer";
import {Types as uploaderTypes} from "store/reducers/uploaderReducer";
import {CustomButton} from "ui";

const ModalUploadFile = () => {
  const dispatch = useDispatch();

  const {dataModal} = useSelector((state: any) => state.stateModal);
  const {uploaderFiles} = useSelector((state: any) => state.stateUploader);
  const {buildLink, item} = dataModal;
  const {screenWidth} = WindowDimensions();

  const handleCloseModal = () => {
    dispatch({
      type: modalTypes.SHOW_MODAL,
      openModal: false,
    });
    dispatch({
      type: modalTypes.OPEN_CHOOSE_MODAL,
      uploadModal: false,
    });
    dispatch({
      type: uploaderTypes.HANDLE_UPLOADER_FILES,
      uploaderFiles: [],
    });
  };

  const handleOpenSnackbar = (severity: string, message: string) => {
    dispatch({
      type: snackbarTypes.SHOW_SNACKBAR,
      openSnackbar: true,
      message: message,
      severity: severity,
    });
  };

  const handleSubmit = async () => {
    const data = new FormData();

    try {
      if (uploaderFiles.length > 0) {
        data.append("image", uploaderFiles[0]);
        const result: any = await Upload(data);

        buildLink(item, dataModal, result.data.link);
      }
    } catch (error) {
      console.error("error:", error);
      handleOpenSnackbar("error", "Não foi possível enviar o comprovante");
    } finally {
      handleCloseModal();
    }
  };

  return (
    <React.Fragment>
      <DialogTitle sx={{paddingTop: screenWidth <= 768 ? 0 : pxToRem(16)}}>
        Anexar comprovante de pagamento
      </DialogTitle>
      <DialogContent
        sx={{height: "90%", display: "flex", alignItems: "center"}}>
        <Grid container>
          <CPUploader />
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{
          padding: `${pxToRem(24)} 
            ${pxToRem(36)} 
            ${pxToRem(24)} 
            ${pxToRem(24)}`,
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}>
        <CustomButton variant="outlined" onClickFn={handleCloseModal}>
          Fechar
        </CustomButton>
        <CustomButton
          variant="contained"
          onClickFn={handleSubmit}
          padding={`${pxToRem(16)} 
            ${pxToRem(48)}`}>
          Enviar
        </CustomButton>
      </DialogActions>
    </React.Fragment>
  );
};

export default ModalUploadFile;
