import React from "react";
import {Grid, Typography, FormControl} from "@mui/material";
import {useFormik} from "formik";
import * as yup from "yup";
import pxToRem from "hooks/usePxToRem";
import {postRequestAccessSystem} from "services/Auth/auth.service";
import {useDispatch} from "react-redux";
import {Types as loadingTypes} from "store/reducers/loadingReducer";
import {Types as dialogTypes} from "store/reducers/dialogReducer";
import CPInput from "components/CPInput";
import CPButton from "components/CPButton";
import {useHistory} from "react-router-dom";

const FormNewAccess = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const newAccessSchema = yup.object({
    name: yup.string().required("Este campo é obrigatório"),
    employer: yup.string().required("Este campo é obrigatório"),
    email: yup
      .string()
      .email("Informe um email válido")
      .required("Este campo é obrigatório"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
      employer: "",
    },
    validationSchema: newAccessSchema,
    onSubmit: (values) => {
      sendRequestAccess(values);
    },
  });

  const sendRequestAccess = async (data: any) => {
    dispatch({
      type: loadingTypes.CHANGE_LOADING,
      isLoading: true,
    });
    try {
      const response = await postRequestAccessSystem(data);

      if (response.status === 200) {
        dispatch({
          type: dialogTypes.CHANGE_DIALOG,
          isOpen: true,
          status: "success",
          message: "Solicitação enviada com sucesso.",
        });
        history.push("/login");
      }
    } catch (err: any) {
      console.log(err);
      dispatch({
        type: dialogTypes.CHANGE_DIALOG,
        isOpen: true,
        status: "error",
        message: err.response.data.message,
      });
    } finally {
      dispatch({
        type: loadingTypes.CHANGE_LOADING,
        isLoading: false,
      });
    }
  };

  return (
    <FormControl
      variant="outlined"
      component="form"
      onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid
          item
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: pxToRem(16),
          }}
          xs={12}>
          <Typography color="primary" variant="h6">
            {" "}
            Solicitar novo acesso
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <CPInput
            name="name"
            placeholder="Nome"
            fullWidth
            value={formik.values.name}
            type="text"
            onChange={formik.handleChange}
            startIcon="person"
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.errors.name}
          />
        </Grid>
        <Grid item xs={12}>
          <CPInput
            name="employer"
            placeholder="Empresa"
            fullWidth
            value={formik.values.employer}
            type="text"
            onChange={formik.handleChange}
            startIcon="business"
            error={formik.touched.employer && Boolean(formik.errors.employer)}
            helperText={formik.errors.employer}
          />
        </Grid>
        <Grid item xs={12}>
          <CPInput
            name="email"
            placeholder="E-mail"
            fullWidth
            value={formik.values.email}
            type="text"
            onChange={formik.handleChange}
            startIcon="email"
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.errors.email}
          />
        </Grid>
        <Grid item xs={12}>
          <CPButton type="submit" variant="contained" fullWidth>
            Enviar solicitação
          </CPButton>
        </Grid>
        <Grid item xs={12}>
          <CPButton
            type="button"
            variant="outlined"
            fullWidth
            fnOnClick={() => history.push("/login")}>
            voltar
          </CPButton>
        </Grid>
      </Grid>
    </FormControl>
  );
};

export default FormNewAccess;
