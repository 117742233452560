/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {Avatar, Badge, Box, Icon} from "@mui/material";
import person from "assets/images/person.svg";
type avatarProps = {
  aWidth?: number;
  aHeight?: number;
  src?: string;
  name?: string | undefined;
  func?: {
    badge?: boolean;
    dropdownMenu?: boolean;
  };
  variant?: "square" | "rounded" | "circular" | undefined;
};

const CPAvatar = (props: avatarProps) => {
  const {func, variant} = props;
  const stringAvatar = (name?: string) => {
    if (name) {
      return {
        children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
      };
    }

    return;
  };

  return (
    <Badge
      invisible={func?.badge === undefined ? true : false}
      overlap="circular"
      anchorOrigin={{vertical: "bottom", horizontal: "right"}}
      badgeContent={
        <Box sx={{backgroundColor: "black", borderRadius: "50%"}}>
          <Icon>photo_camera</Icon>
        </Box>
      }>
      <Avatar
        variant={variant}
        sx={{
          width: props.aWidth || 56,
          height: props.aHeight || 56,
          cursor: "pointer",
          color: "red",

          ":hover": {
            ">img": {
              opacity: 0.6,
            },
          },
        }}
        src={props.src ? props.src : person}
        {...stringAvatar(props.name)}></Avatar>
    </Badge>
  );
};

export default React.memo(CPAvatar);
