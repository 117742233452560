import {dataAPI} from "services/Api/apiConsumer";
import {Authenticate} from "types/Authenticate.types";
import {RequestAccessSystem} from "types/RequestAccessSystem.types";

export const postRequestNewPassword = async (mail: string) => {
  return await dataAPI.post("/users/password/reset", {email: mail});
};

export const postRequestAccessSystem = async (data: RequestAccessSystem) => {
  return await dataAPI.post("/email/requestaccess", data);
};

export const putNewPassword = async (
  id: string,
  newPass: string,
  token: string,
) => {
  return await dataAPI.put(
    `/users/${id}/password`,
    {
      password: newPass,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

export const postAuthenticate = async (data: Authenticate) => {
  return await dataAPI.post(`/users/authenticate`, data);
};
