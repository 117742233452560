/* eslint-disable prefer-const */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import pxToRem from "hooks/usePxToRem";
import {CustomButton, CustomInput} from "ui";
import colors from "global/colors";
import {postOrders, editOrders} from "services/Orders/orders.service";
import RetrieveUserInformation from "hooks/useUserInformation";
import {Upload} from "hooks/useUpload";
import {useDispatch, useSelector} from "react-redux";
import {Types as loadingTypes} from "store/reducers/loadingReducer";
import {Types as snackbarTypes} from "store/reducers/snackbarReducer";
import {Types as modalTypes} from "store/reducers/modalReducer";
import {Types as uploaderTypes} from "store/reducers/uploaderReducer";
import "../scrollBar.scss";
import {CPUploader} from "components";
import WindowDimensions from "hooks/useWindowDimensions";

const ModalNewOrder = () => {
  const dispatch = useDispatch();

  const {dataModal} = useSelector((state: any) => state.stateModal);
  const {uploaderFiles} = useSelector((state: any) => state.stateUploader);
  const {screenWidth} = WindowDimensions();

  const editData = dataModal.editData;

  const blankOrder = {
    clientReference: "",
    provider: "",
    modality: "Marítima",
    incoterm: "",
    notes: "",
    employer: RetrieveUserInformation().employer._id,
    responsible: RetrieveUserInformation()._id,
    documents: [],
  };

  const [newOrder, setNewOrder] = React.useState<any>(blankOrder);
  const [files, setFiles] = React.useState<any>({
    images: [],
  });
  const [validateStatus, setValidateStatus] = useState({
    client: false,
    provider: false,
  });

  const handleOpenSnackbar = (severity: string, message: string) => {
    dispatch({
      type: snackbarTypes.SHOW_SNACKBAR,
      openSnackbar: true,
      message: message,
      severity: severity,
    });
  };

  const handleCloseModal = () => {
    dispatch({
      type: modalTypes.SHOW_MODAL,
      openModal: false,
    });
    dispatch({
      type: uploaderTypes.HANDLE_UPLOADER_FILES,
      uploaderFiles: [],
    });
  };

  const handleChangeModality = (event: SelectChangeEvent) => {
    setNewOrder({
      ...newOrder,
      modality: event.target.value,
    });
  };

  const handleChangeFields = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewOrder({
      ...newOrder,
      [event.target.name]: event.target.value.replace(/\s{2,}/g, " "),
    });
  };

  const handleSubmit = async () => {
    if (validateFields()) return;

    dispatch({
      type: loadingTypes.CHANGE_LOADING,
      isLoading: true,
    });

    const linkDocuments: any[] = [];
    const data = new FormData();
    let temp = newOrder;

    try {
      if (uploaderFiles.length > 0) {
        newOrder.documents.map((link: any) => linkDocuments.push(link));
        for (const doc of uploaderFiles) {
          data.append("image", doc);
          const result: any = await Upload(data);
          linkDocuments.push(result.data.link);
          temp.documents = linkDocuments;
        }
      }

      let response: any = "";

      if (editData !== undefined) {
        response = await editOrders(editData.order._id, temp);
      } else {
        response = await postOrders(temp);
      }

      if (response.status === 200) {
        handleOpenSnackbar("success", "Pedido criado com sucesso!");
      }
      handleCloseModal();
      dataModal.getOrders();
    } catch (err) {
      console.error("err ==>", err);
      handleOpenSnackbar("error", "Não foi possível criar o pedido!");
    } finally {
      dispatch({
        type: loadingTypes.CHANGE_LOADING,
        isLoading: false,
      });
      setNewOrder(blankOrder);
    }
  };

  const validateFields = () => {
    let errors = {
      client: false,
      provider: true,
    };

    let hasError = false;

    if (!newOrder.clientReference) {
      errors.client = true;
      hasError = true;
    } else {
      errors.client = false;
    }

    if (newOrder.clientReference.length <= 2) {
      errors.client = true;
      hasError = true;
    } else {
      errors.client = false;
    }

    if (!newOrder.provider) {
      errors.provider = true;
      hasError = true;
    } else {
      errors.provider = false;
    }
    setValidateStatus({...validateStatus, ...errors});
    return hasError;
  };

  useEffect(() => {
    if (editData && editData !== undefined) {
      setNewOrder({
        ...newOrder,
        clientReference: editData.order.clientReference,
        provider: editData.order.provider,
        modality: editData.order.modality,
        incoterm: editData.order.incoterm,
        notes: editData.order.notes,
        employer: RetrieveUserInformation().employer._id,
        responsible: RetrieveUserInformation()._id,
        documents: editData.order.documents,
      });
    }
  }, [editData]);

  return (
    <React.Fragment>
      <DialogTitle sx={{paddingTop: screenWidth <= 768 ? 0 : pxToRem(16)}}>{`${
        editData === undefined ? "Incluir pedidos" : "Editar pedido"
      }`}</DialogTitle>
      <Divider variant="middle" />

      <DialogContent className="scrollBar">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="caption">
              RF cliente{" "}
              <Typography component="span" sx={{color: colors.error.main}}>
                *
              </Typography>
            </Typography>
            <CustomInput
              error={validateStatus.client}
              helperText={"Campo inválido!"}
              name="clientReference"
              value={newOrder.clientReference}
              required
              onChange={handleChangeFields}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="caption">
              Fornecedor{" "}
              <Typography component="span" sx={{color: colors.error.main}}>
                *
              </Typography>
            </Typography>
            <CustomInput
              error={validateStatus.provider}
              helperText={"Campo inválido"}
              name="provider"
              value={newOrder.provider}
              required
              onChange={handleChangeFields}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="caption">
              Modalidade{" "}
              <Typography component="span" sx={{color: colors.error.main}}>
                *
              </Typography>
            </Typography>
            <Select
              value={newOrder.modality}
              onChange={handleChangeModality}
              required
              fullWidth>
              <MenuItem value={"Marítima"}>Marítima</MenuItem>
              <MenuItem value={"Aérea"}>Aérea</MenuItem>
            </Select>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="caption">
              Incoterm
              <Typography component="span" sx={{color: "transparent"}}>
                *
              </Typography>
            </Typography>

            <CustomInput
              name="incoterm"
              value={newOrder.incoterm}
              onChange={handleChangeFields}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="caption">Observações </Typography>
            <TextField
              name="notes"
              multiline
              value={newOrder.notes}
              placeholder="Escreva as observações aqui..."
              onChange={handleChangeFields}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">Upload de arquivos*</Typography>
            <CPUploader />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: pxToRem(24),
        }}>
        <CustomButton variant="outlined" onClickFn={handleCloseModal}>
          Cancelar
        </CustomButton>
        <CustomButton variant="contained" onClickFn={handleSubmit}>
          {`${
            editData === undefined ? "Cadastrar pedido" : "Atualizar pedido"
          }`}
        </CustomButton>
      </DialogActions>
    </React.Fragment>
  );
};
export default ModalNewOrder;
