/* eslint-disable react-hooks/exhaustive-deps */
import {Suspense, useEffect} from "react";
import {ThemeProvider} from "@mui/material/styles";
import {useSelector, useDispatch} from "react-redux";
import {
  Container,
  Box,
  CircularProgress,
  IconButton,
  Icon,
} from "@mui/material";
import {renderRoutes} from "react-router-config";
import {Types as configsTypes} from "store/reducers/configsLayoutReducer";

import {
  CPAppbar,
  CPContent,
  CPMobileSidebar,
  CPSettings,
  CPLogo,
  CPDivider,
  CPMenu,
  CPConfirmModal,
  CPLoading,
  CPSnackbar,
  CPDrawer,
  CPSidebar,
  CPModal,
} from "components";
import WindowDimensions from "hooks/useWindowDimensions";

import "./private.styles.scss";

import pxToRem from "hooks/usePxToRem";

import darkTheme from "global/themes/darkTheme";
import lightTheme from "global/themes/lightTheme";

import {useLocation} from "react-router-dom";

const Private = (props: any) => {
  const routes = useLocation().pathname.split("/").slice(1);
  const {route} = props;
  const {screenWidth} = WindowDimensions();
  const dispatch = useDispatch();

  const {darkMode, routeActive} = useSelector(
    (state: any) => state.stateConfigsLayout,
  );

  const loadTheme = () => {
    const theme: string | null = localStorage.getItem("theme");
    if (theme) {
      if (JSON.parse(theme) === "dark") {
        dispatch({
          type: configsTypes.SET_DARK_MODE,
          darkMode: true,
        });
      } else {
        dispatch({
          type: configsTypes.SET_DARK_MODE,
          darkMode: false,
        });
      }
    }
  };

  const checkingRouteActive = () => {
    dispatch({
      type: configsTypes.SET_ROUTE_ACTIVE,
      routeActive: routes[0],
    });
  };

  useEffect(() => {
    checkingRouteActive();
  }, [routes[0]]);

  useEffect(() => {
    loadTheme();
  }, []);

  return (
    <ThemeProvider theme={!darkMode ? lightTheme : darkTheme}>
      <Container
        maxWidth="xl"
        disableGutters
        sx={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: darkMode
            ? darkTheme.palette.background.default
            : lightTheme.palette.background.default,
        }}>
        <CPLoading />
        <CPModal />

        <CPSnackbar />
        {screenWidth >= 768 && (
          <Box>
            <CPSidebar>
              <CPLogo />
              <CPDivider />
              <CPMenu />
            </CPSidebar>
          </Box>
        )}
        <CPContent>
          <main className="root_main">
            <CPAppbar />
            <Suspense
              fallback={
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  <CircularProgress color="primary" />
                </Box>
              }>
              {renderRoutes(route.routes)}
            </Suspense>
            <CPDrawer>
              <CPMobileSidebar />
            </CPDrawer>
            <CPConfirmModal />
            <CPSettings />

            {routeActive === "dashboard" && (
              <IconButton
                sx={{
                  position: "fixed",
                  bottom: pxToRem(16),
                  right: pxToRem(16),
                  width: pxToRem(64),
                  height: pxToRem(64),
                  background: !darkMode
                    ? lightTheme.palette.background.paper
                    : darkTheme.palette.background.paper,
                }}>
                <Icon>maps</Icon>
              </IconButton>
            )}
          </main>
        </CPContent>
      </Container>
    </ThemeProvider>
  );
};

export default Private;
