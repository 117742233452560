/* eslint-disable react-hooks/exhaustive-deps */
import {Box, Icon, SpeedDial, SpeedDialAction} from "@mui/material";
import {CPAvatar} from "components";
import colors from "global/colors";
import {useSelector} from "react-redux";
import RetrieveUserInformation from "hooks/useUserInformation";

const CPAvatarMenu = (props: any) => {
  const user = RetrieveUserInformation();
  const {actions} = props;
  const {darkMode} = useSelector((state: any) => state.stateConfigsLayout);

  return (
    <Box>
      <CPAvatar aWidth={48} aHeight={48} src={user.avatar} name={user.name} />
      <SpeedDial
        direction={"down"}
        ariaLabel="SpeedDial"
        sx={{
          position: "absolute",
          top: 10,
          right: 0,
        }}
        FabProps={{
          style: {
            backgroundColor: "transparent",
            color: `${darkMode ? colors.white.main : colors.text.main}`,
            boxShadow: "none",
          },
        }}
        icon={<Icon sx={{color: "transparent"}}>photo_camera</Icon>}>
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={() => action.onClickFn()}
            sx={{whiteSpace: "nowrap", maxWidth: "none"}}
          />
        ))}
      </SpeedDial>
    </Box>
  );
};

export default CPAvatarMenu;
