import {Grid, Typography} from "@mui/material";
import pxToRem from "hooks/usePxToRem";
import WindowDimensions from "hooks/useWindowDimensions";
import {useSelector} from "react-redux";

type Props = {
  data: any[];
  name: string;
  map: any;
};

const CPItemsContainer = (props: Props) => {
  const {screenWidth} = WindowDimensions();
  const {data, name, map} = props;
  const {isLoading} = useSelector((state: any) => state.stateLoading);

  return (
    <Grid
      item
      container
      xs={12}
      sx={{
        width: "100%",
        flexDirection: "column",
        justifyContent: screenWidth <= 768 ? "flex-start" : "",
        height: "99%",
        overflowY: "auto",
        overflowX: "hidden",
        display: "flex",
        paddingBottom: pxToRem(100),
      }}>
      {data.length > 0
        ? map
        : !isLoading && (
            <Grid
              item
              xs={12}
              sx={{textAlign: "center", marginTop: pxToRem(90)}}>
              <Typography color="text.primary" component="span" variant="body2">
                Não há {name} para exibir.
              </Typography>
            </Grid>
          )}
    </Grid>
  );
};

export default CPItemsContainer;
