import {Dialog, Grid} from "@mui/material";
import {useSelector, useDispatch} from "react-redux";
import pxToRem from "hooks/usePxToRem";
import {ModalUploadFile} from "views/Payments/components/OpenedBills/components";
import {ModalNewOrder, ModalViewOrders} from "views/Orders/components";
import {
  ModalRequestRevision,
  ModalAdditionalReference,
} from "views/Payments/components/ApprovePayments/components";
import CPFilePreviewAndDownload from "../CPFilePreviewAndDownload";
import {Types as modalTypes} from "store/reducers/modalReducer";
import {Types as uploaderTypes} from "store/reducers/uploaderReducer";
import WindowDimensions from "hooks/useWindowDimensions";
import {CustomIconButton} from "ui";
import {ModalViewDocument, ModalUploadDocument} from "views/User/components";
import {ModalBrokerPermissions} from "views/Company/components/Brokers/components";
import {Close} from "@mui/icons-material";

const CPModal = () => {
  const dispatch = useDispatch();
  const {screenWidth} = WindowDimensions();
  const {
    uploadModal,
    referenceModal,
    updateModal,
    viewOrdersModal,
    mainOrdersModal,
    downloadModal,
    openUserModal,
    openModal,
    viewDocumentModal,
    brokerPermissionsModal,
  } = useSelector((state: any) => state.stateModal);

  const handleCloseModal = () => {
    dispatch({
      type: modalTypes.SHOW_MODAL,
      openModal: false,
    });
    dispatch({
      type: uploaderTypes.HANDLE_UPLOADER_FILES,
      uploaderFiles: [],
    });
  };

  return (
    <Dialog
      fullScreen={screenWidth <= 768 ? true : false}
      open={openModal}
      onClose={handleCloseModal}
      maxWidth={"lg"}
      PaperProps={{
        sx: {
          borderRadius: screenWidth < 768 ? 0 : pxToRem(12),
          overflow: "hidden",
        },
      }}>
      {screenWidth <= 768 && (
        <Grid container sx={{display: "flex", justifyContent: "flex-end"}}>
          <CustomIconButton
            onClickFn={handleCloseModal}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              padding: pxToRem(5),
            }}>
            <Close fontSize="small" />
          </CustomIconButton>
        </Grid>
      )}

      {updateModal && <ModalRequestRevision />}

      {uploadModal && <ModalUploadFile />}

      {referenceModal && <ModalAdditionalReference />}

      {viewOrdersModal && <ModalViewOrders />}

      {mainOrdersModal && <ModalNewOrder />}

      {downloadModal && <CPFilePreviewAndDownload />}

      {openUserModal && <ModalUploadDocument />}

      {viewDocumentModal && <ModalViewDocument />}

      {brokerPermissionsModal && <ModalBrokerPermissions />}
    </Dialog>
  );
};

export default CPModal;
