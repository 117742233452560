import {Chip, Tooltip} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {
  handleCompany,
  handleLoading,
  handleModal,
  handleSnackbar,
} from "views/Company/utils";
import {Types as modalTypes} from "store/reducers/modalReducer";
import {patchCompany} from "services/Company/company.service";
import {Dispatch, SetStateAction} from "react";
import pxToRem from "hooks/usePxToRem";
import WindowDimensions from "hooks/useWindowDimensions";

type Props = {
  broker: any;
  allBrokers: any[];
  allowedBrokers: any[];
  setAllowedBrokers: Dispatch<SetStateAction<Array<any>>>;
};

const CardAllowedBroker = (props: Props) => {
  const dispatch = useDispatch();

  const {broker, allBrokers, allowedBrokers, setAllowedBrokers} = props;
  const {currentCompany} = useSelector((state: any) => state.stateCompany);
  const {screenWidth} = WindowDimensions();

  const handleOpenBrokerPermissionsModal = (item: any) => {
    handleModal(dispatch, "open");

    dispatch({
      type: modalTypes.OPEN_CHOOSE_MODAL,
      dataModal: item,
      brokerPermissionsModal: true,
    });
  };

  const getBrokerName = (item: any) => {
    const name = allBrokers
      .filter((broker: any) => broker._id === item.person)
      .map((broker: any) => broker.name)[0];
    return name;
  };

  const removeBrokerFromAllowed = async (item: any) => {
    handleLoading(dispatch, "start");
    const tempArray = allowedBrokers;
    const index = tempArray.map((broker) => broker.person).indexOf(item.person);

    if (index > -1) {
      tempArray.splice(index, 1);
    }

    setAllowedBrokers(tempArray);

    try {
      const response = await patchCompany(currentCompany._id, {
        brokers: allowedBrokers,
      });

      if (response.status === 200) {
        handleCompany(dispatch, response.data);
        handleSnackbar(dispatch, "Despachante removido com sucesso", "success");
      }
    } catch (error) {
      console.log(error);
      handleSnackbar(dispatch, "Erro ao remover despachante", "error");
    } finally {
      handleLoading(dispatch, "finish");
    }
  };

  return (
    <Tooltip
      title={`Selecionar permissões ${
        broker.isDefault ? "- Despachante padrão" : ""
      }`}
      placement="top"
      arrow>
      <Chip
        label={getBrokerName(broker)}
        clickable
        onDelete={() => removeBrokerFromAllowed(broker)}
        onClick={() =>
          handleOpenBrokerPermissionsModal({
            ...broker,
            name: getBrokerName(broker),
          })
        }
        sx={{
          maxWidth: "100%",
          marginBottom: pxToRem(7),
          marginRight: screenWidth <= 768 ? 0 : pxToRem(7),
          backgroundColor: broker.isDefault && "primary.main",
          color: broker.isDefault && "#fff",
          display: "flex",
          justifyContent: "space-between",
        }}
      />
    </Tooltip>
  );
};

export default CardAllowedBroker;
