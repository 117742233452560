import React from "react";
import {Backdrop, CircularProgress} from "@mui/material";
import {useSelector} from "react-redux";

const CustomBackdrop = () => {
  const {isLoading} = useSelector((state: any) => state.stateLoading);
  return (
    <Backdrop open={isLoading || false}>
      <CircularProgress color="primary" />
    </Backdrop>
  );
};

export default React.memo(CustomBackdrop);
