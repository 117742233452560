import React, {useState} from "react";
import {DialogTitle, DialogContent, Grid, Tooltip} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {Types as modalTypes} from "store/reducers/modalReducer";
import {Types as snackbarTypes} from "store/reducers/snackbarReducer";
import {Types as loadingTypes} from "store/reducers/loadingReducer";
import {Send} from "@mui/icons-material";
import pxToRem from "hooks/usePxToRem";
import {CustomInput} from "ui";
import {patchProcess} from "services/Process/process.service";
import WindowDimensions from "hooks/useWindowDimensions";

const ModalAdditionalReference = () => {
  const dispatch = useDispatch();

  const {dataModal} = useSelector((state: any) => state.stateModal);
  const {screenWidth} = WindowDimensions();

  const [changeReference, setChangeReference] = useState("");

  const handleCloseModal = () => {
    dispatch({
      type: modalTypes.SHOW_MODAL,
      openModal: false,
    });
    dispatch({
      type: modalTypes.OPEN_CHOOSE_MODAL,
      referenceModal: false,
    });
  };

  const handleLoading = (action: string) => {
    dispatch({
      type: loadingTypes.CHANGE_LOADING,
      isLoading: action === "start" ? true : false,
    });
  };

  const handleSendReference = async () => {
    const data = {
      additionalReference: changeReference,
    };

    try {
      handleLoading("start");
      const response = await patchProcess(dataModal._id, data);

      if (response.status === 200) {
        handleCloseModal();
        dispatch({
          type: snackbarTypes.SHOW_SNACKBAR,
          openSnackbar: true,
          message: "Solicitação realizada com sucesso",
          severity: "success",
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: snackbarTypes.SHOW_SNACKBAR,
        openSnackbar: true,
        message: "Sua solicitação não pôde ser realizada",
        severity: "error",
      });
    } finally {
      handleLoading("finish");
      handleCloseModal();
    }
  };

  const handleChangeAddReference = (event: any) => {
    setChangeReference(event.target.value);
  };

  return (
    <React.Fragment>
      <DialogTitle sx={{paddingTop: screenWidth <= 768 ? 0 : pxToRem(16)}}>
        Inclusão/Alteração da Referência Adicional
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={4}
          sx={{height: pxToRem(100), display: "flex"}}>
          <Grid item xs={12} sx={{alignSelf: "center"}}>
            <CustomInput
              placeholder="Referência adicional"
              value={changeReference}
              onChange={handleChangeAddReference}
              sendIcon={<Send />}
              onClickFn={handleSendReference}
              type="send"
            />
          </Grid>
        </Grid>
      </DialogContent>
    </React.Fragment>
  );
};
export default ModalAdditionalReference;
