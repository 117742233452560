import {DialogContent, DialogTitle, Link, Typography} from "@mui/material";
import pxToRem from "hooks/usePxToRem";
import WindowDimensions from "hooks/useWindowDimensions";
import React from "react";
import {useSelector} from "react-redux";

const ModalViewOrders = () => {
  const {dataModal} = useSelector((state: any) => state.stateModal);
  const {
    responsible,
    clientReference,
    provider,
    incoterm,
    modality,
    notes,
    documents,
    createdAt,
  } = dataModal;
  const {screenWidth} = WindowDimensions();

  const formatDate = (createAt: Date) => {
    const date = new Date(createAt);
    const addZeroToDate = (item: number) => {
      if (item <= 9) {
        return `0${item}`;
      } else {
        return `${item}`;
      }
    };
    const formatedStringDate = `${addZeroToDate(
      date.getDate(),
    )}/${addZeroToDate(date.getMonth() + 1)}/${date.getFullYear()}`;
    return formatedStringDate;
  };

  return (
    <React.Fragment>
      <DialogTitle sx={{paddingTop: screenWidth <= 768 ? 0 : pxToRem(16)}}>
        <Typography sx={{fontSize: pxToRem(35)}}>Pedido</Typography>
      </DialogTitle>

      <DialogContent sx={{"& p": {marginBottom: pxToRem(30)}}}>
        <Typography variant="h6">Informações</Typography>
        <Typography>RF Cliente: {clientReference}</Typography>

        <Typography>Empresa: {responsible.employer.name}</Typography>
        <Typography>Fornecedor: {provider}</Typography>
        <Typography>Incoterm: {incoterm}</Typography>
        <Typography>Modalidade: {modality}</Typography>
        <Typography variant="h6">Observações</Typography>
        <Typography> {notes}</Typography>
        <Typography variant="h6">Arquivos enviados:</Typography>
        {documents.length > 0 ? (
          documents.map((document: string, index) => (
            <Typography key={index} variant="body2">
              <Link href={document} target="_blank">
                Arquivo {index}
              </Link>
            </Typography>
          ))
        ) : (
          <Typography>Nenhum arquivo enviado!</Typography>
        )}

        <Typography variant="h6">Dados de abertura:</Typography>
        <Typography>
          Solicitação realizada por{" "}
          <Typography component="span" sx={{fontWeight: "bold"}}>
            {` ${responsible.name} `}
          </Typography>
          {`em ${formatDate(createdAt)}`}
        </Typography>
      </DialogContent>
    </React.Fragment>
  );
};

export default ModalViewOrders;
