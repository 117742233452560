import {createActions, createReducer} from "reduxsauce";

type initial = {
  userData: Partial<{
    avatar: string;
    document: object;
    employer: object;
    email: string;
    name: string;
    phone: string;
    profiles: string[];
    hasChat: boolean;
    disabled: boolean;
    releasePermissions: object[];
    employersAccessList: object[];
  }>;
};

const INITIAL_STATE: initial = {
  userData: {
    avatar: "",
    document: {},
    email: "",
    name: "",
    employer: {},
    phone: "",
    profiles: [],
    hasChat: true,
    disabled: false,
    releasePermissions: [],
    employersAccessList: [],
  },
};

export const {Types, Creators} = createActions({
  SetUserData: ["userData"],
  ResetUserData: ["userData"],
});

const setUserData = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  userData: action.userData,
});

const resetUserData = (state = INITIAL_STATE) => ({
  ...state,
  userData: {
    ...INITIAL_STATE.userData,
    document: {},
    employer: {},
    profiles: [],
    releasePermissions: [],
    employersAccessList: [],
  },
});

export default createReducer(INITIAL_STATE, {
  [Types.SET_USER_DATA]: setUserData,
  [Types.RESET_USER_DATA]: resetUserData,
});
