import {Dispatch} from "react";
import {Types as companyTypes} from "store/reducers/companyReducer";

const HandleCompany = (dispatch: Dispatch<any>, response: any) => {
  dispatch({
    type: companyTypes.COMPANY_DATA,
    currentCompany: response,
  });
};

export default HandleCompany;
