import {createActions, createReducer} from "reduxsauce";

enum backgroundSidenavEnum {
  dark = "dark",
  light = "light",
}

type initial = {
  darkMode: boolean;
  miniSidenav: boolean;
  backgroundSidenav: backgroundSidenavEnum | null;
  sideNavColor: string;
  fixedNavbar: boolean;
  transparentNavbar: boolean;
  openSettings: boolean;
  menuMobile: boolean;
  routeActive: string;
};

const INITIAL_STATE: initial = {
  darkMode: false,
  miniSidenav: false,
  backgroundSidenav: null,
  sideNavColor: "primary",
  fixedNavbar: true,
  transparentNavbar: true,
  openSettings: false,
  menuMobile: false,
  routeActive: "dashboard",
};

export const {Types, Creators} = createActions({
  setDarkMode: ["darkMode"],
  setMiniSidenav: ["miniSidenav"],
  setBackgroundSidenav: ["backgroundSidenav"],
  setSideNavColor: ["sideNavColor"],
  setFixedNavbar: ["fixedNavbar"],
  setOpenSettings: ["openSettings"],
  setTransparentNavbar: ["transparentNavbar"],
  setOpenMenuMobile: ["menuMobile"],
  setRouteActive: ["routeActive"],
});

const setDarkMode = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  darkMode: action.darkMode,
});

const setMiniSidenav = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  miniSidenav: action.miniSidenav,
});

const setBackgroundSidenav = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  backgroundSidenav: action.backgroundSidenav,
});

const setSideNavColor = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  sideNavColor: action.sideNavColor,
});

const setFixedNavbar = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  fixedNavbar: action.fixedNavbar,
});

const setTransparentNavbar = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  transparentNavbar: action.transparentNavbar,
});

const setOpenSettings = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  openSettings: action.openSettings,
});

const setOpenMenuMobile = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  menuMobile: action.menuMobile,
});

const setRouteActive = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  routeActive: action.routeActive,
});

export default createReducer(INITIAL_STATE, {
  [Types.SET_DARK_MODE]: setDarkMode,
  [Types.SET_MINI_SIDENAV]: setMiniSidenav,
  [Types.SET_BACKGROUND_SIDENAV]: setBackgroundSidenav,
  [Types.SET_SIDE_NAV_COLOR]: setSideNavColor,
  [Types.SET_FIXED_NAVBAR]: setFixedNavbar,
  [Types.SET_TRANSPARENT_NAVBAR]: setTransparentNavbar,
  [Types.SET_OPEN_SETTINGS]: setOpenSettings,
  [Types.SET_OPEN_MENU_MOBILE]: setOpenMenuMobile,
  [Types.SET_ROUTE_ACTIVE]: setRouteActive,
});
