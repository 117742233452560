import React from "react";
import {IconButton} from "@mui/material";

type Props = {
  onClickFn?: () => void;
  children: React.ReactNode;
  sx?: any;
};

const CustomIconButton = (props: Props) => {
  return (
    <IconButton
      sx={{
        ...props.sx,
        "&:hover": {
          backgroundColor: "transparent",
        },
      }}
      onClick={props.onClickFn}>
      {props.children}
    </IconButton>
  );
};

export default React.memo(CustomIconButton);
