import {useState} from "react";
import {Document, Page, pdfjs} from "react-pdf";
import {ArrowForwardIos, ArrowBackIos} from "@mui/icons-material";
import {Box, Button, Paper} from "@mui/material";

import "./CPPDFViewer.scss";

const PDFViewer = (props: any) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({numPages}) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const {pdf} = props;

  return (
    <Paper elevation={0} className="pdf-container">
      <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess} options={{}}>
        <Page key={`page_${pageNumber}`} pageNumber={pageNumber} scale={0.75} />
      </Document>
      <Box className="navigation-controls">
        <Button
          disabled={pageNumber <= 1}
          onClick={() => setPageNumber(pageNumber - 1)}>
          <ArrowBackIos className="navigation-button" />
        </Button>

        <Button
          disabled={pageNumber >= numPages}
          onClick={() => setPageNumber(pageNumber + 1)}>
          <ArrowForwardIos className="navigation-button" />
        </Button>
      </Box>
    </Paper>
  );
};

export default PDFViewer;
