import RetrieveUserInformation from "hooks/useUserInformation";
import {getCompany} from "services/Company/company.service";

const GetCompany = async () => {
  try {
    const response = await getCompany(RetrieveUserInformation().employer._id);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
export default GetCompany;
