import React from "react";
import {Box} from "@mui/material";
import WindowDimensions from "hooks/useWindowDimensions";

type Props = {
  children: React.ReactElement;
};

const CPContent = (props: Props) => {
  const {screenWidth, screenHeight} = WindowDimensions();

  return (
    <Box
      sx={{
        width: screenWidth >= 768 ? "100vw" : "100%",
        minHeight: "100vh",
        flex: 1,
      }}>
      {props.children}
    </Box>
  );
};

export default CPContent;
