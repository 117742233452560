import {Snackbar, Alert} from "@mui/material";
import {useSelector, useDispatch} from "react-redux";
import {Types as snackbarTypes} from "store/reducers/snackbarReducer";

const CPSnackbar = () => {
  const dispatch = useDispatch();
  const {openSnackbar, message, severity} = useSelector(
    (state: any) => state.stateSnackbar,
  );

  const handleCloseSnackbar = () => {
    dispatch({
      type: snackbarTypes.SHOW_SNACKBAR,
      openSnackbar: false,
    });
  };

  return (
    <Snackbar
      open={openSnackbar}
      onClose={handleCloseSnackbar}
      autoHideDuration={6000}
      anchorOrigin={{vertical: "top", horizontal: "right"}}>
      <Alert
        onClose={handleCloseSnackbar}
        severity={severity}
        sx={{width: "100%"}}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default CPSnackbar;
