import React, {FocusEventHandler, useState} from "react";
import {
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
  Icon,
  Tooltip,
  FormLabel,
} from "@mui/material";

import {Visibility, VisibilityOff} from "@mui/icons-material";
import pxToRem from "hooks/usePxToRem";
import {Clear} from "@mui/icons-material";
import {
  DeepRequired,
  FieldError,
  FieldErrorsImpl,
  Merge,
  FieldValues,
  UseFormRegister,
} from "react-hook-form";
import {FormikErrors} from "formik";
import WindowDimensions from "hooks/useWindowDimensions";
import darkTheme from "global/themes/darkTheme";
import {FormikContextType} from "formik";

export type Props = {
  name?: string;
  placeholder?: string;
  error?: boolean;
  helperText?:
    | string[]
    | FormikErrors<any>
    | FormikErrors<any>[]
    | FieldError
    | Merge<FieldError, FieldErrorsImpl<DeepRequired<any>>>
    | string
    | null;
  value?: string | FormikContextType<unknown> | unknown;
  readOnly?: boolean;
  defaultValue?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void | undefined;
  onClickFn?: (event?: any) => void;
  iconStart?: React.ReactElement | string;
  iconEnd?: React.ReactElement | string;
  sendIcon?: React.ReactElement | string;
  inputPassword?: boolean;
  required?: boolean;
  disabled?: boolean;
  fnOnClick?: () => void;
  type?: string;
  fnClearSearch?: (element: any) => void;
  register?: UseFormRegister<FieldValues>;
  onBlur?:
    | FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
  label?: string;
  width?: string;
  mobileMarginBottom?: number;
  desktopMarginBottom?: number;
};

const CustomInput = (props: Props) => {
  const [showPassword, setShowPassword] = useState(false);
  const {disabled = false} = props;
  const {onClickFn} = props;
  const {screenWidth} = WindowDimensions();

  return (
    <FormControl
      variant="outlined"
      required={props.required}
      sx={{
        width: props.width ? props.width : "100%",
        marginBottom:
          screenWidth <= 768
            ? props.mobileMarginBottom
              ? pxToRem(props.mobileMarginBottom)
              : 0
            : props.desktopMarginBottom
            ? pxToRem(props.desktopMarginBottom)
            : 0,
      }}>
      {props.label && <FormLabel>{props.label}:</FormLabel>}
      <OutlinedInput
        {...(props.register && props.name && props.register(props.name))}
        autoComplete="off"
        error={props.error}
        onKeyDown={(e) => e.key === "Enter" && onClickFn?.()}
        type={
          props.inputPassword && !showPassword
            ? "password"
            : props.name === "email"
            ? "email"
            : props.type === "date"
            ? props.type
            : "text"
        }
        value={props.value}
        defaultValue={props.defaultValue}
        name={props.name}
        disabled={disabled}
        readOnly={props.readOnly || false}
        placeholder={props.placeholder}
        sx={{fontSize: darkTheme.typography.body2}}
        onChange={props.onChange}
        onBlur={props.onBlur}
        startAdornment={
          props.iconStart ? (
            <InputAdornment position="start">
              {props.iconStart && <Icon>{props.iconStart}</Icon>}
            </InputAdornment>
          ) : null
        }
        endAdornment={
          props.iconEnd || props.inputPassword ? (
            <InputAdornment position="end">
              {props.inputPassword && (
                <IconButton
                  edge="end"
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              )}
              {props.type === "search" &&
                props.value != "" &&
                props.value != " " && (
                  <IconButton onClick={props.fnClearSearch}>
                    <Clear sx={{fontSize: "0.895rem"}} />
                  </IconButton>
                )}

              {props.iconEnd && (
                <IconButton
                  type="button"
                  edge="end"
                  onClick={props.onClickFn}
                  disabled={props.disabled ? props.disabled : false}>
                  <Icon>{props.iconEnd}</Icon>
                </IconButton>
              )}
            </InputAdornment>
          ) : (
            props.type === "send" &&
            props.value != "" &&
            props.value != " " && (
              <InputAdornment position="end">
                <Tooltip title="Enviar" placement="top" arrow>
                  <IconButton
                    type="submit"
                    edge="end"
                    onClick={props.onClickFn}>
                    <Icon>{props.sendIcon}</Icon>
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            )
          )
        }
      />
      {props.error && (
        <FormHelperText sx={{marginTop: pxToRem(8), color: "#f44336"}}>
          {props.helperText ? props.helperText : "Campo obrigatório"}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default CustomInput;
