import {buildURL, dataAPI} from "services/Api/apiConsumer";

export const getOrders = async (query, sort, page, limit) => {
  return await dataAPI.get(`new-orders/${buildURL(query, sort, page, limit)}`);
};

export const postOrders = async (data: any) => {
  return await dataAPI.post("new-orders", data);
};

export const editOrders = async (id: string, data: any) => {
  return await dataAPI.patch(`new-orders/${id}`, data);
};

export const deleteOrder = async (id) => {
  return await dataAPI.delete(`new-orders/${id}`);
};
