import React from "react";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Icon,
} from "@mui/material";
import "./CPMenu.styles.scss";
import WindowDimensions from "hooks/useWindowDimensions";
import pxToRem from "hooks/usePxToRem";
import listMenu from "local-json/listMenu.json";

const CPMenu = ({action = () => void 0}: any) => {
  const history = useHistory();
  const {screenWidth} = WindowDimensions();
  const [expanded, setExpanded] = React.useState("");
  const {darkMode, routeActive} = useSelector(
    (state: any) => state.stateConfigsLayout,
  );

  const menu = listMenu;

  const checkStyleParentMenu = () => {
    let response = "";
    if (darkMode) {
      response = "parent-active-dark";
    } else {
      response = "parent-active-light";
    }
    return response;
  };

  const checkStyleMenu = (item: any) => {
    const route = item.path !== undefined && item.path.split("/").slice(1);

    let response = "";

    if (routeActive === route[0]) {
      if (darkMode) {
        response = "menu-selected-dark";
      } else {
        response = "menu-selected";
      }
    }

    return response;
  };

  const checkStyleIconMenu = (item: any) => {
    const route = item.path !== undefined && item.path.split("/").slice(1);

    let response = "";

    if (routeActive === route[0]) {
      if (darkMode) {
        response = "menu-icon-selected-dark";
      } else {
        response = "menu-icon-selected";
      }
    }

    return response;
  };

  const verifyIfChildIsActive = () => {
    const hasChild = menu.filter((el) => el.haveChildren);

    const parentMenu = hasChild.filter((el) =>
      el.subMenu!.some((el) => el.path === `/${routeActive}`),
    );

    if (parentMenu !== undefined && parentMenu.length > 0) {
      return parentMenu[0].expanded;
    } else {
      return "";
    }
  };

  React.useEffect(() => {
    setExpanded(verifyIfChildIsActive()!);
  }, []);

  const createMenuRecursive = (item: any, level: number, index: number) => {
    level++;
    return (
      <React.Fragment key={index}>
        <ListItemButton
          sx={{pl: level + 1, mt: 1, ml: level !== 0 ? level + 2 : level}}
          onClick={() => {
            if (item.haveChildren) {
              setExpanded(expanded === item.expanded ? "" : item.expanded);
            } else {
              history.push(`${item.path}`);
              action();
            }
            item.name === "SAIR" && localStorage.removeItem("userInfo");
          }}
          className={`${!item.haveChildren && checkStyleMenu(item)} ${
            item.expanded === verifyIfChildIsActive() && checkStyleParentMenu()
          }`}>
          <ListItemIcon
            sx={{minWidth: pxToRem(40)}}
            className={`${!item.haveChildren && checkStyleIconMenu(item)}`}>
            <Icon sx={{fontSize: 28}}>{item.icon}</Icon>
          </ListItemIcon>
          <ListItemText
            primary={item.name}
            primaryTypographyProps={{
              fontSize: screenWidth <= 1365 ? "0.725rem" : "0.875rem",
            }}
          />
          {!item.haveChildren ? (
            ""
          ) : (expanded === item.expanded && !item.contain) ||
            (expanded !== item.expanded && item.contain) ? (
            <Icon>expand_less</Icon>
          ) : (
            <Icon>expand_more</Icon>
          )}
        </ListItemButton>
        {item.haveChildren && (
          <Collapse
            in={
              (expanded === item.expanded && !item.contain) ||
              (expanded !== item.expanded && item.contain)
            }
            timeout="auto"
            unmountOnExit>
            {item.haveChildren
              ? item.subMenu.map((sub: any, index: number) =>
                  createMenuRecursive(sub, level, index),
                )
              : ""}
          </Collapse>
        )}
      </React.Fragment>
    );
  };

  return (
    <List sx={{width: "87%", margin: "0 auto"}} className="menu-container">
      {menu.map((item: any, index: number) =>
        createMenuRecursive(item, -1, index),
      )}
    </List>
  );
};

export default CPMenu;
