/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState} from "react";
import {
  Grid,
  FormControl,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import CPInput from "components/CPInput";
import {useFormik} from "formik";
import * as yup from "yup";
import {useDispatch} from "react-redux";
import {Types as loadingTypes} from "store/reducers/loadingReducer";
import {Types as dialogTypes} from "store/reducers/dialogReducer";
import {postAuthenticate} from "services/Auth/auth.service";
import ValidatorLogin from "hooks/useValidatorLogin";
import {useHistory} from "react-router-dom";
import CPButton from "components/CPButton";
import pxToRem from "hooks/usePxToRem";

const FormLogin = () => {
  const dispatch = useDispatch();

  const history = useHistory();
  const [form, setForm] = useState({
    type: "password",
    icon: "visibility_off",
    saveLogin: localStorage.getItem(JSON.stringify("username")) ? true : false,
  });

  const loginSchema = yup.object({
    email: yup
      .string()
      .email("Informe um email válido")
      .required("Este campo é obrigatório"),
    password: yup
      .string()
      .min(6, "Mínimo de 6 caracteres")
      .required("Este campo é obrigatório"),
  });

  const handleLogin = async (values: any) => {
    localStorage.removeItem("userInfo");

    if (form.saveLogin) {
      localStorage.setItem("username", JSON.stringify(formik.values.email));
    }

    dispatch({
      type: loadingTypes.CHANGE_LOADING,
      isLoading: true,
    });

    try {
      const response = await postAuthenticate(values);
      if (response.status === 200) {
        if (ValidatorLogin(response.data.employer)) {
          localStorage.setItem("userInfo", JSON.stringify(response.data));
          history.push("/dashboard");
        } else {
          dispatch({
            type: dialogTypes.CHANGE_DIALOG,
            isOpen: true,
            status: "error",
            message: "Usuário não autorizado.",
          });
        }
      }
    } catch (err: any) {
      console.log(err);
      dispatch({
        type: dialogTypes.CHANGE_DIALOG,
        isOpen: true,
        status: "error",
        message: "Erro ao tentar efetuar login.",
      });
    } finally {
      dispatch({
        type: loadingTypes.CHANGE_LOADING,
        isLoading: false,
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      email: localStorage.getItem(JSON.stringify("username")) || "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      handleLogin(values);
    },
  });

  const handleViewPassword = () => {
    if (form.type === "password") {
      setForm({
        ...form,
        type: "text",
        icon: "visibility",
      });
    } else {
      setForm({
        ...form,
        type: "password",
        icon: "visibility_off",
      });
    }
  };

  return (
    <FormControl
      variant="outlined"
      component="form"
      onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid
          item
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: pxToRem(16),
          }}
          xs={12}>
          <Typography color="primary" variant="h6">
            {" "}
            Bem vindo(a)!
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <CPInput
            name="email"
            placeholder="E-mail"
            fullWidth
            value={formik.values.email}
            type="text"
            onChange={formik.handleChange}
            startIcon="email"
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.errors.email}
          />
        </Grid>
        <Grid item xs={12}>
          <CPInput
            fullWidth
            name="password"
            placeholder="Senha"
            type={form.type}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.errors.password}
            startIcon="lock"
            endIcon={form.icon}
            fnIconOnClick={handleViewPassword}
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Grid item xs={6}>
            <FormControlLabel
              componentsProps={{
                typography: {variant: "body2", color: "primary"},
              }}
              control={
                <Checkbox
                  checked={form.saveLogin}
                  onChange={() =>
                    setForm({...form, saveLogin: !form.saveLogin})
                  }
                />
              }
              label={"Lembrar acesso"}
            />
          </Grid>
          <Grid item xs={6} sx={{display: "flex", justifyContent: "flex-end"}}>
            <Typography
              variant="body2"
              color="primary"
              sx={{cursor: "pointer"}}
              onClick={() => history.push("/forgot-password")}>
              Esqueceu a senha?
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <CPButton type="submit" variant="contained" fullWidth>
            ENTRAR
          </CPButton>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: pxToRem(8),
          }}>
          <Typography variant="body2">
            {" "}
            <Typography component="span" sx={{color: "#8e8e8e"}}>
              Novo(a) por aqui?{" "}
            </Typography>{" "}
            <Typography
              color="primary"
              onClick={() => history.push("/new-access")}
              component="span"
              sx={{fontWeight: 500, cursor: "pointer"}}>
              Solicitar novo acesso
            </Typography>{" "}
          </Typography>
        </Grid>
      </Grid>
    </FormControl>
  );
};

export default React.memo(FormLogin);
