import {Button} from "@mui/material";
import pxToRem from "hooks/usePxToRem";

type buttonTypes = {
  children: React.ReactNode;
  type: "button" | "submit";
  fnOnClick?: () => void;
  variant: "text" | "contained" | "outlined";
  fullWidth?: boolean;
};

const CPButton = (props: buttonTypes) => {
  return (
    <Button
      onClick={props.fnOnClick}
      color="primary"
      sx={{padding: pxToRem(16)}}
      variant={props.variant}
      type={props.type}
      fullWidth={props.fullWidth}>
      {props.children}
    </Button>
  );
};

export default CPButton;
