/* eslint-disable react-hooks/exhaustive-deps */
import {Box, Paper, Typography} from "@mui/material";
import {useState, useEffect} from "react";
import Icon from "@mui/material/Icon";
import colors from "global/colors";
import linearGradient from "hooks/useLinearGradient";
import pxToRem from "hooks/usePxToRem";
import CPDivider from "components/CPDivider";
import WindowDimensions from "hooks/useWindowDimensions";

type Props = {
  title: string;
  countIsString?: boolean;
  count: number | string;
  icon: string;
  bgIcon: string;
  variant?: string;
  text?: string;
};

const CPCards = (props: Props) => {
  const {gradients, grey} = colors;
  const {screenWidth} = WindowDimensions();

  const [backgroundValue, setBackgroundValue] = useState("");

  const greyColors = {
    "grey-100": grey[100],
    "grey-200": grey[200],
    "grey-300": grey[300],
    "grey-400": grey[400],
    "grey-500": grey[500],
    "grey-600": grey[600],
    "grey-700": grey[700],
    "grey-800": grey[800],
    "grey-900": grey[900],
  };

  const validGradients = [
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ];

  const validColors = [
    "transparent",
    "white",
    "black",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
    "text",
    "grey-100",
    "grey-200",
    "grey-300",
    "grey-400",
    "grey-500",
    "grey-600",
    "grey-700",
    "grey-800",
    "grey-900",
  ];
  const transformBackground = () => {
    if (props.variant === "gradient") {
      setBackgroundValue(
        validGradients.find((el) => el === props.bgIcon)
          ? linearGradient(
              gradients[props.bgIcon].main,
              gradients[props.bgIcon].state,
            )
          : colors.white.main,
      );
    } else if (validColors.find((el) => el === props.bgIcon)) {
      setBackgroundValue(
        colors[props.bgIcon]
          ? colors[props.bgIcon].main
          : greyColors[props.bgIcon],
      );
    } else {
      setBackgroundValue(props.bgIcon);
    }
  };

  useEffect(() => {
    transformBackground();
  }, [props.variant]);

  return (
    <Paper
      elevation={6}
      sx={{
        borderRadius: pxToRem(12),
        padding: pxToRem(12),
        overflow: "visible",
        marginTop: pxToRem(8),
        cursor: "pointer",
      }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}>
        <Paper
          elevation={3}
          sx={{
            color: "#FFF",
            background: backgroundValue,
            display: "flex",
            justifyContent: "center",
            marginTop: pxToRem(-32),
            width: pxToRem(64),
            height: pxToRem(64),
            alignItems: "center",
            borderRadius: pxToRem(8),
          }}>
          <Icon fontSize="large">{props.icon}</Icon>
        </Paper>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}>
          <Box>
            <Typography variant="subtitle1" sx={{fontWeight: 400}}>
              {props.title}
            </Typography>
          </Box>
          <Box sx={{textAlign: "right"}}>
            <Typography
              variant={
                props.countIsString ? "h6" : screenWidth >= 768 ? "h3" : "h2"
              }
              sx={{fontWeight: 700}}>
              {props.count}
            </Typography>
          </Box>
        </Box>
      </Box>

      <CPDivider />
      <Box>
        <Typography variant="body2" sx={{fontWeight: 400}}>
          {props.text}
        </Typography>
      </Box>
    </Paper>
  );
};

export default CPCards;
