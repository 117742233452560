import {Grid} from "@mui/material";
import {CustomButton} from "ui";
import WindowDimensions from "hooks/useWindowDimensions";
import pxToRem from "hooks/usePxToRem";
import {useHistory} from "react-router-dom";

const FormFooter = () => {
  const {screenWidth} = WindowDimensions();
  const history = useHistory();

  return (
    <Grid
      container
      sx={{
        display: "flex",
        justifyContent: screenWidth <= 768 ? "space-between" : "space-evenly",
        padding: screenWidth <= 768 ? `${pxToRem(16)}` : `${pxToRem(16)}`,
      }}>
      <Grid
        item
        sm={screenWidth >= 768 && 6}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          paddingLeft: pxToRem(16),
        }}>
        <CustomButton
          onClickFn={() => {
            history.goBack();
          }}
          variant="outlined">
          Cancelar
        </CustomButton>
      </Grid>

      <Grid
        item
        sm={screenWidth >= 768 && 6}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          paddingRight: pxToRem(16),
        }}>
        <CustomButton submit={true}>Confirmar</CustomButton>
      </Grid>
    </Grid>
  );
};

export default FormFooter;
