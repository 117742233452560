import {Grid, Typography} from "@mui/material";
import pxToRem from "hooks/usePxToRem";
import WindowDimensions from "hooks/useWindowDimensions";
import {ReactNode} from "react";

type Props = {
  children: ReactNode;
  title: string;
};

const InformationContainer = (props: Props) => {
  const {screenWidth} = WindowDimensions();

  const {children, title} = props;

  return (
    <Grid
      item
      container
      sm={screenWidth > 768 && 5.9}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        height: screenWidth <= 768 ? "auto" : "100%",
        padding: pxToRem(16),
        borderRadius: pxToRem(5),
        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)",
        width: screenWidth <= 768 ? "100%" : "auto",
      }}>
      <Typography>{title}</Typography>
      <Grid
        item
        container
        sx={{
          height: "93%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          flexWrap: "nowrap",
        }}>
        {children}
      </Grid>
    </Grid>
  );
};

export default InformationContainer;
