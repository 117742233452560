import {InfoContainer, CardItemBroker} from "../index";
import WindowDimensions from "hooks/useWindowDimensions";
import {GetBrokers} from "api";
import {Grid} from "@mui/material";

const AllBrokersContainer = () => {
  const {allBrokers, filteredBrokers, allowedBrokers, setAllowedBrokers} =
    GetBrokers();
  const {screenWidth} = WindowDimensions();

  return (
    <InfoContainer title="Despachantes cadastrados" desktopHeight="62%">
      {filteredBrokers.length > 10 ? (
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: screenWidth <= 768 ? "column" : "row",
            height: screenWidth <= 768 ? "auto" : "88%",
            overflowY: "auto",
          }}>
          <Grid item sm={screenWidth <= 768 ? 12 : 6}>
            {filteredBrokers
              .slice(0, Math.ceil(allBrokers.length / 2))
              .map((broker: any, index: number) => (
                <CardItemBroker
                  key={index}
                  allowedBrokers={allowedBrokers}
                  setAllowedBrokers={setAllowedBrokers}
                  broker={broker}
                />
              ))}
          </Grid>
          <Grid item sm={screenWidth <= 768 ? 12 : 6}>
            {filteredBrokers
              .slice(
                Math.ceil(filteredBrokers.length / 2),
                filteredBrokers.length,
              )
              .map((broker: any, index: number) => (
                <CardItemBroker
                  key={index}
                  allowedBrokers={allowedBrokers}
                  setAllowedBrokers={setAllowedBrokers}
                  broker={broker}
                />
              ))}
          </Grid>
        </Grid>
      ) : (
        <Grid container>
          {filteredBrokers?.map((broker: any, index: number) => (
            <CardItemBroker
              key={index}
              allowedBrokers={allowedBrokers}
              setAllowedBrokers={setAllowedBrokers}
              broker={broker}
            />
          ))}
        </Grid>
      )}
    </InfoContainer>
  );
};
export default AllBrokersContainer;
