import {createActions, createReducer} from "reduxsauce";

enum statusEnum {
  success = "success",
  error = "error",
}

type initial = {
  isOpen: boolean;
  status: statusEnum | undefined;
  message: string;
};

const INITIAL_STATE: initial = {
  isOpen: false,
  status: undefined,
  message: "",
};

export const {Types, Creators} = createActions({
  changeDialog: ["isOpen", "status", "message"],
});

const changeDialog = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  isOpen: action.isOpen,
  status: action.status,
  message: action.message,
});

export default createReducer(INITIAL_STATE, {
  [Types.CHANGE_DIALOG]: changeDialog,
});
