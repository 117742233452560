import React from "react";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";

import pxToRem from "hooks/usePxToRem";
import WindowDimensions from "hooks/useWindowDimensions";

import {useDispatch, useSelector} from "react-redux";
import {Types as modalTypes} from "store/reducers/modalReducer";

import {CustomButton} from "ui";
import {PDFViewer} from "components";

const ModalViewDocument = () => {
  const dispatch = useDispatch();
  const {dataModal} = useSelector((state: any) => state.stateModal);
  const {screenWidth} = WindowDimensions();

  const handleCloseModal = () => {
    dispatch({
      type: modalTypes.SHOW_MODAL,
      openModal: false,
    });
  };

  return (
    <React.Fragment>
      <DialogTitle sx={{paddingTop: screenWidth <= 768 ? 0 : pxToRem(16)}}>
        Visualizar arquivo
      </DialogTitle>
      <Divider variant="middle" />
      <DialogContent>
        <Typography noWrap sx={{mb: pxToRem(15)}}>
          Arquivo: {dataModal.fileName}
        </Typography>
        {dataModal.type.match("pdf") ? (
          <PDFViewer pdf={{url: dataModal.url}} />
        ) : (
          <Box
            component="img"
            src={dataModal.url}
            sx={{maxWidth: screenWidth < 768 ? "100%" : pxToRem(500)}}
          />
        )}
      </DialogContent>
      <DialogActions>
        <CustomButton variant="outlined" onClickFn={handleCloseModal}>
          OK
        </CustomButton>
      </DialogActions>
    </React.Fragment>
  );
};

export default ModalViewDocument;
