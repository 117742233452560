import {createActions, createReducer} from "reduxsauce";

type initial = {
  isLoading: boolean;
};

const INITIAL_STATE: initial = {
  isLoading: false,
};

export const {Types, Creators} = createActions({
  changeLoading: ["isLoading"],
});

const changeLoading = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  isLoading: action.isLoading,
});

export default createReducer(INITIAL_STATE, {
  [Types.CHANGE_LOADING]: changeLoading,
});
