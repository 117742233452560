import React, {useState} from "react";
import {
  TextField,
  DialogTitle,
  Typography,
  DialogContent,
  DialogActions,
} from "@mui/material";

import RetrieveUserInformation from "hooks/useUserInformation";
import {useDispatch, useSelector} from "react-redux";
import {Types as modalTypes} from "store/reducers/modalReducer";
import {Types as snackbarTypes} from "store/reducers/snackbarReducer";
import {Types as loadingTypes} from "store/reducers/loadingReducer";
import {CustomButton} from "ui";
import pxToRem from "hooks/usePxToRem";
import {requestValueReview} from "services/Payments/payments.service";
import WindowDimensions from "hooks/useWindowDimensions";

const RequestRevision = () => {
  const dispatch = useDispatch();

  const {screenWidth} = WindowDimensions();
  const {dataModal} = useSelector((state: any) => state.stateModal);
  const {additionalReference, clientReference, processNumber} = dataModal;

  const [content, setContent] = useState("");

  const handleCloseModal = () => {
    dispatch({
      type: modalTypes.SHOW_MODAL,
      openModal: false,
    });

    dispatch({
      type: modalTypes.OPEN_CHOOSE_MODAL,
      updateModal: false,
    });
  };

  const handleOpenSnackbar = (severity: string, message: string) => {
    dispatch({
      type: snackbarTypes.SHOW_SNACKBAR,
      openSnackbar: true,
      message: message,
      severity: severity,
    });
  };
  const handleLoading = (action: string) => {
    dispatch({
      type: loadingTypes.CHANGE_LOADING,
      isLoading: action === "start" ? true : false,
    });
  };

  const valueReview = async (description: any) => {
    try {
      handleLoading("start");
      const data = {
        name: RetrieveUserInformation().name,
        email: RetrieveUserInformation().email,
        processNumber: processNumber,
        description: description,
      };

      const response = await requestValueReview(data);
      if (response.status === 200 || 201) {
        handleCloseModal();
        handleOpenSnackbar(
          "success",
          "Solicitação de revisão de valores enviada.",
        );
      }
    } catch (error) {
      console.log(error);
      handleOpenSnackbar(
        "error",
        "Não foi possível solicitar a revisão. Entre em contato com os administradores.",
      );
    } finally {
      handleLoading("finish");
      handleCloseModal();
    }
  };

  return (
    <React.Fragment>
      <DialogTitle sx={{paddingTop: screenWidth <= 768 ? 0 : pxToRem(16)}}>
        Pedido de revisão/atualização
      </DialogTitle>
      <Typography sx={{paddingLeft: pxToRem(24)}}>
        {additionalReference && additionalReference !== ""
          ? additionalReference
          : clientReference}
      </Typography>

      <DialogContent>
        <TextField
          sx={{width: "100%", height: screenWidth <= 768 ? "100%" : "auto"}}
          rows={screenWidth <= 768 ? 16 : 6}
          multiline
          autoComplete="on"
          variant="outlined"
          placeholder="Descreva os itens que precisam de revisão/correção ou solicite a atualização da taxa de conversão."
          value={content}
          onChange={(event) => {
            setContent(event.target.value);
          }}
        />
      </DialogContent>
      <DialogActions
        sx={{
          padding: `${pxToRem(8)} ${pxToRem(24)} ${pxToRem(24)} ${pxToRem(24)}`,
          display: "flex",
          justifyContent: "space-between",
        }}>
        <CustomButton variant="outlined" onClickFn={handleCloseModal}>
          Cancelar
        </CustomButton>
        <CustomButton
          disabled={content != "" && content != " " ? false : true}
          variant="contained"
          onClickFn={() => valueReview(content)}>
          Enviar solicitação
        </CustomButton>
      </DialogActions>
    </React.Fragment>
  );
};

export default RequestRevision;
