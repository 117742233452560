import {Grid, Icon, Typography} from "@mui/material";

import pxToRem from "hooks/usePxToRem";

import {Types as modalTypes} from "store/reducers/modalReducer";
import {useDispatch, useSelector} from "react-redux";

import {CustomButton} from "ui";
import colors from "global/colors";
import {useFormikContext} from "formik";
import {UserFormDataType} from "views/User/types/userTypes";
type Props = {
  title: string;
  type: string;
  canUpload?: () => void;
};

const SendDocument = ({title, type, canUpload}: Props) => {
  const {employerData} = useSelector((state: any) => state.stateEmployerData);
  const formik = useFormikContext<UserFormDataType>();

  const dispatch = useDispatch();

  const handleOpenCPModal = () => {
    dispatch({
      type: modalTypes.SHOW_MODAL,
      openModal: true,
    });
  };

  const handleOpenUploadModal = () => {
    if (!employerData.selectedEmployer || !employerData.dueDate) {
      if (type === "proxy") {
        canUpload!();
        return;
      }
    }

    handleOpenCPModal();
    dispatch({
      type: modalTypes.OPEN_CHOOSE_MODAL,
      openUserModal: true,
      dataModal: {type: type, formData: formik},
    });
    type === "proxy" && canUpload!();
  };

  return (
    <Grid
      item
      xs={12}
      md={8}
      sx={{
        mt: pxToRem(15),
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
      }}>
      <Typography variant="caption">
        {title}
        <Typography component="span" sx={{color: colors.error.main}}>
          *
        </Typography>
      </Typography>
      <Grid
        item
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          textAlign: "center",
        }}>
        <CustomButton onClickFn={handleOpenUploadModal}>
          Enviar arquivo <Icon>upload</Icon>
        </CustomButton>
      </Grid>
    </Grid>
  );
};

export default SendDocument;
