import {Dispatch} from "react";
import {Types as snackbarTypes} from "store/reducers/snackbarReducer";

const HandleSnackBar = (
  dispatch: Dispatch<any>,
  message: string,
  severity: "success" | "error" | "warning" | "info",
) => {
  dispatch({
    type: snackbarTypes.SHOW_SNACKBAR,
    openSnackbar: true,
    message: message,
    severity: severity,
  });
};

export default HandleSnackBar;
