const RetrieveUserInformation = () => {
  const user = localStorage.getItem("userInfo");

  if (user) {
    return JSON.parse(user);
  } else {
    return undefined;
  }
};

export default RetrieveUserInformation;
