import {CustomInput} from "ui";
import WindowDimensions from "hooks/useWindowDimensions";
import {useSelector, useDispatch} from "react-redux";
import {Types as companyTypes} from "store/reducers/companyReducer";
import {Types as snackbarTypes} from "store/reducers/snackbarReducer";
import {Grid} from "@mui/material";
import getAddressFromZipcode from "services/CEP";
import {InformationContainer} from "../index";
import {Search} from "@mui/icons-material";
import {useFormikContext} from "formik";

type Props = {
  readOnly: boolean;
};

const Address = (props: Props) => {
  const {readOnly} = props;
  const {screenWidth} = WindowDimensions();

  const {currentCompany} = useSelector((state: any) => state.stateCompany);

  const formik = useFormikContext<any>();
  const dispatch = useDispatch();

  const handleOpenSnackbar = (severity: string, message: string) => {
    dispatch({
      type: snackbarTypes.SHOW_SNACKBAR,
      openSnackbar: true,
      message: message,
      severity: severity,
    });
  };

  const getCompanyAddressFromZipCode = async (
    zipcode: number | string | undefined,
  ) => {
    try {
      const response = await getAddressFromZipcode(zipcode);

      if (response) {
        dispatch({
          type: companyTypes.COMPANY_DATA,
          currentCompany: {
            ...currentCompany,
            address: {
              ...currentCompany.address,
              street: response.data?.logradouro,
              district: response.data?.bairro,
              city: response.data?.localidade,
              zipcode: response.data?.cep,
              state: response.data?.uf,
              complement: response.data?.complemento,
            },
          },
        });
      }

      if (response.data.erro) {
        handleOpenSnackbar(
          "error",
          "CEP não encontrado. Verifique se os dígitos estão corretos.",
        );
      }
    } catch (error) {
      console.log(error);
      handleOpenSnackbar(
        "error",
        "CEP não encontrado. Verifique se os dígitos estão corretos.",
      );
    }
  };

  return (
    <InformationContainer title="Endereço">
      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: screenWidth <= 768 ? "column" : "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}>
        <CustomInput
          type="company"
          disabled={readOnly}
          name="zipcode"
          error={formik.touched.zipcode && Boolean(formik.errors.zipcode)}
          helperText={formik.errors?.zipcode}
          value={formik.values.zipcode}
          onChange={formik.handleChange}
          iconEnd={<Search fontSize="small" color="primary" />}
          onClickFn={() => getCompanyAddressFromZipCode(formik.values.zipcode)}
          label="CEP"
          width={screenWidth <= 768 ? "100%" : "35%"}
          mobileMarginBottom={25}
        />
        <CustomInput
          type="company"
          disabled={readOnly}
          name="street"
          error={formik.touched.street && Boolean(formik.errors.street)}
          helperText={formik.errors?.street}
          value={formik.values.street}
          onChange={formik.handleChange}
          label="Logradouro"
          width={screenWidth <= 768 ? "100%" : "60%"}
          mobileMarginBottom={25}
        />
      </Grid>
      <CustomInput
        type="company"
        disabled={readOnly}
        name="district"
        error={formik.touched.district && Boolean(formik.errors.district)}
        helperText={formik.errors?.district}
        value={formik.values.district}
        onChange={formik.handleChange}
        label="Bairro"
        mobileMarginBottom={25}
      />
      <CustomInput
        type="company"
        disabled={readOnly}
        name="city"
        error={formik.touched.city && Boolean(formik.errors.city)}
        helperText={formik.errors?.city}
        value={formik.values.city}
        onChange={formik.handleChange}
        label="Cidade"
        mobileMarginBottom={25}
      />
      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: screenWidth <= 768 ? "column" : "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}>
        <CustomInput
          type="company"
          disabled={readOnly}
          name="state"
          error={formik.touched.state && Boolean(formik.errors.state)}
          helperText={formik.errors?.state}
          value={formik.values.state}
          onChange={formik.handleChange}
          label="UF"
          width={screenWidth <= 768 ? "100%" : "20%"}
          mobileMarginBottom={25}
        />
        <CustomInput
          type="company"
          disabled={readOnly}
          name="complement"
          error={formik.touched.complement && Boolean(formik.errors.complement)}
          helperText={formik.errors?.complement}
          value={formik.values.complement}
          onChange={formik.handleChange}
          label="Complemento"
          width={screenWidth <= 768 ? "100%" : "75%"}
          mobileMarginBottom={25}
        />
      </Grid>
    </InformationContainer>
  );
};

export default Address;
