import {Grid} from "@mui/material";
import {useSelector} from "react-redux";

import {useHistory} from "react-router-dom";

import WindowDimensions from "hooks/useWindowDimensions";

import {CustomButton} from "ui";
import {useFormikContext} from "formik";

const UserSubmit = () => {
  const {routeActive} = useSelector((state: any) => state.stateConfigsLayout);

  const history = useHistory();

  const {screenWidth} = WindowDimensions();
  const {submitForm} = useFormikContext();

  const handleSubmitForm = () => {
    submitForm();
  };

  return (
    <Grid
      item
      xs={12}
      sx={{
        display: "flex",
        justifyContent: screenWidth < 1200 ? "space-between" : "flex-end",
        gap: 2,
      }}>
      <CustomButton onClickFn={() => history.goBack()} variant="outlined">
        Cancelar
      </CustomButton>
      <CustomButton type="submit" onClickFn={handleSubmitForm} submit={true}>
        {routeActive === "user-edit" ? "Atualizar" : "Cadastrar"}
      </CustomButton>
    </Grid>
  );
};

export default UserSubmit;
