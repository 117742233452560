import axios from "axios";
import {buildURL, dataAPI} from "services/Api/apiConsumer";
import {mobileDataAPI} from "services/Api/apiMobileConsumer";

export const getProcesses = async (
  query: any,
  sort: string,
  page: number,
  limit: number,
) => {
  return await dataAPI.get(
    `cargoprocesses/${buildURL(query, sort, page, limit)}`,
  );
};

export const patchProcess = async (processID: string, data: any) => {
  return await dataAPI.patch(`cargoprocesses/${processID}/update/`, data);
};

export const getProcessByIdMobile = async (processID) => {
  return await mobileDataAPI.get(`cargoprocesses/${processID}`);
};
