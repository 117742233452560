import {Types as modalTypes} from "store/reducers/modalReducer";
import {Dispatch} from "react";

const HandleModal = (dispatch: Dispatch<any>, action: "open" | "close") => {
  dispatch({
    type: modalTypes.SHOW_MODAL,
    openModal: action === "open" ? true : false,
  });
};

export default HandleModal;
