import {ReactNode} from "react";

import {AccordionDetails, AccordionSummary, Typography} from "@mui/material";

import StyledAccordion from "./CPAccordion.styles";

import {Icon} from "@iconify/react";

interface CPAccordionProps {
  icon?: string;
  accordionTitle: string;
  children: ReactNode;
}

const CPAccordion = ({icon, accordionTitle, children}: CPAccordionProps) => {
  return (
    <StyledAccordion elevation={0} className="accordion" disableGutters>
      <AccordionSummary
        className="accordion__summary"
        expandIcon={
          <Icon
            icon="dashicons:arrow-down-alt2"
            className="accordion__icon--right"
          />
        }>
        {icon && <Icon icon={icon} className="accordion__icon--left" />}

        <Typography
          component="strong"
          variant="subtitle3"
          className="accordion__title">
          {accordionTitle}
        </Typography>
      </AccordionSummary>

      <AccordionDetails className="accordion__details">
        {children}
      </AccordionDetails>
    </StyledAccordion>
  );
};

export default CPAccordion;
