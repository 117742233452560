import GetCompany from "api/Company";
import {useEffect, useState} from "react";
import {getCompanies} from "services/Company/company.service";
import {useSelector, useDispatch} from "react-redux";
import {
  handleSnackbar,
  handleLoading,
  handleCompany,
} from "views/Company/utils";

const GetBrokers = () => {
  const dispatch = useDispatch();
  const {currentCompany} = useSelector((state: any) => state.stateCompany);

  const [allBrokers, setAllBrokers] = useState<any[]>([]);
  const [filteredBrokers, setFilteredBrokers] = useState<any[]>(allBrokers);
  const [allowedBrokers, setAllowedBrokers] = useState<any[]>([]);

  const loadCompany = async () => {
    handleLoading(dispatch, "start");

    const response = await GetCompany();

    if (response._id != "" && currentCompany._id === "") {
      handleCompany(dispatch, response);
    }

    handleLoading(dispatch, "finish");
  };

  const getAllBrokersList = async () => {
    loadCompany();

    const criteria: Array<any> = [];
    criteria.push({name: {$exists: true}});
    criteria.push({profiles: {$in: "broker"}});

    try {
      const response: any = await getCompanies(
        criteria.length > 0 && JSON.stringify({$and: criteria}),
        "name",
      );

      setAllBrokers(response.data.items);
      setFilteredBrokers(response.data.items);
    } catch (error) {
      console.log(error);
      handleSnackbar(dispatch, "Erro ao buscar despachantes", "error");
    } finally {
      handleLoading(dispatch, "finish");
    }
  };

  useEffect(() => {
    getAllBrokersList();
  }, []);

  const getCompanyBrokers = () => {
    loadCompany();
    setAllowedBrokers(currentCompany?.brokers);
  };

  useEffect(() => {
    getCompanyBrokers();
  }, [currentCompany]);

  return {
    getAllBrokersList,
    allBrokers,
    filteredBrokers,
    setFilteredBrokers,
    allowedBrokers,
    setAllowedBrokers,
  };
};

export default GetBrokers;
