import {Grid, Container} from "@mui/material";
import {
  FormForgotPassword,
  FormLogin,
  FormNewAccess,
  FormNewPassword,
} from "./components";
import {useLocation} from "react-router-dom";

const CPForm = () => {
  const routes = useLocation().pathname.split("/").slice(1)[0];

  return (
    <Container>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          margin: "0 auto",
        }}>
        <Grid item xs={12} md={8}>
          {console.log("routes => ", routes)}
          {routes === "login" && <FormLogin />}
          {routes === "forgot-password" && <FormForgotPassword />}
          {routes === "new-access" && <FormNewAccess />}
          {routes === "new-password" && <FormNewPassword />}
        </Grid>
      </Grid>
    </Container>
  );
};

export default CPForm;
