import React from "react";
import {
  Grid,
  Typography,
  Paper,
  Box,
  Tab,
  Checkbox,
  FormControlLabel,
  Tabs,
  Link,
  Icon,
  Tooltip,
} from "@mui/material";

import pxToRem from "hooks/usePxToRem";
import RetrieveUserInformation from "hooks/useUserInformation";
import WindowDimensions from "hooks/useWindowDimensions";

import {Types as userTypes} from "store/reducers/userDataReducer";
import {Types as modalTypes} from "store/reducers/modalReducer";
import {useDispatch, useSelector} from "react-redux";

import {CustomIconButton} from "ui";
import colors from "global/colors";

import type {Document, EmployersAccessList} from "./types";
import {useFormikContext} from "formik";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function controlTabsById(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const EmployerList = () => {
  const [tabValue, setTabValue] = React.useState(0);
  const {userData} = useSelector((state: any) => state.stateUserData);
  const user = RetrieveUserInformation();
  const dispatch = useDispatch();
  const {screenWidth} = WindowDimensions();
  const [filiais, setFiliais] = React.useState<EmployersAccessList[]>([
    {name: "", cnpj: ""},
  ]);
  const [matriz, setMatriz] = React.useState<EmployersAccessList[]>([
    {name: "", cnpj: ""},
  ]);
  const [status, setStatus] = React.useState<boolean>(true);

  const {routeActive} = useSelector((state: any) => state.stateConfigsLayout);
  const formik = useFormikContext<any>();

  const handleChangeTab = (_: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const getAccessList = async () => {
    setMatriz(
      user.employersAccessList.filter((item: EmployersAccessList) =>
        item.cnpj.match(/0001/),
      ),
    );
    setFiliais(
      user.employersAccessList.filter(
        (item: EmployersAccessList) => !item.cnpj.match(/0001/),
      ),
    );
  };

  const statusOfMatrizCheckbox = () => {
    if (routeActive === "user-new") {
      return status;
    } else {
      return userData.employersAccessList?.some(
        (item: any) => item._id === matriz[0]._id,
      );
    }
  };

  const handleMatrizCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (routeActive === "user-new") {
      const tempStatus = status;
      setStatus(!status);

      if (tempStatus) {
        return;
      }
    }
    handleCheckbox(event);
  };

  const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!user.roles.includes("CompanyAdministrator")) return;
    if (
      userData.employersAccessList.some(
        (item: any) => item._id === event.target.value,
      )
    ) {
      const newEmployersAccessList = userData.employersAccessList.filter(
        (item: any) => item._id !== event.target.value,
      );
      dispatch({
        type: userTypes.SET_USER_DATA,
        userData: {
          ...userData,
          employersAccessList: newEmployersAccessList,
        },
      });
    } else {
      const newEmployersAccessList = userData.employersAccessList;
      const employersAccessList = user.employersAccessList.find(
        (item: any) => item._id === event.target.value,
      );
      newEmployersAccessList.push(employersAccessList);
      dispatch({
        type: userTypes.SET_USER_DATA,
        userData: {
          ...userData,
          employersAccessList: newEmployersAccessList,
        },
      });
    }
  };

  const deleteConfirmation = (id: string) => {
    const dataAndActions = {
      action: () => deleteProxy(id),
      title: "Deseja excluir a procuração?",
      message:
        "Após a exclusão será necessário vincular outra procuração para a aprovação.",
      icon: "priority_high",
      color: colors.badgeColors.error.text,
    };

    dispatch({
      type: modalTypes.SHOW_CONFIRM_MODAL,
      openConfirmModal: true,
    });

    dispatch({
      type: modalTypes.SET_DATA_CONFIRM_MODAL,
      dataConfirmModal: dataAndActions,
    });
  };

  const deleteProxy = (id: string) => {
    const newPermission = userData.releasePermissions.filter(
      (item) => item.documents[0]._id !== id,
    );

    formik.setFieldValue("releasePermissions", newPermission);
    dispatch({
      type: userTypes.SET_USER_DATA,
      userData: {
        ...userData,
        releasePermissions: newPermission,
      },
    });
  };

  const deleteDocument = () => {
    formik.setFieldValue("document", null);
    dispatch({
      type: userTypes.SET_USER_DATA,
      userData: {
        ...userData,
        document: "",
      },
    });
  };

  const handleOpenCPModal = () => {
    dispatch({
      type: modalTypes.SHOW_MODAL,
      openModal: true,
    });
  };

  const handleOpenViewDocumentModal = (document: Document) => {
    console.log("document", document);
    handleOpenCPModal();

    dispatch({
      type: modalTypes.OPEN_CHOOSE_MODAL,
      viewDocumentModal: true,
      dataModal: document,
    });
  };

  React.useEffect(() => {
    getAccessList();
  }, []);

  return (
    <Grid item xs={12}>
      <Paper elevation={6} sx={{mt: pxToRem(15), p: pxToRem(20)}}>
        <Box sx={{borderBottom: 1, borderColor: "divider"}}>
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            variant={screenWidth < 768 ? "fullWidth" : "standard"}>
            <Tab label="Empresas" {...controlTabsById(0)} />
            {user.roles.includes("DocumentRelease") && (
              <Tab label="Permissões" {...controlTabsById(1)} />
            )}
            {user.roles.includes("DocumentRelease") && (
              <Tab label="Documento" {...controlTabsById(3)} />
            )}
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
          {userData.employersAccessList && (
            <Grid container sx={{mt: pxToRem(15)}}>
              <Typography>Matriz</Typography>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mb: pxToRem(20),
                }}>
                <FormControlLabel
                  label={`${matriz[0].cnpj} - ${matriz[0].name}`}
                  control={
                    <Checkbox
                      checked={statusOfMatrizCheckbox()}
                      name="employersAccessList"
                      value={matriz[0]._id}
                      onChange={handleMatrizCheckbox}
                    />
                  }
                />
              </Grid>
              <Typography>Filiais</Typography>
              <Grid item xs={12}>
                {filiais?.map((filial) => (
                  <FormControlLabel
                    key={filial.cnpj}
                    label={`${filial.cnpj} - ${filial.name}`}
                    control={
                      <Checkbox
                        checked={formik.values.employersAccessList?.some(
                          (item: any) => item._id === filial._id,
                        )}
                        name="employersAccessList"
                        value={filial._id}
                        onChange={handleCheckbox}
                      />
                    }
                  />
                ))}
              </Grid>
            </Grid>
          )}
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          {formik.values.releasePermissions?.length > 0 ? (
            <Grid container sx={{mt: pxToRem(15)}}>
              <Typography>Empresas</Typography>
              {formik.values.releasePermissions?.map((item, index) => (
                <Grid
                  item
                  key={index}
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mb: pxToRem(20),
                  }}>
                  <FormControlLabel
                    label={
                      <Typography>
                        {item?.employer?.name} - {item?.employer?.cnpj} |
                        Status:{" "}
                        <Typography
                          component="span"
                          sx={{color: "primary.main"}}>
                          {item?.employer?.isActive ? "Ativa" : "Inativa"}
                        </Typography>
                        {" | Procuração: "}
                        <Link
                          onClick={() =>
                            handleOpenViewDocumentModal(item.documents[0])
                          }>
                          Ver
                        </Link>
                        {" | "}
                        <Link
                          onClick={() =>
                            deleteConfirmation(item.documents[0]._id)
                          }>
                          Deletar
                        </Link>
                      </Typography>
                    }
                    control={<Checkbox checked />}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography sx={{p: pxToRem(15)}}>
              Não há permissões para exibir!
            </Typography>
          )}
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          {formik.values.document !== null &&
          Object.keys(formik.values.document).length !== 0 ? (
            <Grid
              container
              sx={{mt: pxToRem(15), display: "flex", flexDirection: "column"}}>
              <Typography>Documento de identificação</Typography>

              <Grid
                item
                xs={12}
                md={5}
                sx={{
                  mt: pxToRem(15),
                  p: pxToRem(10),
                  lineHeight: pxToRem(40),
                  border: `${pxToRem(1)} solid`,
                  borderColor: "text.primary",
                  display: "flex",
                  alignItems: "center",
                }}>
                <Typography>
                  <strong>Nome do arquivo</strong>
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                md={5}
                sx={{
                  p: pxToRem(15),
                  border: `${pxToRem(1)} solid`,
                  borderTop: "none",
                  borderColor: "text.primary",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                <React.Fragment>
                  <Typography sx={{cursor: "pointer"}} noWrap>
                    <Link
                      onClick={() =>
                        handleOpenViewDocumentModal(formik.values?.document)
                      }>
                      {formik.values.document?.fileName}
                    </Link>
                  </Typography>
                  <CustomIconButton onClickFn={deleteDocument}>
                    <Tooltip title="Apagar" arrow placement="top">
                      <Icon>delete_forever</Icon>
                    </Tooltip>
                  </CustomIconButton>
                </React.Fragment>
              </Grid>
            </Grid>
          ) : (
            <Typography sx={{p: pxToRem(15)}}>
              Nenhum documento enviado!
            </Typography>
          )}
        </TabPanel>
      </Paper>
    </Grid>
  );
};

export default EmployerList;
