import {Types as loadingTypes} from "store/reducers/loadingReducer";
import {Types as dialogTypes} from "store/reducers/dialogReducer";
import {useDispatch} from "react-redux";
import {Grid, Typography, FormControl} from "@mui/material";
import {putNewPassword} from "services/Auth/auth.service";
import {useHistory, useLocation} from "react-router-dom";
import {useFormik} from "formik";
import * as yup from "yup";
import pxToRem from "hooks/usePxToRem";
import CPInput from "components/CPInput";
import {useState} from "react";
import CPButton from "components/CPButton";

const FormNewPassword = () => {
  const dispatch = useDispatch();
  const search = useLocation().search;
  const history = useHistory();
  const userInfo = {
    userToken: new URLSearchParams(search).get("token") || "",
    userId: new URLSearchParams(search).get("_id") || "",
  };
  const [form, setForm] = useState({
    type: "password",
    icon: "visibility_off",
    confirm_type: "password",
    confirm_icon: "visibility_off",
  });

  const newPasswordSchema = yup.object().shape({
    password: yup
      .string()
      .min(6, "Mínimo de 6 caracteres")
      .required("Este campo é obrigatório"),
    confirmPassword: yup
      .string()
      .required("Este campo é obrigatório")
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: yup.string().oneOf([yup.ref("password")], "Senhas não conferem"),
      }),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: newPasswordSchema,
    onSubmit: (values) => {
      handleConfirmResetPassword(values);
    },
  });

  const handleViewPassword = () => {
    if (form.type === "password") {
      setForm({
        ...form,
        type: "text",
        icon: "visibility",
      });
    } else {
      setForm({
        ...form,
        type: "password",
        icon: "visibility_off",
      });
    }
  };

  const handleViewConfirmPassword = () => {
    if (form.confirm_type === "password") {
      setForm({
        ...form,
        confirm_type: "text",
        confirm_icon: "visibility",
      });
    } else {
      setForm({
        ...form,
        confirm_type: "password",
        confirm_icon: "visibility_off",
      });
    }
  };

  const handleConfirmResetPassword = async (data: any) => {
    dispatch({
      type: loadingTypes.CHANGE_LOADING,
      isLoading: true,
    });

    try {
      const response = await putNewPassword(
        userInfo.userId,
        data.password,
        userInfo.userToken,
      );

      if (response.status === 200) {
        dispatch({
          type: dialogTypes.CHANGE_DIALOG,
          isOpen: true,
          status: "success",
          message: "Senha alterada com sucesso.",
        });

        history.push("/login");
      }
    } catch (err: any) {
      console.log(err.response.data.message);
      dispatch({
        type: dialogTypes.CHANGE_DIALOG,
        isOpen: true,
        status: "error",
        message: err.response.data.message,
      });
    } finally {
      dispatch({
        type: loadingTypes.CHANGE_LOADING,
        isLoading: false,
      });
    }
  };

  return (
    <FormControl
      variant="outlined"
      component="form"
      onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid
          item
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: pxToRem(16),
          }}
          xs={12}>
          <Typography color="primary" variant="h6">
            {" "}
            Cadastrar nova senha
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <CPInput
            fullWidth
            name="password"
            placeholder="Senha"
            type={form.type}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.errors.password}
            startIcon="lock"
            endIcon={form.icon}
            fnIconOnClick={handleViewPassword}
          />
        </Grid>
        <Grid item xs={12}>
          <CPInput
            fullWidth
            name="confirmPassword"
            placeholder="Confirmar senha"
            type={form.confirm_type}
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            error={
              formik.touched.confirmPassword &&
              Boolean(formik.errors.confirmPassword)
            }
            helperText={formik.errors.confirmPassword}
            startIcon="lock"
            endIcon={form.confirm_icon}
            fnIconOnClick={handleViewConfirmPassword}
          />
        </Grid>
        <Grid item xs={12}>
          <CPButton type="submit" variant="contained" fullWidth>
            Confirmar nova senha
          </CPButton>
        </Grid>
        <Grid item xs={12}>
          <CPButton
            type="button"
            variant="outlined"
            fullWidth
            fnOnClick={() => history.push("/login")}>
            voltar
          </CPButton>
        </Grid>
      </Grid>
    </FormControl>
  );
};

export default FormNewPassword;
