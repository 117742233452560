import React from "react";
import {Button} from "@mui/material";
import pxToRem from "hooks/usePxToRem";

type Props = {
  variant: "text" | "contained" | "outlined" | undefined;
  href: string;
  onClickFn: () => void;
  disabled: boolean;
  children: React.ReactNode;
  padding: string;
  fullWidth: boolean;
  type?: "button" | "submit" | "reset";
  submit?: boolean;
  size?: "small" | "medium" | "large";
};

const CustomButton = (props: Partial<Props>) => {
  return (
    <Button
      disabled={props.disabled}
      onClick={props.onClickFn}
      size={props.size || "medium"}
      type={props.submit ? "submit" : "button"}
      sx={{
        padding: props.padding ? props.padding : pxToRem(16),
        borderRadius: pxToRem(4),
      }}
      variant={props.variant || "contained"}
      href={props.href}
      color="primary"
      fullWidth={props.fullWidth || false}>
      {props.children}
    </Button>
  );
};

export default React.memo(CustomButton);
