import React from "react";
import {
  Grid,
  Typography,
  MenuItem,
  Select,
  FormHelperText,
  SelectChangeEvent,
} from "@mui/material";

import pxToRem from "hooks/usePxToRem";

import {useSelector, useDispatch} from "react-redux";
import {Types as employerTypes} from "store/reducers/employerDataReducer";

import {CustomInput} from "ui";
import colors from "global/colors";

type Props = {
  canUploadError: boolean;
};

const EmployerSelect = ({canUploadError}: Props) => {
  const dispatch = useDispatch();

  const {employerData} = useSelector((state: any) => state.stateEmployerData);
  const [employerName, setEmployerName] = React.useState("");
  const [employerList, setEmployerList] = React.useState<any>("");

  const verifyIfEmployerIsBroker = () => {
    if (employerData === undefined) return;
    if (employerData.brokerInfo) {
      setEmployerList(employerData.brokerInfo);
      return employerData.brokerInfo;
    } else {
      setEmployerList(employerData.employer);
      return employerData.employer;
    }
  };

  const handleChangeEmployer = (event: SelectChangeEvent<string>) => {
    setEmployerName(event.target.value);

    let selectedEmployerInfo = verifyIfEmployerIsBroker();
    event.target.value === ""
      ? (selectedEmployerInfo = "")
      : selectedEmployerInfo;

    dispatch({
      type: employerTypes.SET_EMPLOYER_DATA,
      employerData: {...employerData, selectedEmployer: selectedEmployerInfo},
    });
  };

  const handleChangeDueDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: employerTypes.SET_EMPLOYER_DATA,
      employerData: {...employerData, dueDate: new Date(event.target.value)},
    });
  };

  React.useEffect(() => {
    verifyIfEmployerIsBroker();
  }, [employerData]);

  return (
    <Grid
      container
      spacing={1}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "start",
      }}>
      <Grid item xs={12} md={8}>
        <Typography variant="caption">
          Informe a empresa da procuração
          <Typography component="span" sx={{color: colors.error.main}}>
            *
          </Typography>
        </Typography>
        <Select
          value={employerName}
          onChange={handleChangeEmployer}
          displayEmpty
          fullWidth
          error={canUploadError}>
          <MenuItem value="">
            <em>Empresa</em>
          </MenuItem>
          {employerList !== undefined && (
            <MenuItem key={employerList._id} value={employerList._id}>
              {employerList.name}
            </MenuItem>
          )}
        </Select>
        {canUploadError && (
          <FormHelperText sx={{marginTop: pxToRem(8), color: "#f44336"}}>
            Campo obrigatório
          </FormHelperText>
        )}
      </Grid>
      <Grid item xs={12} md={8} sx={{mt: pxToRem(10)}}>
        <Typography variant="caption">
          Informe o vencimento da procuração
          <Typography component="span" sx={{color: colors.error.main}}>
            *
          </Typography>
        </Typography>
        <CustomInput
          type="date"
          onChange={handleChangeDueDate}
          error={canUploadError}
        />
      </Grid>
    </Grid>
  );
};

export default EmployerSelect;
