import React, {useEffect} from "react";
import {Grid, Paper, Typography, Divider} from "@mui/material";

import pxToRem from "hooks/usePxToRem";
import WindowDimensions from "hooks/useWindowDimensions";

import {EmployerSelect, SendDocument} from "views/User/components";

import {useSelector} from "react-redux";
import RetrieveUserInformation from "hooks/useUserInformation";
import {useFormikContext} from "formik";
import {UserFormDataType} from "views/User/types/userTypes";

const UserOnlineRelease = () => {
  const {screenWidth} = WindowDimensions();
  const {employerData} = useSelector((state: any) => state.stateEmployerData);
  const user = RetrieveUserInformation();
  const {userData} = useSelector((state: any) => state.stateUserData);

  const formik = useFormikContext<UserFormDataType>();

  const [canUploadError, setCanUploadError] = React.useState<boolean>(false);

  useEffect(() => {
    formik.setFieldValue("document", {...userData.document});
    formik.setFieldValue("releasePermissions", [
      ...userData.releasePermissions,
    ]);
  }, [userData.document, userData.releasePermissions]);

  const canUpload = () => {
    if (!employerData.selectedEmployer || !employerData.dueDate) {
      setCanUploadError(true);
    } else {
      setCanUploadError(false);
    }
  };

  return (
    <Grid item xs={12} md={12}>
      {user.roles.includes("DocumentRelease") && (
        <Paper elevation={6} sx={{mt: pxToRem(15), p: pxToRem(20)}}>
          <Typography variant="h6">Liberação online</Typography>
          <Divider />
          <Grid container>
            <Grid item xs={12} sx={{m: `${pxToRem(15)} 0 ${pxToRem(15)}`}}>
              <Typography variant="subtitle1">Envio de documentos</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="caption">
                Para liberação online é necessário enviar a procuração ou o
                contrato social e também um documento de identificação com foto
                do usuário.
              </Typography>
              <Divider sx={{color: "text.primary"}} />
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: screenWidth < 1200 ? "column" : "row",
                mt: pxToRem(15),
              }}>
              <Grid item xs={12} md={5}>
                <EmployerSelect canUploadError={canUploadError} />
              </Grid>

              <Grid
                item
                xs={12}
                md={7}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}>
                <SendDocument
                  title="Foto do documento de identificação"
                  type="identification"
                />
                <SendDocument
                  title="Foto da procuração ou contrato social"
                  type="proxy"
                  canUpload={() => canUpload()}
                />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      )}
    </Grid>
  );
};

export default UserOnlineRelease;
