import {ThemeProvider} from "@mui/material/styles";
import {useSelector} from "react-redux";
import {Container} from "@mui/material";
import {renderRoutes} from "react-router-config";
import {CustomDialog, CustomBackdrop} from "ui";
import darkTheme from "global/themes/darkTheme";
import lightTheme from "global/themes/lightTheme";

const Public = (props: any) => {
  const {route} = props;
  const {darkMode} = useSelector((state: any) => state.stateConfigsLayout);

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <Container maxWidth="xl" disableGutters>
        {renderRoutes(route.routes)}
        <CustomBackdrop />
        <CustomDialog />
      </Container>
    </ThemeProvider>
  );
};

export default Public;
