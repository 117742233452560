import {useEffect, useState} from "react";
import {format, subDays} from "date-fns";
import CurrencyFormat from "react-currency-format";
import colors from "global/colors";
import {CPCardItem} from "components";
import {Types as modalTypes} from "store/reducers/modalReducer";
import {useDispatch} from "react-redux";
import {Types as snackbarTypes} from "store/reducers/snackbarReducer";
import {Types as loadingTypes} from "store/reducers/loadingReducer";
import {patchProcess} from "services/Process/process.service";
import PaymentsData from "api/Payments";

const Item = (props: any) => {
  const dispatch = useDispatch();

  const {financial} = props;
  const {documents, clientReference, _id} = props.item;
  const {getPayments} = PaymentsData();

  const [urlPaymentSlip, setUrlPaymentSlip] = useState("");

  const itemsToRender = [
    {
      xs: 2,
      content: clientReference,
      mobileTitle: "Referência",
      disableTooltip: false,
    },
    {
      xs: 2,
      content: format(new Date(financial.due), "dd/MM/yyyy"),
      mobileTitle: "Vencimento",
      disableTooltip: true,
    },
    {
      xs: 2,
      content: (
        <CurrencyFormat
          displayType={"text"}
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
          value={financial.value}
          fixedDecimalScale={true}
        />
      ),
      mobileTitle: "Valor (R$)",
      disableTooltip: true,
    },
  ];

  useEffect(() => {
    if (documents.length > 0) {
      const paymentSlips = documents.filter((item: any) => {
        return item.group === "BOLETO";
      });
      setUrlPaymentSlip(paymentSlips[paymentSlips.length - 1].url);
    }
  }, [documents]);

  const showDownload = () => {
    if (
      financial.isPaymentSlip ||
      format(new Date(financial?.date), "dd/MM/yyyy") ===
        format(new Date(), "dd/MM/yyyy") ||
      (format(new Date(financial?.date), "dd/MM/yyyy") ===
        format(subDays(new Date(), 1), "dd/MM/yyyy") &&
        Number(format(new Date(), "hh")) < 13)
    ) {
      return true;
    }
    return false;
  };

  const handleOpenSnackbar = (severity: string, message: string) => {
    dispatch({
      type: snackbarTypes.SHOW_SNACKBAR,
      openSnackbar: true,
      message: message,
      severity: severity,
    });
  };

  const handleApproveBills = async () => {
    dispatch({
      type: loadingTypes.CHANGE_LOADING,
      isLoading: true,
    });
    try {
      const temp = financial;

      temp.isAvailable = true;

      const data = {financials: temp};

      const response = await patchProcess(_id, data);

      if (response.status === 200) {
        getPayments();
        handleOpenSnackbar("success", "Fatura aprovada com sucesso");
      }
    } catch (err) {
      console.log(err);
      handleOpenSnackbar("error", "Erro ao aprovar fatura");
    } finally {
      dispatch({
        type: loadingTypes.CHANGE_LOADING,
        isLoading: false,
      });
    }
  };

  const handleOpenModal = () => {
    dispatch({
      type: modalTypes.SHOW_MODAL,
      openModal: true,
    });
  };

  const handleOpenConfirmModal = () => {
    dispatch({
      type: modalTypes.SHOW_CONFIRM_MODAL,
      openConfirmModal: true,
    });

    dispatch({
      type: modalTypes.SET_DATA_CONFIRM_MODAL,
      dataConfirmModal: {
        action: () => handleApproveBills(),
        title: "Confirmar aprovação da fatura?",
        message: `RF do Cliente: ${clientReference}`,
        icon: "check",
        color: colors.badgeColors.error.text,
      },
    });
  };

  const handleOpenDownloadModal = (item: any) => {
    handleOpenModal();

    dispatch({
      type: modalTypes.OPEN_CHOOSE_MODAL,
      dataModal: item,
      downloadModal: true,
    });
  };

  const handleOpenUpdateModal = (item: any) => {
    handleOpenModal();

    dispatch({
      type: modalTypes.OPEN_CHOOSE_MODAL,
      dataModal: item,
      updateModal: true,
    });
  };

  const handleOpenReferenceModal = (item: any) => {
    handleOpenModal();

    dispatch({
      type: modalTypes.OPEN_CHOOSE_MODAL,
      dataModal: item,
      referenceModal: true,
    });
  };

  const itemsMenu = [
    {
      label: "Aprovar fatura",
      icon: "done",
      fnOnClick: () => handleOpenConfirmModal(),
    },
    {
      label: "Visualização e download de fatura",
      icon: "download",
      fnOnClick: () =>
        handleOpenDownloadModal({
          ...financial,
          clientReference,
          name: "fatura",
        }),
    },
    {
      label: "Visualização e download de boleto",
      icon: "download_for_offline",
      fnOnClick: () =>
        handleOpenDownloadModal({
          ...financial,
          urlPaymentSlip,
          name: "boleto",
        }),
      disabled: `${showDownload()}` ? false : true,
    },
    {
      label: "Atualizar ou corrigir valores",
      icon: "refresh",
      fnOnClick: () =>
        handleOpenUpdateModal({
          ...financial,
          clientReference,
        }),
    },
    {
      label: "Adicionar/Alterar referência adicional",
      icon: "sell",
      fnOnClick: () =>
        handleOpenReferenceModal({
          _id,
        }),
    },
  ];

  return <CPCardItem itemsToRender={itemsToRender} menuOptions={itemsMenu} />;
};

export default Item;
