import {createActions, createReducer} from "reduxsauce";

type initial = {
  uploaderFiles: Array<any>;
};

const INITIAL_STATE: initial = {
  uploaderFiles: [],
};

export const {Types, Creators} = createActions({
  handleUploaderFiles: ["uploaderFiles"],
});

const handleUploaderFiles = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  uploaderFiles: action.uploaderFiles || [],
});

export default createReducer(INITIAL_STATE, {
  [Types.HANDLE_UPLOADER_FILES]: handleUploaderFiles,
});
