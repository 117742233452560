import React from "react";
import {Box, Grid, Paper, Tooltip, Typography} from "@mui/material";
import {CPMenuItem} from "components";
import WindowDimensions from "hooks/useWindowDimensions";
import pxToRem from "hooks/usePxToRem";

type Props = {
  menuOptions:
    | {
        label: string;
        icon: string;
        fnOnClick: () => void;
        disabled?: boolean;
      }[];

  itemsToRender: {
    xs: number;
    content: any;
    mobileTitle: string;
    disableTooltip: boolean;
  }[];

  data?: any;

  clickable?: React.Dispatch<React.SetStateAction<boolean>>;

  setDataToShow?: React.Dispatch<React.SetStateAction<boolean>>;
};

const CPCardItem = (props: Props) => {
  const {screenWidth} = WindowDimensions();
  const {menuOptions, itemsToRender, data, clickable, setDataToShow} = props;

  const handleClickCard = () => {
    clickable ? clickable(true) : "";
    if (setDataToShow) {
      setDataToShow(data);
    }
  };

  return (
    <Paper
      elevation={6}
      sx={{
        margin: `${pxToRem(8)} 0 ${pxToRem(16)} 0`,
        paddingTop: pxToRem(10),
        width: "100%",
        cursor: clickable ? "pointer" : "",
      }}
      // onClick={() => {
      //   clickable ? clickable(true) : "";
      // }}
      onClick={handleClickCard}>
      {screenWidth <= 768 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}>
          <CPMenuItem menuPosition={"top-right"} itemsMenu={menuOptions} />
        </Box>
      )}
      {/* {data != null ? ( */}
      <Grid
        container
        spacing={1}
        sx={{
          flexDirection: screenWidth <= 768 ? "column" : "row",
          width: "100%",
          padding: `${pxToRem(6)} ${pxToRem(16)} ${pxToRem(16)} ${pxToRem(16)}`,
          paddingTop: screenWidth <= 768 ? pxToRem(0) : "",
          justifyContent: screenWidth <= 768 ? "center" : "space-between",
          alignItems: screenWidth <= 768 ? "start" : "center",
        }}>
        {itemsToRender.map((item, index) => (
          <Grid item xs={screenWidth <= 768 ? 12 : item.xs} key={index}>
            <Tooltip
              disableHoverListener={item.disableTooltip}
              title={item.content || ""}
              arrow
              placement="top-start">
              <Typography color="inherit" variant="body2" noWrap>
                {screenWidth <= 768 ? (
                  <React.Fragment>
                    <Typography component="span" sx={{fontWeight: 500}}>
                      {item.mobileTitle}:{" "}
                    </Typography>
                    {item.content}
                  </React.Fragment>
                ) : (
                  item.content
                )}
              </Typography>
            </Tooltip>
          </Grid>
        ))}
        <Grid
          item
          xs={screenWidth <= 768 ? 12 : 1}
          sx={{width: "100%", textAlign: "end"}}>
          {screenWidth <= 768 ? "" : <CPMenuItem itemsMenu={menuOptions} />}
        </Grid>
      </Grid>
      {/* ) : (
        <Grid>Não há itens para exibir</Grid>
      )} */}
    </Paper>
  );
};

export default CPCardItem;
