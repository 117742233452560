import {createActions, createReducer} from "reduxsauce";

type initial = {
  rememberMe: boolean;
};

const INITIAL_STATE: initial = {
  rememberMe: false,
};

export const {Types, Creators} = createActions({
  changeRememberMe: ["rememberMe"],
});

const changeRememberMe = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  rememberMe: action.rememberMe,
});

export default createReducer(INITIAL_STATE, {
  [Types.CHANGE_REMEMBER_ME]: changeRememberMe,
});
