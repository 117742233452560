import pxToRem from "hooks/usePxToRem";

import {Paper} from "@mui/material";

const CPSidebar = ({children}) => {
  return (
    <Paper
      elevation={0}
      sx={{
        height: `calc(100vh - ${pxToRem(32)})`,
        flexBasis: pxToRem(268),
        borderRadius: pxToRem(12),
        margin: `${pxToRem(16)} 0 ${pxToRem(16)} ${pxToRem(6)}`,
        overflow: "hidden",
        position: "relative",
        padding: `${pxToRem(17)} 0`,
        maxWidth: pxToRem(268),
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
      }}>
      {children}
    </Paper>
  );
};

export default CPSidebar;
