import {useEffect, useState} from "react";

import RetrieveUserInformation from "hooks/useUserInformation";
import {useDispatch, useSelector} from "react-redux";
import {Types as loadingTypes} from "store/reducers/loadingReducer";
import {Types as snackbarTypes} from "store/reducers/snackbarReducer";
import {Types as paginationTypes} from "store/reducers/paginationReducer";

import {getProcesses} from "services/Process/process.service";

const PaymentsData = (type?: string) => {
  const dispatch = useDispatch();
  const [paymentsData, setPaymentsData] = useState<any[]>([]);
  const [search, setSearch] = useState<string>("");
  const {actualPage, itemsPerPage} = useSelector(
    (state: any) => state.statePagination,
  );

  const handlePagination = () => {
    dispatch({
      type: paginationTypes.CHANGE_HAVE_PAGINATION,
      havePagination: true,
    });
  };

  const handleLoading = (action: string) => {
    dispatch({
      type: loadingTypes.CHANGE_LOADING,
      isLoading: action === "start" ? true : false,
    });
  };

  const handleOpenSnackbar = (severity: string, message: string) => {
    dispatch({
      type: snackbarTypes.SHOW_SNACKBAR,
      openSnackbar: true,
      message: message,
      severity: severity,
    });
  };

  const getPayments = async (action?: string) => {
    handlePagination();
    handleLoading("start");

    const criteria: Array<any> = [];

    //verifica se o termo de pesquisa não é vazio e adiciona ele ao criteria
    if (action === "search") {
      if (search === "" || search === " ") return;

      //parâmetros para pesquisa
      criteria.push({
        $or: [
          {processNumber: {$regex: search, $options: "i"}},
          {clientReference: {$regex: search, $options: "i"}},
          {externalReference: {$regex: search, $options: "i"}},
          {additionalReference: {$regex: search, $options: "i"}},
        ],
      });
    }

    // cliente ou consignee
    criteria.push({
      $or: [
        {client: RetrieveUserInformation().employer._id},
        {consignee: RetrieveUserInformation().employer._id},
      ],
    });

    //removendo status Fora do Kanban
    criteria.push({
      kanbanStatus: {$nin: ["Fora do Kanban", "Cancelado"]},
    });

    //removendo microstatus Novo Processo
    criteria.push({microStatus: {$nin: ["Novo Processo"]}});

    //apenas desembarcados
    criteria.push({kanbanStatus: {$in: ["Desembarcado"]}});

    //apenas com financial items
    criteria.push({"financials.0": {$exists: true}});
    criteria.push({"financials.0.isPayed": false});

    if (type === "available") {
      //finacial items disponíveis/em aberto
      criteria.push({"financials.0.isAvailable": true});
    } else {
      criteria.push({"financials.0.isAvailable": false});
    }
    try {
      const response = await getProcesses(
        criteria.length > 0 && JSON.stringify({$and: criteria}),
        "financials.0.due",
        action ? 1 : actualPage,
        itemsPerPage,
      );

      setPaymentsData(response.data.items);

      const billsOnlyArray = response.data.items
        .filter((item: any) => item?.financials.length > 0)
        .map((item: any) => item.financials.map((financial: any) => financial));

      let correctActualPage = 1;
      if (billsOnlyArray.length === 0 && actualPage > 1) {
        correctActualPage = actualPage - 1;
      } else {
        correctActualPage = actualPage;
      }

      dispatch({
        type: paginationTypes.CHANGE_OPTIONS,
        totalItems: billsOnlyArray.length,
        actualPage: correctActualPage,
        itemsPerPage: itemsPerPage,
        totalPages: Math.ceil(billsOnlyArray.length / itemsPerPage),
      });
    } catch (err) {
      console.log(err);
      handleOpenSnackbar(
        "error",
        "Não foi possível carregar as faturas em aberto.",
      );
    } finally {
      handleLoading("finish");
    }
  };

  useEffect(() => {
    getPayments();
  }, [actualPage, itemsPerPage, dispatch]);

  return {
    paymentsData,
    getPayments,
    search,
    setSearch,
  };
};

export default PaymentsData;
