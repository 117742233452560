import {Box, Checkbox, ListItemText, Tooltip} from "@mui/material";
import {Dispatch, SetStateAction} from "react";
import {useDispatch, useSelector} from "react-redux";
import {patchCompany} from "services/Company/company.service";
import {Types as modalTypes} from "store/reducers/modalReducer";
import colors from "global/colors";
import {
  handleCompany,
  handleLoading,
  handleSnackbar,
} from "views/Company/utils";
import pxToRem from "hooks/usePxToRem";

type Props = {
  allowedBrokers: Array<any>;
  setAllowedBrokers: Dispatch<SetStateAction<Array<any>>>;
  broker: any;
};

const CardItemBroker = (props: Props) => {
  const {allowedBrokers, setAllowedBrokers, broker} = props;
  const {currentCompany} = useSelector((state: any) => state.stateCompany);

  const dispatch = useDispatch();
  const isAllowedBroker = allowedBrokers.find(
    (item) => item.person === broker._id,
  );

  const handleOpenConfirmModal = (broker: any) => {
    dispatch({
      type: modalTypes.SHOW_CONFIRM_MODAL,
      openConfirmModal: true,
    });

    dispatch({
      type: modalTypes.SET_DATA_CONFIRM_MODAL,
      dataConfirmModal: {
        action: () => addBrokerToAllowed(broker),
        title: "Permitir despachante",
        message: `Deseja permitir o despachante ${broker.name}?`,
        icon: "warning",
        color: colors.badgeColors.error.text,
      },
    });
  };

  const addBrokerToAllowed = async (item: any) => {
    handleLoading(dispatch, "start");
    const tempArray = allowedBrokers;
    const index = tempArray.map((broker) => broker.person).indexOf(item._id);

    if (index == -1) {
      tempArray.push({
        permissions: [],
        isDefault: false,
        person: item._id,
      });
    }

    setAllowedBrokers(tempArray);

    try {
      const response = await patchCompany(currentCompany._id, {
        brokers: allowedBrokers,
      });

      if (response.status === 200) {
        handleCompany(dispatch, response.data);
        handleSnackbar(
          dispatch,
          "Despachante adicionado com sucesso",
          "success",
        );
      }
    } catch (error) {
      console.log(error);
      handleSnackbar(dispatch, "Erro ao adicionar despachante", "error");
    } finally {
      handleLoading(dispatch, "finish");
    }
  };

  const handleCheckedAndUncheckedChange = (item: any) => {
    if (!isAllowedBroker) {
      handleOpenConfirmModal(item);
    }
  };

  return (
    <Tooltip
      title={`Habilitar despachante ${broker.name}`}
      placement="top-start"
      arrow>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: pxToRem(2),
          width: "100%",
        }}>
        <Checkbox
          onChange={() => handleCheckedAndUncheckedChange(broker)}
          checked={isAllowedBroker ? true : false}
          sx={{
            padding: `${pxToRem(7)} ${pxToRem(8)} ${pxToRem(8)} 0`,
          }}
        />
        <ListItemText
          primary={broker.name.toUpperCase()}
          primaryTypographyProps={{fontSize: pxToRem(14)}}
        />
      </Box>
    </Tooltip>
  );
};

export default CardItemBroker;
