import React from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";

import {Types as modalTypes} from "store/reducers/modalReducer";
import {Types as uploaderTypes} from "store/reducers/uploaderReducer";
import {Types as userTypes} from "store/reducers/userDataReducer";
import {Types as snackbarTypes} from "store/reducers/snackbarReducer";
import {Types as loadingTypes} from "store/reducers/loadingReducer";

import {Upload} from "hooks/useUpload";
import {useDispatch, useSelector} from "react-redux";

import {CustomButton} from "ui";
import {CPUploader} from "components";
import WindowDimensions from "hooks/useWindowDimensions";
import pxToRem from "hooks/usePxToRem";

const ModalUploadDocument = () => {
  const dispatch = useDispatch();

  const {screenWidth} = WindowDimensions();

  const {uploaderFiles} = useSelector((state: any) => state.stateUploader);
  const {userData} = useSelector((state: any) => state.stateUserData);
  const {employerData} = useSelector((state: any) => state.stateEmployerData);
  const {dataModal} = useSelector((state: any) => state.stateModal);

  const description = () => {
    if (dataModal.type === "identification") {
      return "Adicione a foto do documento de identificação abaixo:";
    } else {
      return "Adicione a foto da procuração ou contrato social abaixo:";
    }
  };

  const handleCloseModal = () => {
    dispatch({
      type: modalTypes.SHOW_MODAL,
      openModal: false,
    });
    dispatch({
      type: uploaderTypes.HANDLE_UPLOADER_FILES,
      uploaderFiles: [],
    });
  };

  const handleSubmit = async () => {
    const data = new FormData();

    try {
      dispatch({
        type: loadingTypes.CHANGE_LOADING,
        isLoading: true,
      });

      if (uploaderFiles.length > 0) {
        data.append("image", uploaderFiles[0]);
        const result = await Upload(data);

        if (dataModal.type === "proxy") {
          const newPermission: any[] = userData.releasePermissions;

          newPermission.push({
            status: "pendente",
            documents: [
              {
                url: result.data.link,
                fileName: uploaderFiles[0].name,
                type: uploaderFiles[0].type,
              },
            ],
            due: employerData.dueDate,
            employer: employerData.selectedEmployer,
          });
          dispatch({
            type: userTypes.SET_USER_DATA,
            userData: {
              ...userData,
              releasePermissions: newPermission,
            },
          });
        } else {
          dispatch({
            type: userTypes.SET_USER_DATA,
            userData: {
              ...userData,
              document: {
                fileName: uploaderFiles[0].path,
                url: result.data.link,
                type: uploaderFiles[0].type,
              },
            },
          });
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch({
        type: modalTypes.SHOW_MODAL,
        openModal: false,
      });
      dispatch({
        type: uploaderTypes.HANDLE_UPLOADER_FILES,
        uploaderFiles: [],
      });
      dispatch({
        type: loadingTypes.CHANGE_LOADING,
        isLoading: false,
      });
      dispatch({
        type: snackbarTypes.SHOW_SNACKBAR,
        openSnackbar: true,
        message: "Documento anexado com sucesso",
        severity: "success",
      });
    }
  };

  return (
    <React.Fragment>
      <DialogTitle sx={{paddingTop: screenWidth <= 768 ? 0 : pxToRem(16)}}>
        Enviar documento
      </DialogTitle>
      <Divider variant="middle" />
      <DialogContent>
        <Typography>{description()}</Typography>
        <CPUploader />
      </DialogContent>
      <DialogActions>
        <CustomButton variant="outlined" onClickFn={handleCloseModal}>
          Cancelar
        </CustomButton>
        <CustomButton variant="contained" onClickFn={handleSubmit}>
          Enviar
        </CustomButton>
      </DialogActions>
    </React.Fragment>
  );
};

export default ModalUploadDocument;
