import React from "react";
import {Paper, Typography} from "@mui/material";
import pxToRem from "hooks/usePxToRem";
import WindowDimensions from "hooks/useWindowDimensions";

type Props = {
  children: React.ReactNode;
  title: string;
  desktopHeight: string;
};
const InfoContainer = (props: Props) => {
  const {screenWidth} = WindowDimensions();
  const {children, title, desktopHeight} = props;

  return (
    <Paper
      sx={{
        width: "100%",
        padding: pxToRem(10),
        height: screenWidth <= 768 ? "auto" : desktopHeight,
        marginTop: screenWidth <= 768 ? pxToRem(15) : 0,
      }}>
      <Typography
        component="h2"
        variant="body1"
        sx={{fontSize: pxToRem(18), marginBottom: pxToRem(10)}}>
        {title}
      </Typography>
      {children}
    </Paper>
  );
};

export default InfoContainer;
