import {
  Box,
  Typography,
  Paper,
  Grid,
  IconButton,
  Icon,
  Badge,
} from "@mui/material";
import {CPAvatar, CPDivider, CPMenu} from "components";
import pxToRem from "hooks/usePxToRem";
import {useDispatch, useSelector} from "react-redux";
import {Types as configsTypes} from "store/reducers/configsLayoutReducer";
import RetrieveUserInformation from "hooks/useUserInformation";
import {useHistory} from "react-router-dom";

import "./style.scss";

const CPMobileSidebar = () => {
  const dispatch = useDispatch();
  const user = RetrieveUserInformation();
  const history = useHistory();

  const handleCloseDrawer = () => {
    dispatch({
      type: configsTypes.SET_OPEN_MENU_MOBILE,
      menuMobile: false,
    });
  };
  return (
    <Box
      sx={{
        width: pxToRem(240),
        margin: pxToRem(16),
        overflow: "hidden",
      }}>
      <Paper
        className="scroll"
        elevation={6}
        sx={{
          width: "100%",
          borderRadius: pxToRem(12),
          display: "flex",
          justifyContent: "start",
          flexDirection: "column",
          alignItems: "center",
          maxHeight: "100%",
          overflow: "scroll",
        }}>
        <Box
          sx={{
            position: "sticky",
            top: "0",
          }}>
          <Paper elevation={6} sx={{boxShadow: "none"}}>
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}>
              <Grid item>
                <IconButton onClick={handleCloseDrawer}>
                  <Icon>close</Icon>
                </IconButton>
              </Grid>

              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}>
                <Badge
                  overlap="circular"
                  anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                  badgeContent={
                    <Box
                      sx={{
                        backgroundColor: "background.paper",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}>
                      <Icon
                        sx={{color: "text.primary"}}
                        onClick={() => {
                          history.push(`/user-edit/${user._id}`);
                          handleCloseDrawer();
                        }}>
                        account_circle
                      </Icon>
                    </Box>
                  }>
                  <CPAvatar src={user.avatar} aWidth={80} aHeight={80} />
                </Badge>
                <Typography variant="subtitle1" sx={{marginTop: pxToRem(8)}}>
                  {user.name}
                </Typography>
              </Grid>
            </Grid>
            <CPDivider />
          </Paper>
        </Box>

        <CPMenu action={() => handleCloseDrawer()} />
      </Paper>
    </Box>
  );
};

export default CPMobileSidebar;
