import React from "react";
import {Dialog, DialogContent, DialogContentText, Avatar} from "@mui/material";
import {useSelector, useDispatch} from "react-redux";
import {Types as dialogTypes} from "store/reducers/dialogReducer";

import CheckIcon from "@mui/icons-material/Check";
import PriorityHighOutlinedIcon from "@mui/icons-material/PriorityHighOutlined";

import "./customDialog.styles.scss";

const CustomDialog = () => {
  const dispatch = useDispatch();
  const {isOpen, status, message} = useSelector(
    (state: any) => state.stateDialog,
  );
  const bgcolor = {
    success: "#4caf50",
    error: "#f44336",
  };

  const handleClose = () => {
    dispatch({
      type: dialogTypes.CHANGE_DIALOG,
      isOpen: false,
      status: undefined,
      message: "",
    });
  };

  return (
    <Dialog open={isOpen} fullWidth maxWidth="xs" onClose={handleClose}>
      {status === "error" && (
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <DialogContentText
            sx={{
              marginBottom: "16px",
              fontSize: "1.2rem",
              fontWeight: 500,
              color: bgcolor.error,
            }}>
            {"Ops! Algo deu errado"}
          </DialogContentText>
          <Avatar
            sx={{
              marginBottom: "16px",
              bgcolor: bgcolor.error,
              width: "80px",
              height: "80px",
            }}>
            <PriorityHighOutlinedIcon sx={{fontSize: 60}} />
          </Avatar>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
      )}

      {status === "success" && (
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <DialogContentText
            sx={{
              marginBottom: "16px",
              fontSize: "1.2rem",
              fontWeight: 500,
              bgColor: bgcolor.success,
            }}>
            {"Sucesso!"}
          </DialogContentText>
          <Avatar
            sx={{
              marginBottom: "16px",
              bgcolor: bgcolor.success,
              width: "80px",
              height: "80px",
            }}>
            <CheckIcon sx={{fontSize: 60}} />
          </Avatar>
          <DialogContentText className="dialog-message">
            {message}
          </DialogContentText>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default React.memo(CustomDialog);
