import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Avatar,
  Icon,
  Typography,
  Grid,
} from "@mui/material";
import pxToRem from "hooks/usePxToRem";
import {useDispatch, useSelector} from "react-redux";
import {Types as modalTypes} from "store/reducers/modalReducer";
import {CustomButton, CustomIconButton} from "ui";
import WindowDimensions from "hooks/useWindowDimensions";
import {Close} from "@mui/icons-material";

const CPConfirmModal = () => {
  const dispatch = useDispatch();
  const {openConfirmModal, dataConfirmModal} = useSelector(
    (state: any) => state.stateModal,
  );

  const {screenWidth} = WindowDimensions();
  const {title, message, icon, action, sensitiveConfirm} = dataConfirmModal;

  const handleClose = () => {
    dispatch({
      type: modalTypes.SHOW_CONFIRM_MODAL,
      openConfirmModal: false,
    });
  };

  return (
    <Dialog
      open={openConfirmModal}
      //maxWidth="sm"
      onClose={handleClose}
      fullScreen={screenWidth <= 768 ? true : false}>
      {screenWidth <= 768 && (
        <Grid container sx={{display: "flex", justifyContent: "flex-end"}}>
          <CustomIconButton
            onClickFn={handleClose}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              padding: pxToRem(5),
            }}>
            <Close fontSize="small" />
          </CustomIconButton>
        </Grid>
      )}
      <DialogTitle
        sx={{
          textAlign: "center",
          paddingTop: screenWidth <= 768 ? 0 : pxToRem(16),
        }}>
        {title}
      </DialogTitle>
      <DialogContent
        sx={{
          paddingBottom: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            flexDirection: "column",
            height: pxToRem(150),
          }}>
          <Avatar
            sx={{
              bgcolor: "primary.main",
              width: 50,
              height: 50,
              //</Box>mb: pxToRem(16),
            }}>
            <Icon>{icon}</Icon>
          </Avatar>
          <Typography variant="body2" sx={{mt: pxToRem(8)}}>
            {message}
          </Typography>
        </Box>
      </DialogContent>

      <DialogActions
        sx={{
          display: "flex",
          flexDirection: sensitiveConfirm ? "row-reverse" : "row",
          justifyContent: "space-between",
          padding: pxToRem(16),
          minWidth: "320px",
        }}>
        <CustomButton variant="outlined" onClickFn={handleClose}>
          Cancelar
        </CustomButton>

        <CustomButton
          onClickFn={() => {
            action();
            handleClose();
          }}>
          Confirmar
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default CPConfirmModal;
