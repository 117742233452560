import {createActions, createReducer} from "reduxsauce";

type initial = {
  openSnackbar: boolean;
  message: string;
  severity: string;
};

const INITIAL_STATE: initial = {
  openSnackbar: false,
  message: "",
  severity: "success",
};

export const {Types, Creators} = createActions({
  showSnackbar: ["openSnackbar", "message", "severity"],
});

const showSnackbar = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  openSnackbar: action.openSnackbar || false,
  message: action.message || "",
  severity: action.severity || "success",
});

export default createReducer(INITIAL_STATE, {
  [Types.SHOW_SNACKBAR]: showSnackbar,
});
