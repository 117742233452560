import {Divider} from "@mui/material";
import rgba from "hooks/useRgba";
import pxToRem from "hooks/usePxToRem";
import {useSelector} from "react-redux";

const CPDivider = () => {
  const {darkMode} = useSelector((state: any) => state.stateConfigsLayout);
  return (
    <Divider
      sx={{
        backgroundColor: "transparent",
        backgroundImage: `linear-gradient(to right, ${rgba(
          darkMode ? "#FFF" : "#000",
          0,
        )}, ${rgba(darkMode ? "#FFF" : "#000", 0.4)},
	${rgba(darkMode ? "#FFF" : "#000", 0)}) !important`,
        height: pxToRem(1),
        margin: `${pxToRem(16)} 0`,
        borderBottom: "none",
        opacity: 0.25,
      }}
    />
  );
};
export default CPDivider;
