/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  AppBar,
  Box,
  IconButton,
  Icon,
  Badge,
  SpeedDial,
  SpeedDialAction,
} from "@mui/material";
import {useSelector, useDispatch} from "react-redux";
import {Types as configsTypes} from "store/reducers/configsLayoutReducer";
import pxToRem from "hooks/usePxToRem";
import {useHistory} from "react-router-dom";
import WindowDimensions from "hooks/useWindowDimensions";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import darkTheme from "global/themes/darkTheme";
import lightTheme from "global/themes/lightTheme";
import colors from "global/colors";

import {CPLogo} from "components";
import CPAvatarMenu from "components/CPAvatarMenu";
import Breadcrumbs from "./Breadcrumbs";
import RetrieveUserInformation from "hooks/useUserInformation";

const CPAppbar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = RetrieveUserInformation();

  const {screenWidth} = WindowDimensions();
  const {fixedNavbar, openSettings, menuMobile, darkMode} = useSelector(
    (state: any) => state.stateConfigsLayout,
  );

  const actions = [
    {icon: <Icon>notifications</Icon>, name: "Notificações"},
    {icon: <Icon>chat</Icon>, name: "Chat"},
    {icon: <Icon>settings</Icon>, name: "Configurações"},
  ];

  const handleOpenSettings = () => {
    dispatch({
      type: configsTypes.SET_OPEN_SETTINGS,
      openSettings: !openSettings,
    });
  };

  const handleOpenMenuMobile = () => {
    dispatch({
      type: configsTypes.SET_OPEN_MENU_MOBILE,
      menuMobile: !menuMobile,
    });
  };

  const handleChooseOptionDial = (name: string) => {
    if (name === "Configurações") {
      handleOpenSettings();
    } else if (name === "Notificações") {
      console.log("Notificações");
    } else {
      console.log("Chat");
    }
  };

  return (
    <AppBar
      position="sticky"
      elevation={0}
      sx={{
        height: pxToRem(75),
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingTop: pxToRem(8),
        paddingBottom: pxToRem(8),
        paddingRight: pxToRem(8),
        paddingLeft: pxToRem(16),
        marginTop: pxToRem(16),
        borderRadius: pxToRem(12),
        width: "100%",
        backgroundColor: !fixedNavbar
          ? "transparent"
          : darkMode
          ? darkTheme.palette.background.paper
          : lightTheme.palette.background.paper,
      }}>
      {screenWidth >= 768 ? (
        <Box>
          <Breadcrumbs />
        </Box>
      ) : (
        <Box>
          <CPLogo />
        </Box>
      )}
      {screenWidth >= 768 ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}>
          <IconButton onClick={handleOpenSettings} size="large">
            <Badge color="primary" badgeContent={10}>
              <Icon>chat</Icon>
            </Badge>
          </IconButton>

          <IconButton onClick={handleOpenSettings} size="large">
            <Badge color="primary" badgeContent={99}>
              <Icon>notifications</Icon>
            </Badge>
          </IconButton>
          <IconButton onClick={handleOpenSettings} size="large">
            <Icon>settings</Icon>
          </IconButton>

          <CPAvatarMenu
            actions={[
              {
                name: "Editar perfil",
                icon: <Icon>edit</Icon>,
                onClickFn: () => history.push(`/user-edit/${user._id}`),
              },
              {
                name: "Criar perfil",
                icon: <Icon>person_add</Icon>,
                onClickFn: () => history.push(`/user-new`),
              },
            ]}
          />
        </Box>
      ) : (
        <Box sx={{display: "flex", flexDirection: "row"}}>
          <IconButton
            size="large"
            sx={{marginRight: pxToRem(32)}}
            onClick={handleOpenMenuMobile}>
            {menuMobile ? <MenuOpenIcon /> : <MenuIcon />}
          </IconButton>
          <SpeedDial
            direction={"down"}
            ariaLabel="SpeedDial"
            sx={{
              position: "absolute",
              top: 10,
              right: 0,
            }}
            FabProps={{
              style: {
                backgroundColor: "transparent",
                color: `${darkMode ? colors.white.main : colors.text.main}`,
                boxShadow: "none",
              },
            }}
            icon={<MoreVertIcon />}>
            {actions.map((action) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                tooltipOpen
                onClick={() => handleChooseOptionDial(action.name)}
              />
            ))}
          </SpeedDial>
        </Box>
      )}
    </AppBar>
  );
};

export default React.memo(CPAppbar);
