import {createTheme} from "@mui/material/styles";

import {breakpointValues, priorityColors, typographyConfigs} from "../base";

const darkTheme = createTheme({
  breakpoints: {
    values: {
      ...breakpointValues,
    },
  },

  typography: {
    ...typographyConfigs,
  },

  palette: {
    mode: "dark",
    ...priorityColors,

    primary: {
      main: "#00b2af",
    },

    secondary: {
      main: "#32C4A9",
    },

    background: {
      default: "#1a2035",
      paper: "#1f283e",
    },

    text: {
      primary: "#ffffff",
    },
  },
});

export default darkTheme;
