import {Box, SxProps, Theme} from "@mui/material";
import Dropzone from "react-dropzone";

type UploaderIcon = {
  children: JSX.Element | JSX.Element[];
  onDrop: any;
  sx?: SxProps<Theme> | undefined;
};

const CPUploaderIcon = ({children, onDrop, sx}: UploaderIcon) => {
  return (
    <Dropzone onDrop={onDrop}>
      {({getRootProps, getInputProps}) => (
        <section>
          <div {...getRootProps()} style={{position: "relative"}}>
            <input {...getInputProps()} />
            <Box
              sx={{
                ...sx,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                cursor: "pointer",
              }}>
              {children}
            </Box>
          </div>
        </section>
      )}
    </Dropzone>
  );
};

export default CPUploaderIcon;
