import {Types as loadingTypes} from "store/reducers/loadingReducer";
import {Dispatch} from "react";

const HandleLoading = (dispatch: Dispatch<any>, action: "start" | "finish") => {
  dispatch({
    type: loadingTypes.CHANGE_LOADING,
    isLoading: action === "start" ? true : false,
  });
};

export default HandleLoading;
