import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getCompanyUsers} from "services/User/user.service";
import GetCompany from "../Company/GetCompany";
import {Types as companyTypes} from "store/reducers/companyReducer";
import {Types as loadingTypes} from "store/reducers/loadingReducer";
import {Types as snackbarTypes} from "store/reducers/snackbarReducer";
import {Types as paginationTypes} from "store/reducers/paginationReducer";
import RetrieveUserInformation from "hooks/useUserInformation";

const GetUsers = () => {
  const [usersList, setUsersList] = useState<any[]>([]);
  const [search, setSearch] = useState<string>("");

  const dispatch = useDispatch();
  const {actualPage, itemsPerPage} = useSelector(
    (state: any) => state.statePagination,
  );

  const handlePagination = () => {
    dispatch({
      type: paginationTypes.CHANGE_HAVE_PAGINATION,
      havePagination: true,
    });
  };

  const handleLoading = (action: string) => {
    dispatch({
      type: loadingTypes.CHANGE_LOADING,
      isLoading: action === "start" ? true : false,
    });
  };

  const handleOpenSnackbar = (severity: string, message: string) => {
    dispatch({
      type: snackbarTypes.SHOW_SNACKBAR,
      openSnackbar: true,
      message: message,
      severity: severity,
    });
  };

  const loadCompany = async () => {
    const response = await GetCompany();

    if (response._id != "") {
      dispatch({
        type: companyTypes.COMPANY_DATA,
        currentCompany: response,
      });
    }
  };

  const getUsersList = async (action?: string) => {
    loadCompany();
    handleLoading("start");
    handlePagination();

    const criteria: Array<any> = [];

    if (action === "search") {
      if (search === "" || search === " ") return;

      //adiciona filtro por termo pesquisado
      criteria.push({
        $or: [
          {name: {$regex: search, $options: "i"}},
          {email: {$regex: search.toLowerCase()}},
        ],
      });
    }
    //adiciona filtro por empresa
    criteria.push({
      employer: RetrieveUserInformation().employer._id,
    });

    //adiciona filtro por usuários ativos
    criteria.push({
      disabled: false,
    });

    try {
      const response = await getCompanyUsers(
        criteria.length > 0 && JSON.stringify({$and: criteria}),
        "name",
        action ? 1 : actualPage,
        itemsPerPage,
      );
      setUsersList(response.data.items);

      const usersArray = response.data.items;
      let correctActualPage = 1;

      if (usersArray.length === 0 && actualPage > 1) {
        correctActualPage = actualPage - 1;
      } else {
        correctActualPage = actualPage;
      }

      dispatch({
        type: paginationTypes.CHANGE_OPTIONS,
        totalItems: usersArray.length,
        actualPage: correctActualPage,
        itemsPerPage: itemsPerPage,
        totalPages: Math.ceil(usersArray.length / itemsPerPage),
      });
    } catch (error) {
      console.log(error);
      handleOpenSnackbar("error", "Erro ao buscar usuários da empresa.");
    } finally {
      handleLoading("finish");
    }
  };

  useEffect(() => {
    getUsersList();
  }, [actualPage, itemsPerPage, dispatch]);

  return {
    setUsersList,
    usersList,
    getUsersList,
    search,
    setSearch,
  };
};

export default GetUsers;
