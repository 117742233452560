import {Drawer} from "@mui/material";
import pxToRem from "hooks/usePxToRem";
import {useSelector, useDispatch} from "react-redux";
import {Types as configsTypes} from "store/reducers/configsLayoutReducer";

const CPDrawer = ({children}) => {
  const dispatch = useDispatch();
  const {menuMobile} = useSelector((state: any) => state.stateConfigsLayout);

  const handleCloseDrawer = () => {
    dispatch({
      type: configsTypes.SET_OPEN_MENU_MOBILE,
      menuMobile: false,
    });
  };

  return (
    <Drawer
      elevation={0}
      open={menuMobile}
      onClose={handleCloseDrawer}
      PaperProps={{
        sx: {
          backgroundColor: "transparent",
          border: "none",
        },
      }}
      sx={{
        padding: pxToRem(16),
      }}>
      {children}
    </Drawer>
  );
};

export default CPDrawer;
