import {useFormik} from "formik";
import * as yup from "yup";
import {Grid, FormControl, Typography} from "@mui/material";
import {useDispatch} from "react-redux";
import {Types as loadingTypes} from "store/reducers/loadingReducer";
import {Types as dialogTypes} from "store/reducers/dialogReducer";
import {postRequestNewPassword} from "services/Auth/auth.service";
import {useHistory} from "react-router-dom";
import pxToRem from "hooks/usePxToRem";
import CPInput from "components/CPInput";
import CPButton from "components/CPButton";

const FormForgotPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loginSchema = yup.object({
    email: yup
      .string()
      .email("Informe um email válido")
      .required("Este campo é obrigatório"),
  });

  const formik = useFormik({
    initialValues: {
      email: localStorage.getItem(JSON.stringify("username")) || "",
    },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      handleForgotPassword(values);
    },
  });

  const handleForgotPassword = async (data: any) => {
    dispatch({
      type: loadingTypes.CHANGE_LOADING,
      isLoading: true,
    });

    try {
      const response = await postRequestNewPassword(data.email);

      if (response.status === 200) {
        dispatch({
          type: dialogTypes.CHANGE_DIALOG,
          isOpen: true,
          status: "success",
          message: "link de redefinição enviado para o seu email.",
        });

        history.push("/login");
      }
    } catch (err: any) {
      console.log(err.response.data.message);
      dispatch({
        type: dialogTypes.CHANGE_DIALOG,
        isOpen: true,
        status: "error",
        message: err.response.data.message,
      });
    } finally {
      dispatch({
        type: loadingTypes.CHANGE_LOADING,
        isLoading: false,
      });
    }
  };

  return (
    <FormControl
      variant="outlined"
      component="form"
      onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid
          item
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: pxToRem(16),
          }}
          xs={12}>
          <Typography color="primary" variant="h6">
            {" "}
            Recuperar senha
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <CPInput
            name="email"
            placeholder="E-mail"
            fullWidth
            value={formik.values.email}
            type="text"
            onChange={formik.handleChange}
            startIcon="email"
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.errors.email}
          />
        </Grid>
        <Grid item xs={12}>
          <CPButton type="submit" variant="contained" fullWidth>
            solicitar nova senha
          </CPButton>
        </Grid>
        <Grid item xs={12}>
          <CPButton
            type="button"
            variant="outlined"
            fullWidth
            fnOnClick={() => history.push("/login")}>
            voltar
          </CPButton>
        </Grid>
      </Grid>
    </FormControl>
  );
};

export default FormForgotPassword;
