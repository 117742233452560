import {buildURL, dataAPI} from "../Api/apiConsumer";

export const MobileTokenInformation = () => {
  const token = localStorage.getItem("mobileToken");
  if (token) return token;

  return undefined;
};

export const getActualUser = async (userId: string) => {
  return await dataAPI.get(`/users/${userId}`);
};

export const getCompanyUsers = async (
  query: any,
  sort: string,
  page: number,
  limit: number,
) => {
  return await dataAPI.get(`/users/${buildURL(query, sort, page, limit)}`);
};

export const getCompany = async (id: string) => {
  return await dataAPI.get(`employers/${id}`);
};

export const getCompaniesByBroker = async (brokerId: string) => {
  return await dataAPI.get(`employers/findBrokers/${brokerId}`);
};

export const createNewUser = async (data: any) => {
  return await dataAPI.post("/users", data);
};

export const editUser = async (data: any) => {
  if (data._id) return await dataAPI.patch(`/users/${data._id}`, data);
  return null;
};
