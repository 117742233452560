import React from "react";
import pxToRem from "hooks/usePxToRem";

import {useSelector} from "react-redux";

import {Box} from "@mui/material";

import standardCargoPlayLogo from "assets/images/cp-logo_default.svg";
import whiteCargoPlayLogo from "assets/images/cp-logo_white.svg";
import {Link} from "react-router-dom";

const CPLogo = () => {
  const {darkMode} = useSelector((state: any) => state.stateConfigsLayout);

  return (
    <Link to="/dashboard">
      <Box
        component="img"
        sx={{
          width: "100%",
          height: "100%",
          maxHeight: "80px",
          padding: `0 ${pxToRem(18)}`,
        }}
        alt="Cargo Play"
        src={darkMode ? whiteCargoPlayLogo : standardCargoPlayLogo}
      />
    </Link>
  );
};

export default React.memo(CPLogo);
