import {createActions, createReducer} from "reduxsauce";

type initial = {
  havePagination: boolean;
  totalItems: number;
  actualPage: number;
  itemsPerPage: number;
  totalPages: number;
};

const INITIAL_STATE: initial = {
  havePagination: false,
  totalItems: 0,
  actualPage: 1,
  itemsPerPage: 10,
  totalPages: 1,
};

export const {Types, Creators} = createActions({
  changeHavePagination: ["havePagination"],
  changeOptions: ["totalItems", "actualPage", "itemsPerPage", "totalPages"],
});

const changeHavePagination = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  havePagination: action.havePagination,
});

const changeOptions = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  totalItems: action.totalItems,
  actualPage: action.actualPage,
  itemsPerPage: action.itemsPerPage,
  totalPages: action.totalPages,
});

export default createReducer(INITIAL_STATE, {
  [Types.CHANGE_HAVE_PAGINATION]: changeHavePagination,
  [Types.CHANGE_OPTIONS]: changeOptions,
});
