import {Box, Paper, Typography} from "@mui/material";
import pxToRem from "hooks/usePxToRem";
import WindowDimensions from "hooks/useWindowDimensions";
import {useSelector} from "react-redux";

const Header = () => {
  const {screenWidth} = WindowDimensions();

  const {currentCompany} = useSelector((state: any) => state.stateCompany);

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: screenWidth <= 768 ? "column" : "row",
        justifyContent: "space-between",
        width: "100%",
        padding: pxToRem(16),
        height: screenWidth <= 768 ? "25%" : "12%",
      }}>
      <Typography
        variant={screenWidth <= 768 ? "h6" : "h5"}
        sx={{alignSelf: "center"}}>
        Ativação e alteração da empresa
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: screenWidth <= 768 ? "center" : "flex-start",
          justifyContent: "center",
        }}>
        <Typography variant="body1">
          Situação da empresa: {currentCompany?.isActive ? "Ativa" : "Inativa"}
        </Typography>
        <Typography variant="body1">
          Termo anual: {currentCompany?.annualDemurrage ? "Ativo" : "Inativo"}
        </Typography>
      </Box>
    </Paper>
  );
};

export default Header;
